import React from 'react'
import { LOCAL_IMAGES } from '../../utils/localimages'
import './aboutus.css'
import MainHeading from '../../commonComponent/dividerline'
import Slider from 'react-slick'
import { testimonials } from '../../utils/dummydata'


export default function AboutUs() {

  const settingsreview = {
    dots: true,
    // infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="mt-24">
      <div className='aboutimg'>
        <img src={LOCAL_IMAGES.aboutusImg} />
      </div>
      <div style={{ marginTop: '6px' }}>
        <MainHeading headingText={"About Us"} />

      </div>
      <div className='aboutMaincontainer'>
       
        <p className='para stardos-stencil-bold'>
          AGATIN Keratin revolutionizes beauty. This revolutionary smoothing
          treatment was the first to give every hair type healthy, smooth, frizz-free hair.
          Our unique formulas contain rich Argan Oil and our patented Keratin to remove
          frizz, repair damage, strengthen, and soften for healthy hair.
        </p>
        <p className='para stardos-stencil-bold'>
          Hair, skin, and nails are mostly made of keratin-associated proteins. Keratin is a
          protective protein in the cuticle and a structural protein in the cortex. Damage to
          hair physically, chemically, or by the sun depletes keratin. This keratin loss
          exposes the hair&#39;s cortex to further damage and creates porous patches
          resembling potholes. Keratin replacement smooths hair and corrects porosity.
          Strength and suppleness are restored, giving hair a youthful, healthy
          appearance.
        </p>
       

        
        <MainHeading headingText={"Sinconyc Skincare:"} />
        <p className='para stardos-stencil-bold'>Skincare Sinconyc combines natural beauty with technological innovation to make you shine.
          We started with a simple yet strong idea: that excellent skincare comes from a perfect balance
          between nature&#39;s ageless wisdom and modern science.</p>
        <p className='para stardos-stencil-bold'>
          Luxury, potent, safe, and delicate skincare products are our goal at Skincare Sinconyc. We
          carefully choose natural ingredients and integrate them with cutting-edge technology to
          nourish, renew, and enhance your skin&#39;s inherent beauty.
        </p>
        <p className='para stardos-stencil-bold'>Experience exceptional skincare and embrace your inner beauty with us.</p>
        <p  className='para stardos-stencil-bold'>
          Sustainability, honesty, and quality are our goals. We welcome diversity and believe every
          skin type is lovely and deserves care as unique and vivid as you are.
        </p>

        <div className='missoncontainer'>
          <div className='missiontext'>

            <MainHeading headingText={"Our Mission"} />

            <p className='para'> We want to pioneer the hair and beauty market with our goods. Our Keratin
              treatment series uses modern technology and natural bio-actives like Argan oil
              to help women worldwide feel attractive in their hair. Our products with natural
              Argan oil transform dull, lifeless hair into lustrous, gorgeous, and strong.</p>
            <p className='para'>
              What distinguishes our brand?
              Our groundbreaking Argan Oil-infused ARGATIN Keratin treatments attach
              keratin to hair. This prevents humidity from entering the hair, preventing frizz.
              Argan oil, an ancient beauty secret, hydrates and softens hair. Argan Oil&#39;s
              antioxidants, vital fatty acids, and vitamin E naturally boost hair elasticity and
              shine to dull, lifeless hair.
            </p>
          </div>
          <img src={LOCAL_IMAGES.ab1} alt='mission img' />


        </div>
        <div className='missoncontainer'>
          <div className='missiontext'>

            <MainHeading headingText={"FROM SKIN CARE EXPERTS"} />

            <p className='para stardos-stencil-bold' >
              I had been adhering to a CTM routine for quite some time, which prompted me
              to consider attempting Skin Co Products. I&#39;ve been utilizing this combination for
              several days, and I must state that this price is unbeatable. In the past, I had
              never used Skin Co products; however, this purchase prompted me to explore
              the brand&#39;s other skin care lines.
            </p>
          </div>
          <img src={LOCAL_IMAGES.ab2} alt='mission img' />


        </div>

        {/* <h1>All Skin &amp; Hair care Types Products</h1>
        <h1>Pure Organic Products</h1>
        <h1>Natural Care</h1>
        <h1>DELIVERY FREE UPTO 500 RS ORDER </h1>
        <h1>Delivery available over India</h1>
        <h1>EASY PAYMENT</h1>
        <h1>Use a Credit/Debit Card, UPI, Netbanking etc.</h1>
        <h1>HAVE QUESTIONS?</h1>
        <h1>Call: +91-9315743367</h1> */}
        <MainHeading headingText={"Testimonials"} />
        <div style={{ backgroundColor: '', justifyContent: 'center', alignItems: 'center' }}>

          <Slider {...settingsreview}>
            {
              testimonials?.map((item, index) => {
                console.log("itemdata", item)
                return (

                  <>
                    <div style={{ backgroundColor: '', marginTop: '20px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={item.bgimge} alt='testilogo'
                          className='service_para' />
                      </div>

                      <p className='service_para_testi'>{item.para}</p>
                      <p className='service_para_Name'>{item.name}</p>
                    </div>

                  </>

                )

              })
            }
          </Slider>
        </div>

      </div>
    </div>
  )
}
