import React from 'react'
import { LOCAL_IMAGES } from '../../utils/localimages'
import './line.css'

export default function MainHeading(props) {
    const {headingText}=props
  return (
    <div className='mainHeadingContainer my-5'>
        <p className='headingText merriweather-regular mt-[30px] md:mt-0'>{headingText}</p>
    </div>
  )
}
