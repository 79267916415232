import React, { useEffect, useState } from 'react'
import './contactus.css'
import CustomCart from '../../commonComponent/customcard'
import { postApiData } from '../../utils/services'
import { LOCAL_IMAGES } from "../../utils/localimages";
import { countryName } from "./country";
import { IoMdCall } from 'react-icons/io';
import { IoMailSharp } from 'react-icons/io5';
export default function ContactUs() {
  const [facialkit,setFacialKit]=useState([])
  // const token=useSelector((store)=>store.authReducer.userData)
  // useEffect(()=>{
  //   if(token)
  //   {
  //     setAuthorizationToken(token)
  //   }

  // },[])

  const [formvalue, setformvalue] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    country: "",
    query: "",
    
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setformvalue({ ...formvalue, [name]: value });
  };
  const handledistributionsubmit = (e) => {
    e.preventDefault();

    postApiData(
      "/user/distributerForm",
      formvalue,
      (response) => {
        console.log("Data submitted successfully in the database");
        console.log("Form data:", formvalue);

        setformvalue({
          name: "",
          phoneNumber: "",
          email: "",
          country: "",
          query: "",
        });
      },
      (error) => {
        console.error("Error submitting data to the database", error);
      }
    );
  };

  const apidata = {
    brand: "Argatinkeratin",
  };
  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setFacialKit(callBack);
    });
  }, []);
  return (
    <div className="my-24">
        {/* <p className='contactus'>Contact Us</p> */}
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3504.6701034106613!2d77.267363!3d28.549634!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce24a702d1c55%3A0x9a5500a8d4eee992!2sMonsoon%20Salon%20Head%20Office!5e0!3m2!1sen!2sin!4v1695618174537!5m2!1sen!2sin"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      {/* <CustomCart productdata  ={facialkit}/> */}

      
      <form
        onSubmit={handledistributionsubmit}
        className="distribution-formgroupContainer"
      >
        <img src={LOCAL_IMAGES.qualityimg} alt="qualityimg" />
        <div className="distribution-formgroup ">
        <div className='flex flex-col'>
          <h1 className='text-sm md:text-lg font-semibold text-black cursor-pointer flex justify-center items-center font-sans gap-x-3 my-2'>Please Contact Us on</h1>
          <h1 className='text-sm md:text-lg font-semibold text-black cursor-pointer flex justify-center items-center font-sans gap-x-3'><IoMdCall className='text-xl font-bold'/>9315743367</h1>
          <h1 className='text-sm md:text-lg font-semibold text-black cursor-pointer flex justify-center items-center font-sans gap-x-3 mt-2'><IoMailSharp className='text-xl font-bold'/>exports@theprofessionalworld.com</h1>
          <h1 className='text-sm md:text-lg font-semibold text-black cursor-pointer flex justify-center items-center font-sans gap-x-3 mx-8  md:mx-0'>Please Share Your requirements and we will get in touch with you </h1>
        </div>
        {/* <p className="px-4 text-md md:text-lg">
            
              Please Contact on  Email exports@theprofessionalworld.com  or call at 9315743367
            
          </p> */}
          {/* <p className="text-sm md:text-lg font-semibold text-black cursor-pointer flex justify-center items-center font-sans gap-x-3 my-4">
            {
              "Please Share Your requirements and we will get in touch with you "
            }
          </p> */}
          <div className="name-container">
            <div className="name-field">
              <h1>Your Name</h1>
              <input
                type="text"
                name="name"
                value={formvalue.name}
                onChange={handleInput}
                placeholder="Enter your name"
                style={{boxShadow:"0 2px 4px rgba(0, 0, 0, 0.1)"}}
              />
            </div>
            <div className="phone-field">
              <h1>Phone</h1>
              <input
                type="text"
                name="phoneNumber"
                value={formvalue.phoneNumber}
                onChange={handleInput}
                placeholder="Enter your number"
                style={{boxShadow:"0 2px 4px rgba(0, 0, 0, 0.1)"}}
              />
            </div>
          </div>

          <div className="name-container">
            <div className="name-field">
              <h1>Email</h1>
              <input
                type="text"
                name="email"
                value={formvalue.email}
                onChange={handleInput}
                placeholder="Enter your email"
                style={{boxShadow:"0 2px 4px rgba(0, 0, 0, 0.1)"}}
              />
            </div>
            <div className="phone-field">
              <h1>Select Country</h1>
              <select
                name="country"
                value={formvalue.country}
                onChange={handleInput}
                style={{backgroundColor:"white",boxShadow:"0 2px 4px rgba(0, 0, 0, 0.1)"}}
               
              >
                <option value="" disabled>
                  Please select
                </option>
                {countryName.map((country) => (
                  <option key={country.country_id} value={country.country_name}>
                    {country.country_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="query-container">
            <h1>Query</h1>
            <textarea
              className="query-inputbox"
              name="query"
              value={formvalue.query}
              onChange={handleInput}
            ></textarea>
          </div>
          <button type="submit" className="submit-button">
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  )
}
