import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "./Carddescrption.css";
import toast from "react-hot-toast";
import { postProductData } from "../Redux/actions";
import { useDispatch } from "react-redux";
import { CartContext } from "../context/CartContext";
import { MdVerified } from "react-icons/md";
import { Helmet } from "react-helmet";
import ReviewButton from "../commonComponent/reviewButton";
import { postApiData } from "../utils/services";
import { FaCircleUser } from "react-icons/fa6";
import { Rating } from "@mui/material";
import ProductSlider from "../commonComponent/slider/ProductSlider";
import { store } from "../Redux/store/store";

const ProductDiscription = () => {
  const { state } = useLocation();
  const [product, setProduct] = useState({});
  const [urldata, setUrlData] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [imageTransitionDirection, setImageTransitionDirection] = useState(false);
  const { isCartOpen, setIsCartOpen } = useContext(CartContext);
  const [isRating,setIsRating] = useState(false)
  const [ratings, setRatings] = useState([])
  const navigate = useNavigate();
  const sliderRef = useRef();
  const dispatch = useDispatch();
  const [isTruncated, setIsTruncated] = useState(true);
  const [divHeight, setDivHeight] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const authToken = store.getState();
  const token = authToken.authReducer.userData
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };
  const toggleDivHeight=()=>{
    setDivHeight(!divHeight)
  }
  var typeProduct = state?.productType;
  const settings = {
    //dots: true,
    infinite: true,
    //speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
  };
  const { shortUrl } = useParams();
  console.log("iddatacoming",shortUrl)
  useEffect(() => {
    axios
      .get(`https://prosaloncart.com/user/getSingleProductData?id=${shortUrl}`)
      .then((response) => {
        setProduct(response.data.data);
        console.log("singleproduct", response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [isRating]);
  useEffect(() => {
    const currentURL = window.location.href;
    setUrlData(currentURL);
    console.log("currentURL", currentURL);

    return () => {};
  }, []);
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setImageTransitionDirection(!imageTransitionDirection);
  };
  const handleVideoClick = (index) => {
    setSelectedImageIndex(index);
    setImageTransitionDirection(!imageTransitionDirection);
  };
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const addToCart = (product) => {
    dispatch(postProductData(product, 1, typeProduct));
    setIsCartOpen(true);
    toast.success("product added successfully!");
  };
  const buyNow = (product) => {
    dispatch(postProductData(product, 1, typeProduct));
    setIsCartOpen(true);
    toast.success("product added successfully!");
    if(!token){
      navigate("/login")
    }else{
      navigate("/AddressModal");
    }
  };
  useEffect(()=>{
    const data = {
      productId: product?._id
    }
    console.log("lodu",data)
    postApiData("review/getReviewsOfProduct",data,
    
      (resp)=>{
        setRatings(resp)
      },
      (error)=>{
        console.log("error",error)
      }
    )
  },[isRating])
console.log("ratings",ratings)
function FormatDate(date) {
  const dates = new Date(date);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = formatter.format(dates);

  return formattedDate;
}
const renderDescription = () => {
  if (!product?.description) return null;

  // Split the description by periods and filter out empty strings
  const sentences = product.description.split('.').filter(sentence => sentence.trim() !== '');

  return (
    <ul className="list-disc pl-5">
      {sentences.map((sentence, index) => (
        <li key={index}>{sentence.trim()}.</li>
      ))}
    </ul>
  );
};
  return (
    <>
      <Helmet>
        <title>{product?.name}</title>
        <meta name="description" content={product?.description} />
        <link rel="canonical" href={urldata} />
        <meta property={product?.name} content={product?.description} />
        <meta property={product?.description} content={product?.description} />
        <meta property="og:url" content={urldata} />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Skin.co" />
        <meta property="og:updated_time" content="2023-05-02T06:55:45+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content=" Skin.co  " />
        <meta name="twitter:label2" content="Time to read" />
        <meta name="twitter:data2" content="Less than a minute" />
      </Helmet>
      <div className="w-screen flex flex-col mt-32 md:mt-24">
        <div className="flex flex-col md:flex-row h-auto md:h-[70vh] justify-between ">
        {
          isSmallScreen ? (
            <ProductSlider data={product?.images}/>
          ) : (
            <>
            <div className="w-full md:w-[10%] h-full overflow-x-scroll md:overflow-y-auto scrollbar flex flex-row md:flex-col order-2 md:order-1">
  {product &&
    product?.images?.map((media, index) => {
      const isImage = media.includes(".jpg") || media.includes(".png") || media.includes(".jpeg") || media.includes(".webp") || media.includes(".JPG");
      return (
        <div key={index} className="w-full md:w-full m-3 shadow-xl">
          {isImage ? (
            <img
              className="w-full h-full md:w-[90%] mx-auto cursor-pointer"
              src={media}
              alt={`${product?.name}`}
              onClick={() => handleImageClick(index)}
            />
          ) : (
            <video
              className="w-full h-full md:h-[150px] md:w-[90%] mx-auto cursor-pointer"
              autoPlay
              controls={false}
              muted
              onClick={() => handleVideoClick(index)}
              alt={`${product?.name}`}
            >
              <source src={media} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      );
    })}
</div>
<div className="w-full md:w-[35%] h-full order-1 md:order-2">
          {product && product?.images && (
    <>
      {product.images[selectedImageIndex].includes(".mp4") ? (
        <video
          className={`w-[90%] mx-auto h-[300px] md:h-[500px] transition-${
            imageTransitionDirection ? "left" : "right"
          }`}
          controls
          autoPlay
          muted
        >
          <source src={product.images[selectedImageIndex]} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          className={`w-[90%] mx-auto transition-${
            imageTransitionDirection ? "left" : "right"
          }`}
          src={product.images[selectedImageIndex]}
          alt={`${product?.name}`}
        />
      )}
    </>
  )}
          </div>
            </>
          )
        }
        

          
          <div className="w-full md:w-[45%]  flex flex-col py-5 px-4 order-3">
            <h1 className="text-lg md:text-2xl font-extrabold merriweather-regular text-black leading-tight my-5">
              {product?.name}
            </h1>
            <p className="sans-serif  text-sm md:text-lg mb-5 text-gray-500 ">
              {product?.productbenefit}
            </p>
            <p className="merriweather-black text-sm md:text-sm mb-5 text-black flex justify-start items-center">
              <MdVerified className="text-green-600 text-lg mr-3" /> <span className="text-lg">{ratings?.length} &nbsp; </span>
               Verified Reviews {product?.price > 499 ? "| Free Shipping" : " "}{" "}
            </p>
            <div className="flex justify-start items-center gap-x-4">
              <p className="merriweather-regular text-black text-md md:text-lg mb-5 font-semi-bold">
                Price: {product?.price}
              </p>
              <p className="merriweather-regular text-black text-md md:text-lg mb-5 font-semi-bold line-through">
                Mrp:{product?.mrp}
              </p>
              <p className="merriweather-regular text-black text-xs md:text-sm mb-5 font-semi-bold">
                incl. of all taxes
              </p>
            </div>
            <div className="flex justify-between items-center gap-x-4 ">
              <button
                className={`w-1/2 bg-${product.stockQuantity === 0?"gray-700":"black"} hover:opacity-80 text-white py-4 font-bold merriweather-regular`}
                onClick={() => {
                  if (product.stockQuantity === 0) {
                    toast.error("Product is not in stock");
                  } else {
                    buyNow(product);
                  }
                }}
              >
                Buy Now
              </button>
              {product.stockQuantity === 0 ? (
                <button className="w-1/2 bg-gray-700 text-white py-4  addtocart-button cursor-not-allowed">
                  Out Of Stock
                </button>
              ) : (
                <button
                  className="w-1/2 bg-black hover:opacity-80 text-white py-4 font-bold merriweather-regular"
                  onClick={() => addToCart(product)}
                >
                  Add To Cart
                </button>
              )}
            </div>
          </div>
        </div>
          <h1 className="text-lg md:text-2xl font-extrabold merriweather-regular text-black text-center leading-tight my-5">
            PRODUCT DESCRIPTION
          </h1>
        <div className="px-4 bg-[#edf2f4] my-5">
        <div 
        className={`h-auto w-full md:w-[70%] mx-auto`}
        // className={`h-${divHeight?'auto':'[150px]'} overflow-hidden`}
        >
        {
          product?.desImages?.map((item,index)=>(
            <div key={index} className="w-[80%] mx-auto my-10">
            <img src={item} className="w-full h-auto object-cover" style={{ imageRendering: "pixelated" }}/>
            </div>
          )) 
        }
          <p className="sans-serif  text-black  leading-8 text-sm mb-5 font-medium px-5 md:px-32">
          {renderDescription()}
          </p>
          <ul className="list-none  px-5 md:px-40">
        {product?.benefitdesc?.split('\n').map((benefit, index) => (
          <li key={index} className="font-serif  text-black text-md leading-8 mb-5 font-normal">
            {benefit}
          </li>
        ))}
      </ul>
        </div>  
        {/* <button onClick={toggleDivHeight} className="bg-white w-full text-center text-2xl py-2 mb-2 text-black font-semibold">{!divHeight ? " Read more" : "Read less"}</button> */}
        </div>
        {/* Review Section  */}
        <div className="my-10 border border-black  flex flex-col mx-5">
          <div className="border-b border-gray-300 w-full">
            <h1 className="border-b-2 border-black inline-block px-4 font-sans text-black font-semibold text-md md:text-lg mx-2 py-2 sans-serif">Ratings & Reviews</h1>
          </div>
          <div className="flex ">
            <div className="w-1/2 border-r border-black  flex flex-col md:flex-row justify-center items-center md:justify-start md:items-start py-4 px-2 md:px-10">
              <div className="text-xl md:text-5xl font-bold font-sans">{product?.rating || 0}/5</div>
              <div className="flex flex-col mx-5">
                <h1 className="text-sm md:text-lg font-medium sans-serif">Overall Rating</h1>
                <h1 className="text-xs md:text-lg font-medium sans-serif">{ratings?.length} verified ratings</h1>
              </div>
            </div>
            <div className="w-1/2 flex justify-center items-center">
              <ReviewButton id={product?._id} isRating={isRating} setIsRating={setIsRating}/>
            </div>
          </div>
        </div>
        {
          ratings?.length > 0 && (
               <h1 className="text-center text-lg md:text-3xl font-bold sans-serif">Customer Reviews</h1>
          )
        }

        <div className="my-1 md:my-10 flex flex-col md:flex-row justify-evenly items-center gap-5 flex-wrap px-2 md:px-10 ">
        {
          ratings?.slice(0,6).map((item,index)=>(
            <div key={index} className="w-full md:w-1/4 h-auto shadow-xl rounded-md px-5 pt-2 pb-5 flex flex-col border border-gray-300">
            <div className="flex justify-between items-center">
            <Rating value={item?.rating} readOnly className="my-4"/>
            `<h1 className="text-sm font-normal font-sans">{FormatDate(item?.createdAt)}</h1>
            </div>
            <div className="flex justify-start items-center gap-x-3">
              <FaCircleUser className="text-2xl"/>
              <h1 className="text-lg font-serif font-semibold">{item?.name}</h1>
              <h1 className="text-sm font-serif font-medium bg-black text-white px-3 rounded-md ">verified</h1>
            </div>
            <h1 className="text-md font-serif font-medium my-2 flex flex-wrap">{item?.description?.slice(0, 100)}{item?.description?.length > 100 && '...'}</h1>
            <div className="flex justify-start gap-x-4 w-full items-center">
              {
                item?.imageUrl?.slice(0, 3).map((img)=>(
                  <img src={img} alt="img" className="w-[50px] h-[50px] object-cover"/>
                ))
              }
            </div>
            </div>
          ))
        }
        </div>
      </div>
    </> 
  );
};

export default ProductDiscription;
