import React, { useEffect, useState } from 'react'
import CommonSlider from '../../commonComponent/commonSlider'
import { springh2obannerimg } from '../../utils/dummydata'
import ProductCart from '../../commonComponent/customcard/productCart'
import { postApiData } from '../../utils/services'
import CustomProductTag from '../../commonComponent/gtm'
import TestCard from '../../commonComponent/customcard/testCart'
import { Helmet } from 'react-helmet'
import SliderCarts from '../../commonComponent/customcard/sliderCart'

const Spring = () => {
  const [facialKit,setFacialKit] = useState([])
  const apidata = {
    brands: {"Spring H2O":true},
  };
  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setFacialKit(callBack);
    });
  }, []);
  return (
    <div>
    <Helmet>
        <title>Buy Skin Care Products Online in India -SpringH2O.</title>
        <meta
          name="description"
          content="Experience the pure goodness of nature with Skinco's toxin-free skin care products. Our range includes a luxurious Facial Kit for radiant skin, Menicare for tailored male grooming, and Pedicare for pampered feet. Embrace the power of natural ingredients for a healthier, glowing complexion. Buy online in India and treat your skin to the care"
        />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href="https://prosaloncart.com/spring" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16512616878"></script>
          <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16512616878');
          `}
          </script>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Buy Skin Care Products Online in India -SpringH2O"
        />
        <meta
          property="og:description"
          content="Experience the pure goodness of nature with Spring H2O's toxin-free skin care products. Our range includes a luxurious Facial Kit for radiant skin, Menicare for tailored male grooming, and Pedicare for pampered feet. Embrace the power of natural ingredients for a healthier, glowing complexion. Buy online in India and treat your skin to the care"
        />
        <meta property="og:url" content="https://prosaloncart.com/spring" />
        <meta property="og:site_name" content="SpringH2O" />
        <meta property="og:updated_time" content="2023-05-02T06:55:45+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
        <div className="skinconContainer mt-24">
        <CommonSlider brand="spring" />
      </div>
      <div className="flex flex-row gap-5 justify-evenly md:justify-between items-center flex-wrap md:mx-10 my-10">
        {
          facialKit?.map((item,index)=>(
          <>
            <SliderCarts key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)} stockQuantity={item.stockQuantity} productType={"product"}/>
            <CustomProductTag product={item}/>          
          </>
        ))
      }
      </div>
    </div>
  )
}

export default Spring