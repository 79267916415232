import React from 'react';
import './skin.css'
import { LOCAL_IMAGES } from '../../utils/localimages';

const Skincategory = ({skin}) => {
   
    return (
        <div className="flex justify-around items-center flex-wrap mt-4 md:mt-0">
            {skin?.map((item, index) => (
                <div className="flex flex-col" key={index}>
                        <img className="w-[100px] md:w-[150px]" src={item.image} alt={item.description} />
                        <h4 className=" text-lg font-semibold text-black text-center mt-3">{item.description}</h4>
                </div>

            ))}
        </div>
    );
};

export default Skincategory;
