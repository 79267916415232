import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getApiCall } from '../../utils/services';
import ResponsiveSlider from '../slider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [bestSeller, setBestSeller] = React.useState([])
  const [newLaunches, setNewLaunches] = React.useState([])
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(()=>{
    getApiCall("user/getAllProductsForBestseller",
        (resp)=>{
            setBestSeller(resp)
        },
        (error)=>{
            console.log('error')
        }
    )
  },[])
  React.useEffect(()=>{
    getApiCall("user/getAllProductsForNewArrivals",
        (resp)=>{
            setNewLaunches(resp)
        },
        (error)=>{
            console.log('error')
        }
    )
  },[])
  console.log("best-sellers",bestSeller)

  return (
    <Box sx={{ width: '100%' }}>
      <div className='w-full flex justify-center items-center'>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="🔥 Best Sellers 🔥" {...a11yProps(0)} sx={{
            background: "black", 
            color: "white", 
            fontWeight: "bold", 
            padding: isMobile ? "5px 5px" : "10px 20px", 
            margin: isMobile ? "0px 10px" : "0px 50px", 
            fontSize: isMobile ? "14px" : "25px", 
            borderRadius: "20px"
          }}/>
          <Tab label="✨ New Launches ✨" {...a11yProps(1)} sx={{
            background: "black", 
            color: "white", 
            fontWeight: "bold", 
            padding: isMobile ? "5px 5px" : "10px 20px", 
            margin: isMobile ? "0px 10px" : "0px 50px", 
            fontSize: isMobile ? "14px" : "25px", 
            borderRadius: "20px"
          }} />
        </Tabs>
      </div>
      <CustomTabPanel value={value} index={0}>
      <ResponsiveSlider data ={bestSeller}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
       <ResponsiveSlider data ={newLaunches}/>
      </CustomTabPanel>
    </Box>
  );
}
