import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./addressmodal.css";
import { getApiCall, postApiData } from "../../utils/services";
import axios from "axios";
import AddNewAddress from "../addnewAddress";
import { LOCAL_IMAGES } from "../../utils/localimages";
import { useDispatch, useSelector } from "react-redux";
import setAuthorizationToken from "../../utils/commonFunction";
import { FaIndianRupeeSign } from "react-icons/fa6";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { successCartEmpty } from "../../Redux/actions";
import { Helmet } from "react-helmet";

const Shipping = ({ addressmodal, setAddressModal }) => {
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [email,setEmail]=useState('')
  const [selectaddress, setSelectaddress] = useState("");
  const [isgif, setgif] = useState(false);
  const [addNewAddressModal, setAddNewAddress] = useState(false);
  const [filledAddress, setFilledAddress] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedAddressObject, setSelectedAddressObject] = useState(null);
  const [summery, setSummery] = useState(false);
  const [cartDetails, setCartDetails] = useState([]);
  const [orderid, setOrderId] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [cash, setCash] = useState(false);
  const [paymentonline, setPayemntOnline] = useState(false);
  const [updatedAmount, setupdatedAmount] = useState(0);

  const [isModalOpen, setModalOpen] = useState(false);
  const cartData = useSelector((store) => store.cartDetailsReducer.cartLength);
  const navigate = useNavigate();
  const location = useLocation();
  const openCartpress = location.state;
  const dispatch = useDispatch();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const token = useSelector((store) => store.authReducer.userData);
  const totalAmountpayable = useSelector(
    (store) => store.addProduct.cartpayableAmount
  );
  const guestUserCartDetails = useSelector((state) => state.addProduct.cart);
  const result = guestUserCartDetails.map(({ _id, quantity }) => ({
    _id,
    quantity,
  }));
  console.log("resultscoming", result);
  useEffect(() => {
    if (token) {
      setAuthorizationToken(token);
    }
  }, []);
  useEffect(() => {
    if (cash === true) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [cash]);
  useEffect(() => {
    if (paymentonline === true) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [paymentonline]);
  useEffect(() => {
    if (totalAmountpayable < 500) {
      const updatedamountwithDelivery = totalAmountpayable + 50;
      setupdatedAmount(updatedamountwithDelivery);
    } else {
      setupdatedAmount(totalAmountpayable);
    }
  }, [totalAmountpayable]);

  const handleSelectChange = (event) => {
    console.log("adddresss id -----", event.target.value);
    const selectedAddress = filledAddress?.filter(
      (item) => item._id === event.target.value
    );
    console.log("selected address ", selectedAddress[0]);
    // const addressObj = selectedAddress[0]
    const [address, address2, city, state, pincode] = selectedAddress;
    console.log(address, address2, city, state, pincode);
    setSelectaddress({ address, address2, city, state, pincode });
  };
  console.log("selected addressssss------------", selectaddress.address);
  console.log("handle select change", selectedOption);

  function paymentbycash() {
    setgif(!isgif);
  }

  useEffect(() => {
    const selectedObject = filledAddress.find(
      (address) =>
        address.address2 + address.city + address.state + address.pincode ===
        selectedOption
    );

    // Update the selectedAddressObject state with the selected object
    setSelectedAddressObject(selectedObject);
  }, [selectedOption]);

  console.log("address ki ------------------", selectedAddressObject);

  const truncateProductName = (name, maxLength) => {
    return name.length > maxLength
      ? name.substring(0, maxLength) + "..."
      : name;
  };
  const loadScript = (src, callback) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.onload = callback;
    document.head.appendChild(script);
  };

  // Load Razorpay SDK
  loadScript("https://checkout.razorpay.com/v1/checkout.js", () => {
    // Now you can create a Razorpay instance
  });
  <script src="https://checkout.razorpay.com/v1/checkout.js"></script>;
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  console.log("selected option", selectedOption);
  const addnewAddress = (e) => {
    setAddNewAddress(!addNewAddressModal);
    e.preventDefault();
  };
  const captureOrderDetails = (razorpayid, orderId, totalAmount) => {
    const data = {
      orderId: orderId,
      // orderId: orderData._doc._id,
      orderAmount: totalAmount,
      paymentMethod: selectedOption == "online" ? 2 : 1,
      // selectedOption,
      razorPayId: razorpayid,
    };
    postApiData("user/captureProductOrder", data, (response) => {
      if (response) {
        setPayemntOnline(true);
        toast.success("order has been placed");
      }
    });
  };

  useEffect(() => {
    const data = {};
    axios
      .post("https://prosaloncart.com/user/getCartDetails", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(
          "Responsedatadsfhsahhfsdsdg:",
          response.data.data[0].products
        );
        setCartDetails(response.data.data[0]?.products);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [openCartpress]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "whitesmoke",
      position: "absolute",
      zIndex: 899,
      borderRadius: "20px",
      paddingRight: "0px",
    },
  };
  const total = guestUserCartDetails?.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const updatedTotal = total + 50;
  const onClickPayment = () => {
    if (!selectaddress || !name || !phoneNumber || !selectedOption || !email) {
      toast.error("Enter all required details!");
      return;
    }
    const data = {
      phoneNumber: phoneNumber,
      name: name,
      emailId:email,
      paymentMethod: selectedOption === "online" ? 2 : 1,
      addresses: {
        address: selectaddress?.address?.address,
        address2: selectaddress?.address?.address2,
        city: selectaddress?.address?.city,
        state: selectaddress?.address?.state,
        pincode: selectaddress?.address?.pincode,
        name: name,
        phoneNumber: phoneNumber,
      },
      orderAmount: total < 500 ? updatedTotal : total,
      products: result,
    };
    // if(selectaddress==="" ){
    postApiData(
      "user/createProductOrder",
      data,
      (response) => {
        console.log("create order response", response);
        setOrderId(response?._doc?._id);
        setTotalAmount(response?._doc?.totalPayableAmount);

        if (selectedOption === "cash" && name && phoneNumber) {
          // setCash(true);
          toast.success("You Order has been Successfully Placed!!");
          dispatch(successCartEmpty());
          navigate("/successorder");
        }

        if (selectedOption === "online" && name && phoneNumber) {
          const options = {
            // key: "rzp_test_hdlZcgaBXfBRBr",
            // key: "rzp_live_ZmjmD6vtJZ9giq",
            key: "rzp_live_gPOHKfuDqbcZFw",
            amount: response.amount, // Amount in paise
            currency: "INR",
            name: "SalonKart",
            description: "Test Transaction",
            order_id: response.id,
            handler: async (responses) => {
              if (responses) {
                console.log("razorpay ka response ------", responses);
                // captureOrderDetails(response?.razorpay_payment_id, response?.razorpay_order_id);
                captureOrderDetails(
                  responses?.razorpay_payment_id,
                  response?._doc?._id,
                  response?._doc?.totalPayableAmount
                );
                toast.success("Your order has been placed");
                dispatch(successCartEmpty());
                navigate("/successorder");
              }
            },
            prefill: {
              name: name,
              email: "customer@example.com",
              contact: phoneNumber,
            },
            notes: {
              address: "Note Address",
            },
            theme: {
              color: "#61dafb",
            },
          };

          const razorpay = new window.Razorpay(options);
          razorpay.open();

          razorpay.on("payment.failed", function (response) {
            // alert(response.error.reason);
            toast.error(response.error.reason);
          });
        }
      },
      (error) => {
        toast.error("Something went wrong , Please try again!");
        //   alert("order error: " + error.message); // Assuming error.message contains the actual error message
      }
    );
    // }else{
    //   toast.error("Enter all required details!")
    // }
  };

  useEffect(() => {
    getApiCall("user/getUserAddress", (resp) => {
      console.log("user/getUserAddress", resp);
      setFilledAddress(resp.addresses);
    });
  }, [addressmodal, selectedOption, addNewAddressModal]);
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const onclicksummery = () => {
    setSummery(!summery);
  };
  const backpress = () => {
    setAddressModal(false);
  };

  console.log("filled address ------", filledAddress);
  return (
    <div className="flex flex-col-reverse md:flex-row h-auto mt-32 box-border p-4 bg-[#edede9]">
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QVHPDKWFC7"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-QVHPDKWFC7');
          `}
        </script>
        <noscript>
        {`
        <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=1166284498035863&ev=PageView&noscript=1"
          />
        `}
          
        </noscript>
        <script>
          {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1166284498035863');
fbq('track', 'PageView');
  `}
        </script>

        
      </Helmet>
      <div className="w-full md:w-[40%] mx-auto  h-auto box-border p-4 bg-white shadow-lg mt-20 md:mt-0">
        <h1 className="text-3xl font-bold">Shipping Address</h1>
        <input
          type="text"
          placeholder="Enter your Name"
          className="px-4 py-2 border-2 border-gray-500 w-full mt-6 outline-none rounded-lg"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter your Phone Number"
          className="px-4 py-2 border-2 border-gray-500 w-full mt-6 outline-none rounded-lg"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
            <input
          type="text"
          placeholder="Enter your Email"
          className="px-4 py-2 border-2 border-gray-500 w-full mt-6 outline-none rounded-lg"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="w-full mt-5">
          <select className="w-full" onChange={handleSelectChange}>
            {/* Map over the address objects array to create option elements */}
            <option value={selectaddress}>SELECT YOUR ADDRESS</option>
            {filledAddress.map((option) => (
              <option
                key={option._id}
                // value={option.address2 +" "+ option.city +" "+ option.state +" "+ option.pincode}
                // value={`${option.address},${option.address2},${option.city},${option.state},${option.pincode}`}
                value={option._id}
              >
                {option?.address}, {option.address2}, {option.city},{" "}
                {option.state},{option.pincode}
              </option>
            ))}
          </select>
        </div>
        <button
          className="w-full bg-black text-white p-3 text-lg font-bold mt-5"
          onClick={addnewAddress}
        >
          Add New Address
        </button>

        <h1 className="w-full bg-black text-white p-3 text-lg font-bold mt-5 text-center">
          Select Payment Method
        </h1>

        <div
          style={{
            backgroundColor: "",
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label style={{}} className="text-lg px-2 font-bold">
            <input
              type="radio"
              value="cash"
              checked={selectedOption === "cash"}
              style={{ marginRight: "3px" }}
              onChange={handleOptionChange}
            />
            cash
          </label>
          <label
            style={{ marginLeft: "20px" }}
            className="text-lg px-2 font-bold"
          >
            <input
              type="radio"
              value="online"
              checked={selectedOption === "online"}
              style={{ marginRight: "3px" }}
              onChange={handleOptionChange}
            />
            online
          </label>
        </div>
        <button
          className="w-full bg-black text-white p-3 text-lg font-bold mt-5"
          onClick={onClickPayment}
        >
          Complete Purchase
        </button>
      </div>
      <div className="w-full md:w-[50%] mx-auto  h-auto box-border bg-white shadow-lg p-4">
        <h1 className="text-3xl font-bold">Order Summary</h1>

        <div className="grid grid-cols-2 gap-3 my-5">
          <div className="text-black font-medium">Items :</div>
          <div className="text-black font-medium text-right">
            {guestUserCartDetails.length}
          </div>
          <div className="text-black font-medium">Total :</div>
          {total < 500 ? (
            <div className="text-black font-medium text-right">
              ₹ {total} + ₹ 50(Delivery Charge) = {total + 50}
            </div>
          ) : (
            <div className="text-black font-medium text-right">₹ {total}</div>
          )}
        </div>

        <div className=" w-full h-[280px] overflow-y-auto">
          {guestUserCartDetails?.map((item, index) => (
            <div className="flex justify-between items-center w-full h-[100px] p-2 my-5 border-2 border-gray-200 ">
              <img src={item?.images[0]} className="w-20 h-full" />
              <div className="flex flex-col">
                <h1 className="text-lg font-bold">
                  {truncateProductName(item.name, 32)}
                </h1>
                <div className="flex justify-between items-center">
                  <h1 className="text-md font-semibold">
                    ₹ {item.price * item.quantity}
                  </h1>
                  <h1 className="text-md font-semibold">Qty:{item.quantity}</h1>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {
        <AddNewAddress
          addNewAddress={addNewAddressModal}
          setAddNewAddress={setAddNewAddress}
        />
      }
    </div>
  );
};

export default Shipping;
