import React from 'react'
import TestimonalSlider from '../../commonComponent/slider/TestimonialSider'

const Testimonial = () => {
  return (
    <div className='w-full h-auto md:h-[450px] bg-black flex flex-col my-10 md:my-10 px-4 py-2'>
    <h1 className='text-xl md:text-3xl font-bold text-center text-white mt-3'>✨ TESTIMONIALS SPOTLIGHT ✨</h1>
    <TestimonalSlider />
    </div>
  )
}

export default Testimonial