import React, { useState } from 'react';
import { postApiData } from '../../utils/services';
import toast from 'react-hot-toast';

const Unlisting = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    checkboxes: {
      Location: false,
      'Personal Info': false,
      Messages: false,
      Photos: false,
      Contacts: false,
      'App info and performance': false,
      'Device or other IDs': false,
    },
  });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    if (type === 'checkbox') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        checkboxes: {
          ...prevFormData.checkboxes,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Use formData object for submission or any further processing
    postApiData("user/deleteTheUserData",formData,
    (resp)=>{
        toast.success("Your Data has been Deleted!")
        console.log(resp)
        setFormData({
            name: "",
            phoneNumber: "",
            checkboxes: {
              Location: false,
              "Personal Info": false,
              Messages: false,
              Photos: false,
              Contacts: false,
              "App info and performance": false,
              "Device or other IDs": false,
            },
          });
    },(error)=>{
        console.log(error)
        toast.error("Something went wrong!")
    }
)
    console.log(formData);
  };

  return (
    <div className='mt-32'>
      <h1 className='mt-10 bg-black text-white text-center text-xl font-bold p-4'>
        Request for data deletion from Prosalon App
      </h1>
      <h1 className='mt-5 text-black text-center text-xl font-bold p-4'>
        To Request Permanent or partial deletion of your account please fill the form below:
      </h1>
      <div className='flex flex-col w-1/2 mx-auto'>
        <form onSubmit={handleSubmit}>
          <div className='flex justify-between items-center'>
            <input
              placeholder='Name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              className='w-1/3 border-2 border-black p-4 rounded-xl outline-none'
            />
            <input
              placeholder='Phone Number'
              name='phoneNumber'
              value={formData.phoneNumber}
              onChange={handleChange}
              className='w-1/3 border-2 border-black p-4 rounded-xl outline-none'
            />
          </div>
          <h1 className='mt-5 text-black text-left text-xl font-bold p-4'>Info check:</h1>
          <div className='flex items-center flex-wrap'>
            {Object.keys(formData.checkboxes).map((key) => (
              <React.Fragment key={key}>
                <input
                  type='checkbox'
                  id={key}
                  name={key}
                  checked={formData.checkboxes[key]}
                  onChange={handleChange}
                />
                <label htmlFor={key} className='mx-4 text-black font-medium'>
                  {key}
                </label>
              </React.Fragment>
            ))}
          </div>
          <button type='submit' className='my-4 bg-blue-500 text-white px-4 py-3 rounded-xl'>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Unlisting;
