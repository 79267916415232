import { postApiData } from "../../utils/services";
const postProductData = (productData,) => {
  console.log("productdata",productData)
  return (dispatch, getState) => {
    dispatch({ type:'addproductTocart', payload: { productData, } });

  };
};
const decreaseQuantity=(productData,quantity)=>{
   return(dispatch,getState)=>{
    console.log("productDataremove",productData)
    dispatch({type:'DecreaseQuantity',payload:{productData}})
   }
}
const cartRemove = (productData,) => {
  console.log("productremove",productData)
    return(dispatch,getState)=>{
      dispatch({ type: "RemoveItem", payload: productData._id });
    }
};

const cartTotal = (subtotal) => {
  return (dispatch) => {
    dispatch({ type: "CarttotalAmount", payload: subtotal });
  };
};
const userDetails = (userDetailsData) => {
  console.log("userDetailsData",userDetailsData)
  return (dispatch) => {
    dispatch({ type: "SET_USERDETAILS", payload: userDetailsData });
  };
};
const paymentTotal = (payment) => {
  console.log("jkdfhshjkdfshkdfshdfhjsdfhjshjdfs");
  return (dispatch) => {
    dispatch({ type: "payment_TotalAmount", payload: payment });
  };
};

const totalProduct = (totalproducts) => {
  console.log("totalproducts", totalproducts);
  return (dispatch) => {
    dispatch({ type: "cardTotalProduct", payload: totalproducts });
  };
};
const filterProducts = (productCategory) => {
  return (dispatch) => {
    dispatch({ type: "filterProductData", payload: productCategory });
  };
};
const sliderPricedata = (price) => {
  return (dispatch) => {
    dispatch({ type: "filterProductPrice", payload: price });
  };
};
const userLogOut=()=>{
  return(dispatch)=>{
    dispatch({type:"logoutDetails",payload:{}})
  }

}
const cartTotalpayableAmount=(data)=>{
  return(dispatch)=>{
    console.log("cartTotalpayableAmount",data)
    dispatch({type:'totalCart_Amount',payload:data})
  }
}

const successCartEmpty=()=>{
  return(dispatch)=>{
    dispatch({type:'successorder',payload:{}})
  }
}
export {
  postProductData,
  cartRemove,
  cartTotal,
  userDetails,
  paymentTotal,
  totalProduct,
  filterProducts,
  sliderPricedata,
  userLogOut,
  cartTotalpayableAmount,
  decreaseQuantity,
  successCartEmpty
};
