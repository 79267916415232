import React from 'react'
import './terms.css'
import { termConditionData } from '../../utils/dummydata'

export default function TermsAndCondition() {
  return (
    <div className='mt-24'>
    {
       termConditionData.map((item,index)=>{
           return(
       <div className='faqContainer '>
           <p className='faqQusetion'>{item.question}</p>
           <p className='faqAns'>{item.ans}</p>

       </div>
    

           )
    
       })

    }
   </div>
  )
}
