import React, { useState } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

const DropdownAllCheckboxes = ({checkedBrands, setCheckedBrands}) => {
  const [isOpen, setIsOpen] = useState(false);
//   const [checkedBrands, setCheckedBrands] = useState({
//     Loreal: false,
//     'Skin Co': false,
//     Argatin: false,
//     SpringH2O: false,
//   });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (brand) => {
    setCheckedBrands((prevCheckedBrands) => ({
      ...prevCheckedBrands,
      [brand]: !prevCheckedBrands[brand],
    }));
  };
  console.log("checked brandss----",checkedBrands)

  return (
    <div className="w-full">
      <button
        onClick={toggleDropdown}
        className="w-full bg-gray-200 text-left px-4 py-2 flex justify-between items-center"
      >
        Brands
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </button>
      {isOpen && (
        <div className="border border-gray-200 bg-white">
          {['Loreal','Skinconyc', 'Argatin',"Spring H2O"].map((brand) => (
            <label key={brand} className="flex items-center px-4 py-2">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                checked={checkedBrands[brand]}
                onChange={() => handleCheckboxChange(brand)}
              />
              <span className="ml-2">{brand}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownAllCheckboxes;
