// src/Sitemap.js

import React from 'react';

const sitemapData = [
  { loc: 'https://prosaloncart.com/', priority: 0.6 },
  { loc: 'https://prosaloncart.com/Skinco', priority: 1.0 },
  { loc: 'https://prosaloncart.com/Argatin', priority: 1.0 },
  { loc: 'https://prosaloncart.com/cartdescription/', priority: 0.8 },
  { loc: 'https://prosaloncart.com/best-sellers', priority: 1.0 },
  { loc: 'https://prosaloncart.com/best-sellers-combos', priority: 1.0 },
  { loc: 'https://prosaloncart.com/newlaunches', priority: 1.0 },
  { loc: 'https://prosaloncart.com/haircareallproducts', priority: 1.0 },
  { loc: 'https://prosaloncart.com/skincareallproducts', priority: 1.0 },
  { loc: 'https://prosaloncart.com/AboutUs', priority: 1.0 },
];

const Sitemap = () => {
  return (
    <div>
      <h1>Sitemap</h1>
      <ul>
        {sitemapData.map((item, index) => (
          <li key={index}>
            <a href={item.loc} target="_blank" rel="noopener noreferrer">
              {item.loc}
            </a> - Priority: {item.priority}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sitemap;
