// import { instance } from "./services";
import { store } from "../Redux/store/store";
import { instance } from "./services";


const setAuthorizationToken = (token) =>{
//     const authToken = store.getState()
// const token=authToken.authReducer.userData
    if(token){
      //  instance.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }
  export default setAuthorizationToken;