import React, { useEffect, useState } from 'react'
import Footer from '../footer'
import { LOCAL_IMAGES } from '../../utils/localimages'
import { Link } from 'react-router-dom'
import './profile.css'
import { postApiData } from '../../utils/services'
import AccountCard from '../../commonComponent/accountCard'
import OrderImg from '../../assets/images/tracking.png';
import LoginImg from  '../../assets/images/login.png';
import AddressImg from '../../assets/images/location.png';
import PaymentImg from '../../assets/images/credit-card.png';
import AboutImg from '../../assets/images/customer-service.png'



export default function Profile() {
    const [orderHistory, setHistory] = useState([])

    console.log("orderHistory", orderHistory)
    useEffect(() => {
        const data = {
            phoneNumber: "7462378682",
        }
        postApiData(
            "user/getCartDetails",
            data,
            (response) => {
                setHistory(response[0]?.products);

            },
            (error) => {
                console.log("erro");
            }
        );

    }, [])

    return (
        // <div>
        //     <div className='profileContainer'>
        //         <div style={{ padding: '0px', background: 'gainsboro', height: '360px', width: '200px' }}>
        //             <div style={{ marginLeft: '10px', backgroundColor: '', paddingTop: '20px' }}>

        //                 <div style={{ display: 'flex', alignItems: 'center' }}>
        //                     <img src={LOCAL_IMAGES.userIcon} alt="userIcon"
        //                         style={{ height: '20px', width: '20px' }} />
        //                     <Link to={'/Profile'}>
        //                         <p className='myprofile'
        //                         >My Profile</p>

        //                     </Link>
        //                 </div>
        //                 <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        //                     <img src={LOCAL_IMAGES.packageIcon} alt="order"
        //                         style={{ height: '20px', width: '20px' }} />
        //                     <p className='myprofile'>Orders</p>
        //                 </div>
        //             </div>
        //         </div>
        //         <div style={{ background: 'whitesmoke', width: '100%' }}>
        //             <p className='myprofile'>{'My Orders'}</p>
        //             <div style={{ borderBottom: '1px solid whitesmoke', width: '100%', marginTop: '7px' }}></div>
        //             {/* <div className='orderNumber'>
        //             <span>Order Number</span>
        //             <span>SK-177653505-8603074</span>
        //           </div> */}
        //             <div style={{background:''}}>
        //                 {
        //                     orderHistory.map((item,index) => {
        //                         return (
        //                           <div style={{padding:'20px',background:'grey',marginTop:'10px',display:'flex'}}>
        //                             <img src={item?.image_link} alt='img' style={{height:'160px',width:'150px'}}/>
        //                             <span>{item?.name}</span>
        //                             <span>{item?.price}</span>

        //                           </div>
        //                     )
        //                     })



        //                 }

        //             </div>
        //         </div>
        //     </div>
        //     <Footer />
        // </div>
<>


        <div className='flex flex-col w-[90%]   mx-auto rounded-lg py-10 mt-24'>
            <h1 className='text-2xl font-bold text-gray-600 text-center mb-5'>Your Account</h1>
            <div className='flex justify-around items-center flex-wrap gap-x-10 gap-y-10 '>
                <AccountCard title={"Your Orders"} desc={"Track, return or buy things again"} img={OrderImg} src="/ordersummary"/>
                <AccountCard title={"Login & Security"} desc={"Edit login, name, and mobile number"} img={LoginImg} src=""/>
                <AccountCard title={"Your Addresses"} desc={"Edit addresses for orders and gifts"} img={AddressImg} src="/addresses"/>
                <AccountCard title={"Payment Options"} desc={"Edit or add payment methods"} img={PaymentImg} src=""/>
                <AccountCard title={"Contact us"} desc={""} img={AboutImg} src="/ContactUs"/>
            </div>
        </div>
        </>
    )
}
