import { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
import { postApiData } from '../../utils/services';
import toast from 'react-hot-toast';
const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION 
  });

export default function MediaCard({id,onClose,isRating,setIsRating}) {
    console.log("productId",id)
    const [name,setName] = useState("");
    const [description,setDescription] = useState("");
    const [rate,setRate] = useState(0)
    const [images, setImages] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const handleDrop = async (acceptedFiles) => {
        const uniqueId = uuidv4()
        const promises = acceptedFiles.map((file) => {
          const params = {
              Bucket: 'tphpdfs',
              Key: `uploaded/${uniqueId}/${file.name}`,
              Body: file,
          };
          return s3.upload(params).promise();
        });
    
        try {
          const uploadedFiles = await Promise.all(promises);
          const imageUrls = uploadedFiles.map((file) => file.Location);
          setImages(prevImages => [...prevImages, ...imageUrls]);
          setFileNames(prevFileNames => [...prevFileNames, ...acceptedFiles.map((file) => file.name)]);
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });

    const handleSubmit=()=>{
        const data = {
            name: name,
            description : description,
            productId: id,
            imageUrl: images,
            rating: rate
        };
        postApiData("review/postReviewForProduct", data ,
            (resp)=>{
                console.log(resp);
                setName("")
                setDescription("");
                setRate(0);
                setImages([])
                setFileNames([])
                onClose()
                setIsRating(!isRating)
                toast.success("Thank you for your feedback.")
            },
            (error)=>{
                console.log(error)
                toast.error("Already have your feedback")
            }
        )
    }
    console.log("filenames:",fileNames)
    console.log("images:",images)
    console.log("rate",rate)
  return (
    <Card sx={{ maxWidth: 500,marginTop: "10px", padding:"10px" }}>
        <h1 className='text-lg font-bold font-serif mb-5'>Write Review</h1>
        <Rating
            name="simple-controlled"
            sx={{position:"center",marginBottom:"14px"}}
            size='large'
            value={rate}
            onChange={(event, newValue) => {
            setRate(newValue);
         }}
        />
      <TextField
          id="outlined-textarea"
          label="Enter Name"
          placeholder="Name"
          value={name}
          onChange={(e)=>setName(e.target.value)}
          multiline
          className='w-full '
          sx={{marginTop:"14px"}}
        />
      <TextField
          id="outlined-textarea"
          label="Enter descrption"
          placeholder="description"
          value={description}
          onChange={(e)=>setDescription(e.target.value)}
          multiline
          className='w-full'
          sx={{marginTop:"14px"}}
        />
      <div {...getRootProps()} className='dropzone'>
              <input {...getInputProps({ multiple: true })} />
              <p className='border border-black my-3 px-4 py-2 rounded-md '>Drag 'n' drop images here, or click to select image</p>
              <ul>
                {fileNames.map((fileName, index) => (
                  <li key={index}>{fileName}</li>
                ))}
              </ul>
            </div>
      
      <CardActions>
        <Button size="large" variant="contained" onClick={handleSubmit}>Submit</Button>
      </CardActions>
    </Card>
  );
}
