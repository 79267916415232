import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../../context/CartContext';
import ProductCart from '../../commonComponent/customcard/productCart';
import CustomProductTag from '../../commonComponent/gtm';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TestCard from '../../commonComponent/customcard/testCart';

const SearchResult = () => {
    const { searchResults } = useContext(CartContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    console.log("search kiii results -------", searchResults);

    return (
        <div className='text-lg text-white flex justify-center items-center gap-10 mt-24 mb-16 flex-wrap'>
            {loading ? (
                <Box sx={{ display: 'flex', height:'70vh',justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : searchResults?.length > 0 ? (
                searchResults?.map((item, index) => (
                    <React.Fragment key={index}>
                        <TestCard key={index} item={item} img={item?.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp - item.price) / item.mrp) * 100)} stockQuantity={item?.stockQuantity} productType={"combos"} />
                        <CustomProductTag product={item} />
                    </React.Fragment>
                ))
            ) : (
                <div className='h-[70vh] w-full flex justify-center items-center'>
                    <h1 className='text-xl font-bold'>No Results Found</h1>
                </div>
            )}
        </div>
    );
};

export default SearchResult;
