import React, { useEffect, useState } from "react";
import { postApiData } from "../utils/services";
import BestSeller from "../bestseller";
import { Link } from "react-router-dom";
import Editform from "../Editform/Editform";

const Productformdetails = () => {
  const [productformdetail, setProductformdetail] = useState([]);
  const apidata = {
    brand: "",
  };
  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setProductformdetail(callBack);
      console.log("productormdetaiils", callBack);
    });
  }, []);

  return (
    <div className="mt-24">
      <Link
        to={{
          pathname: "/productdetailform",
          state: { productdata: productformdetail },
        }}
      >
        <div className="underline font-bold row justify-end flex mr-11">
          {/* View Details */}
        </div>
      </Link>
      <div>
        <BestSeller productdata={productformdetail} form={"form"} />
        {/* <Editform productdata={productformdetail}/> */}
      </div>
    </div>
  );
};

export default Productformdetails;
