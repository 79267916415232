// CartContext.js
import React, { createContext, useState } from 'react';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);
  const [searchResults,setSearchResults] = useState([]);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <CartContext.Provider value={{ isCartOpen,setIsCartOpen, toggleCart, cartTotal, setCartTotal,searchResults,setSearchResults }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };
