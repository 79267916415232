import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Login from '../../Register/Register';
import { postProductData } from '../../Redux/actions';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { CartContext } from '../../context/CartContext';

const TestCard = ({ item,name,img,desc,price,mrp,discount,stockQuantity,productType }) => {
    const { isCartOpen,setIsCartOpen} = useContext(CartContext);
    
    
    console.log("discount",discount);
    const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const token = useSelector((store) => store.authReducer.userData);
    const addToCart = (product) => {
          dispatch(postProductData(product
          ));  
          // setIsCartOpen(true)  
          setIsCartOpen(true)   
          toast.success("Product Added Successfully!!")
        
      };
  const truncateName = (name, maxLength) => {
    if (name?.length <= maxLength) return name;
    return name?.slice(0, maxLength) + '...';
  };

  return (
    <div className="w-[150px] md:w-64  h-[350px] md:h-[400px]  overflow-hidden flex flex-col justify-between mx-0 md:mx-5">
     <Link to={`/products/${item.shortUrl}`} state={{ data: item ,productType:productType}} className=' h-[45%] md:h-[55%]'>
      <img src={img} alt={name} className="w-full h-full  object-cover" style={{ filter: "brightness(95%)", backgroundColor: "#F9F1E0" }}/>
     </Link>
      <div className="flex flex-col items-start">
      <div className='flex justify-start gap-x-1 md:gap-x-5 items-center '>
        <p className='bg-red-700 text-white font-semibold text-xs md:text-lg px-2 md:px-3 py-1 '>{discount}%</p>
        <p className=' text-red-900 font-semibold text-[10px] md:text-sm px-2 py-1'>Limited Time Offer</p>
      </div>
      <div className='flex  justify-start items-center gap-x-2 md:gap-x-4 flex-wrap my-2'>
        <p className='text-sm md:text-lg font-semibold text-black '>₹{price}</p>
        <p className='text-xs md:text-sm text-black'>List Price: </p>
        <p className='text-sm md:text-lg line-through text-black font-semibold'>₹{mrp}</p>
      </div>
        <h2 className="text-sm md:text-lg font-semibold text-start text-black">{truncateName(name, 40)}</h2>
      </div>
      {
        stockQuantity === 0 ? (
      <button className='flex justify-center items-center text-white font-semibold text-md px-5 py-2 cursor-not-allowed bg-gray-700 hover:text-slate-100 duration-150 ease-in-out' >Out Of Stock</button>
        ) :
      <button className='flex justify-center items-center bg-black text-white font-semibold text-md px-5 py-2 cursor-pointer hover:bg-gray-800 hover:text-slate-100 duration-150 ease-in-out'  onClick={() => addToCart(item)}>Add to cart</button>
      }
    </div>
  );
};

export default TestCard;
