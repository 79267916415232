import React, { useEffect, useState } from "react";
import Editform from "../Editform/Editform";
// import "./formupdate.css";
// import Multiselect from "multiselect-react-dropdown";

// import { useLocation } from "react-router-dom";
// import { postApiData } from "../utils/services";
// import Editform from "../Editform/Editform";

 const Formupdate = () => {
//   const { state } = useLocation();
//   const [showForm, setShowForm] = useState(false);
//   console.log("abc", state);
//   console.log(state?.data?.image_link);
//   console.log(state?.data?._id);
//   //const [imageLink, setImageLink] = useState(state?.data?.image_link || '');
//   const [name, setName] = useState(state?.data?.name || "");
//   const [weight, setWeight] = useState(state?.data?.weight || "");
//   const [eancode, setEancode] = useState(state?.data?.eancode || "");
//   const [dimention, setDimention] = useState(state?.data?.dimention || "");
//   const [color, setColor] = useState(state?.data?.color || "");
//   const [hairtype, sethairType] = useState(state?.data?.hairtype || "");
//   const [ingredients, setIngredients] = useState(
//     state?.data?.ingredients || ""
//   );
//   const [itemform, setitemForm] = useState(state?.data?.itemform || "");
//   const [liquidvolume, setliquidVolume] = useState(
//     state?.data?.liquidvolume || ""
//   );
//   const [productbenefit, setproductBenefit] = useState(
//     state?.data?.productbenefit || ""
//   );
//   const [srno,setsrNo]=useState(Number)
//   const [price, setPrice] = useState(state?.data?.price || "");
//   const [brand, setBrand] = useState(state?.data?.brand || "");
//   const [discount, setDiscount] = useState(state?.data?.discount || "");
//   const [mrp, setMrp] = useState(state?.data?.mrp || "");
//   const [description, setdescription] = useState(
//     state?.data?.description || ""
//   );
//   const [images, setImages] = useState(state?.data?.images || []);
//   //const [products, setProducts] = useState(state?.data?.products || []);

//   const [productalldetail, setProductalldetail] = useState([]);

//   const [selectedProducts, setSelectedProducts] = useState(
//     state?.data?.products || []
//   );

//   const productOptions = productalldetail.map((product) => ({
//     // id: product.name,

//     //
//     name: product.name,

//     id: product._id,

//     // id:product._id
//   }));
//   console.log("fkghgfhdjgkfddddddddddddddddfd", productOptions);

//   const onSelect = (selectedList) => {
//     console.log("dfgdt345", selectedList);
//     setSelectedProducts(selectedList);
//   };
//   const HandleUpdate = () => {
//     postApiData(
//       "user/updateComboProducts",
//       {
//         id: state?.data?._id,
//         obj: {
//           name: name,
//           price: price,
//           products: selectedProducts,
//           discount: discount,
//           brand: brand,
//           mrp: mrp,
//           description: description,
//           images: images,
//           weight: weight,
//           eancode: eancode,
//           dimention: dimention,
//           color: color,
//           hairtype: hairtype,
//           ingredients: ingredients,
//           itemform: itemform,
//           liquidvolume: liquidvolume,
//           productbenefit: productbenefit,
//           srno:srno
//         },
//       },
//       (resp) => {
//         console.log("reponsapi add", resp);
//       }
//     );
//   };
  

//   const apidata = {
//     brand: "",
//   };
//   useEffect(() => {
//     postApiData("user/getAllProducts", apidata, (callBack) => {
//       setProductalldetail(callBack);
//       console.log("navbar", callBack);
//     });
//   }, []);

//   console.log("productaldetails", selectedProducts);

//   const handleAddButtonClick = () => {
//     setShowForm(!showForm);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form Data:", {
//       //imageLink,
//       name,
//       weight,
//       dimention,
//       //products,
//       color,
//       hairtype,
//       ingredients,
//       itemform,
//       liquidvolume,
//       productbenefit,
//       price,
//       brand,
//       mrp,
//       description,
//       images,
//     });

//     // Reset the form state or perform any other actions
//     //setImageLink('');
//     setName("");
//     setSelectedProducts([]);
//     setPrice("");
//     setWeight("");
//     setEancode("");
//     setDimention("");
//     setColor("");
//     sethairType("");
//     setIngredients("");
//     setitemForm("");
//     setliquidVolume("");
//     setproductBenefit("");
//     setBrand("");
//     setMrp("");
//     setdescription("");
//     setDiscount("");
//     setImages([]);
//     setShowForm(false);
//   };

  return (
    <Editform/>
    // <div className="app">
    //   <button
    //     onClick={handleAddButtonClick}
    //     style={{
    //       display: "flex",
    //       marginLeft: "700px",
    //       backgroundColor: "blue",
    //       color: "white",
    //       width: "100px",
    //       height: "50px",
    //       border: "none",
    //       borderRadius: "5px",
    //       cursor: "pointer",
    //       justifyContent: "center",
    //     }}
    //   >
    //     Add
    //   </button>
    //   {showForm && (
    //     <form className="add-form" onSubmit={handleSubmit}>
    //       {/* <label htmlFor="imageLink">Image Link:</label>
    //       <input
    //         type="text"
    //         id="imageLink"
    //         name="imageLink"
    //         value={imageLink}
    //         onChange={(e) => setImageLink(e.target.value)}
    //       /> */}

    //       <label htmlFor="name">Name:</label>
    //       <input
    //         type="text"
    //         id="name"
    //         name="name"
    //         value={name}
    //         onChange={(e) => setName(e.target.value)}
    //       />

    //       {/* <label htmlFor="products">Products:</label>
    //       <select
    //         id="products"
    //         name="products"
            
    //       //value={productName}
    //       //onChange={(e) => setProductName(e.target.value)}
    //       >
    //         <option value="">Select a product</option>
    //         {productalldetail.map((product,index) => (
    //           <option key={product.index} value={product.name}>
    //             {product.name}
    //           </option>
    //         ))} 
           
    //       </select>


    //   */}

    //       <label htmlFor="products">Products:</label>
    //       <Multiselect
    //         options={productOptions}
    //         selectedValues={selectedProducts}
    //         onSelect={onSelect}
    //         displayValue="name"
    //         placeholder="Select a product"
    //         id="products"
    //         name="products"
    //       />

    //       <label htmlFor="price">Price:</label>
    //       <input
    //         type="text"
    //         id="price"
    //         name="price"
    //         value={price}
    //         onChange={(e) => setPrice(e.target.value)}
    //       />

    //       {/* Add more fields */}

    //       <label htmlFor="price">Weight:</label>
    //       <input
    //         type="text"
    //         id="weight"
    //         name="weight"
    //         value={weight}
    //         onChange={(e) => setWeight(e.target.value)}
    //       />

    //       <label htmlFor="eancode">EAN Code:</label>
    //       <input
    //         type="text"
    //         id="eancode"
    //         name="eancode"
    //         value={eancode}
    //         onChange={(e) => setEancode(e.target.value)}
    //       />
    //       <label htmlFor="price">Dimention:</label>
    //       <input
    //         type="text"
    //         id="dimention"
    //         name="dimention"
    //         value={dimention}
    //         onChange={(e) => setDimention(e.target.value)}
    //       />

    //       <label htmlFor="color">Color:</label>
    //       <input
    //         type="text"
    //         id="color"
    //         name="color"
    //         value={color}
    //         onChange={(e) => setColor(e.target.value)}
    //       />

    //       <label htmlFor="hairtype">Hairtype:</label>
    //       <input
    //         type="text"
    //         id="color"
    //         name="hairtype"
    //         value={hairtype}
    //         onChange={(e) => sethairType(e.target.value)}
    //       />

    //       <label htmlFor="ingredients">Ingredients:</label>
    //       <input
    //         type="text"
    //         id="color"
    //         name="color"
    //         value={color}
    //         onChange={(e) => setIngredients(e.target.value)}
    //       />
    //       <label htmlFor="itemform">Item form:</label>
    //       <input
    //         type="text"
    //         id="itemform"
    //         name="itemform"
    //         value={itemform}
    //         onChange={(e) => setitemForm(e.target.value)}
    //       />
    //       <label htmlFor="itemform">Liquid Volume:</label>
    //       <input
    //         type="text"
    //         id="itemform"
    //         name="itemform"
    //         value={itemform}
    //         onChange={(e) => setitemForm(e.target.value)}
    //       />
    //       <label htmlFor="itemform">Product Benefits:</label>
    //       <input
    //         type="text"
    //         id="productbenefit"
    //         name="productbenefit"
    //         value={productbenefit}
    //         onChange={(e) => setproductBenefit(e.target.value)}
    //       />
    //       <label htmlFor="itemform">Serial Number:</label>
    //       <input
    //         type="number"
    //         id="srno"
    //         name="srno"
    //         value={srno}
    //         onChange={(e) => setsrNo(e.target.value)}
    //       />
          
    //       <label htmlFor="brand">Brand:</label>
    //       <input
    //         type="text"
    //         id="brand"
    //         name="brand"
    //         value={brand}
    //         onChange={(e) => setBrand(e.target.value)}
    //       />

    //       <label htmlFor="mrp">mrp:</label>
    //       <input
    //         type="text"
    //         id="mrp"
    //         name="mrp"
    //         value={mrp}
    //         onChange={(e) => setMrp(Number(e.target.value))}
    //       />

    //       <label htmlFor="description">Description:</label>
    //       <textarea
    //         type="text"
    //         id="description"
    //         className="imagesall"
    //         name="description"
    //         //value={description}
    //         onChange={(e) => setdescription(e.target.value)}
    //       />

    //       <label htmlFor="images">Images:</label>
    //       <textarea
    //         type="text"
    //         id="images"
    //         className="imagesall"
    //         name="images"
    //         value={images.join(", ")}
    //         onChange={(e) =>
    //           setImages(e.target.value.split(",").map((img) => img.trim()))
    //         }
    //       />

    //       <button type="submit" onClick={HandleUpdate}>
    //         Submit
    //       </button>
    //     </form>
    //   )}
    // </div>
  );
};

export default Formupdate;
