import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { getApiCall } from "../../utils/services";
import { Link } from "react-router-dom";

const CommonSlider = ({ brandimg, brand }) => {
  const sliderRef = useRef();
  const [images, setImages] = useState([]);
  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };
  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    // arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getApiCall(
      "user/bannersApi",
      (resp) => {
        setImages(resp[brand]);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }, []);

  return (
    <div className="relative">
      <>
        <Slider ref={sliderRef} {...settings}>
          {images?.map((image, index) => (
            <div key={index}>
              {" "}
              <Link to={`/${image.value}`}>
                <div
                  key={index}
                  className="md:w-full aspect-auto h-auto md:h-full box-border block overflow-hidden  bg-none opacity-100 border-0 m-0 p-0 relative"
                  style={{
                    width: "initial",
                  }}
                >
                  {/* Apply the className here */}

                  <span
                    className="box-border block bg-none opacity-100 border-0 m-0  pt-[40.75%] md:pt-[28.75%]"
                    style={{
                      width: "initial",
                      height: "initial",

                      // padding: "55.75% 0px 0px",
                    }}
                  />
                  <img
                    lazyboundary="800px"
                    sizes="100vw"
                    src={image?.image_link}
                    alt={`Slide ${index}`}
                    decoding="async"
                    data-nimg="responsive"
                    className="border border-silverSurfer-300 rounded absolute inset-0 box-border p-0 border-none m-auto block w-0 h-0"
                    style={{
                      minWidth: "100%",
                      maxWidth: "100%",
                      minHeight: "100%",
                      maxHeight: "100%",
                    }}
                  />

                  {/* <img src={image.image_link} alt={`Slide ${index}`} className='w-full h-full object-cover'/> */}
                </div>
              </Link>
              {/* Apply the className here */}
              {/* <Link to={`/${image?.value}`}>
                <img
                  src={image?.image_link}
                  alt={`Slide ${index}`}
                  className="w-full h-full"
                />
              </Link> */}
            </div>
          ))}
        </Slider>
      </>

      <div className="arrow-containerbrand">
        <button
          className="text-2xl md:text-3xl absolute top-1/2 left-1"
          onClick={goToPrev}
        >
          <IoIosArrowDropleftCircle className="text-gray-500" />
        </button>
        <button
          className="text-2xl md:text-3xl absolute top-1/2 right-1"
          onClick={goToNext}
        >
          <IoIosArrowDroprightCircle className="text-gray-500" />
        </button>
      </div>
    </div>
  );
};

export default CommonSlider;
