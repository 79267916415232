import React, { useContext, useEffect, useState } from "react";
import logo from "./img/logo.png";
import skinco from "./img/skinco_logo.png";
import argatin from "./img/argatin_logo.png";
import user from "./img/user.png";
import kart from "./img/kart.png";
import searchicon from "./img/search_icon.png";
import { PiCirclesThreePlusBold } from "react-icons/pi";
import { FaCartShopping, FaCircleUser, FaUserLarge } from "react-icons/fa6";
import { FaBorderAll } from "react-icons/fa";
import { IoIosLogIn } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import Login from "../Register/Register";
import { useDispatch, useSelector } from "react-redux";
import { LOCAL_IMAGES } from "../utils/localimages";
import Cart from "../commonComponent/cardDetails";
import { Link, useNavigate} from "react-router-dom";
import Register from "../Login/Login";
import SignUp from "../Login/Login";
import AddressModal from "../commonComponent/addressModal";
import "./navbar.css"; // Import your custom CSS file
import ProfilePopUp from "../components/profilePopup";
import { SlUser } from "react-icons/sl";

import { userLogOut } from "../Redux/actions";
import { postApiData } from "../utils/services";
import { MdKeyboardArrowDown } from "react-icons/md";
import ReactGA from "react-ga";
import { CartContext } from "../context/CartContext";
import SmallNavbar from "./SmallNavbar";
import BigNavbar from "./BigNavbar";

const TRACKING_ID = "G-56Q0JTSZKG";
ReactGA.initialize(TRACKING_ID);
const Navbar = () => {
  const navigate = useNavigate();
  const { isCartOpen,setIsCartOpen,cartTotal,setCartTotal,searchResults,setSearchResults} = useContext(CartContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [isbrand, setBrand] = useState(false);
  const [options, setOptions] = useState(false);
  const [hamburgermenu, sethamburgermenu] = useState(false);
  const [showLogin, setshowLogin] = useState(false);
  const [headersearch, setHeaderSearch] = useState("");
  const cardData = useSelector((state) => state.addProduct);
  const authToken = useSelector((state) => state?.authReducer?.userData);
  const cartLengthData=useSelector((store)=>store.addProduct.cart)
  const cartData = useSelector((store) => store.addProduct);
  const itemAddedCount = cardData?.cart?.length;
  const [showCart, setShowCart] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [test, setTest] = useState(isCartOpen);
  const [search, setSearch] = useState("");
  const [branddetails, setbrandDetails] = useState(false);
  const dispatch = useDispatch();
  //const [islogin, setLogin] = useState(true);

  const [productformdetail, setProductformdetail] = useState([]);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // const [searchResults,setSearchResults] = useState([]);

// for outside click

console.log('cartData',cartLengthData,cartLengthData.length)
const handleOutsideClick = (event) => {
  if (!event.target.closest(".dropdown-menu")) {
    setIsOpen(false);
  }
};

useEffect(() => {
  if (modalIsOpen) {
    document.addEventListener("mousedown", handleOutsideClick);
  } else {
    document.removeEventListener("mousedown", handleOutsideClick);
  }

  return () => {
    document.removeEventListener("mousedown", handleOutsideClick);
  };
}, [modalIsOpen]);


 
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    // Update isSmallScreen state on window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1100);
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const apidata = {
    brand: "",
  };
  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setProductformdetail(callBack);
      console.log("navbar", callBack);
    });
  }, []);
  console.log("apidataa", productformdetail);

  const onpress = () => {
    setIsOpen(false);
    setShowCart(true);
    setIsCartOpen(true)
  };

  const  openSmallUserModal=()=>{
    console.log("before",modalIsOpen)
    setIsOpen(!modalIsOpen)
    console.log("after",modalIsOpen)
  }
  const openUserModal=()=>{
    if(!authToken){
      navigate('login')
    }else{
      setIsOpen(!modalIsOpen);
    }
  }
  const loginpress = () => {
    navigate("/ordersummary")
  };
  function loginmodelpress() {
    setLoginModal(!loginModal);
    setIsOpen(!modalIsOpen);
  }
  const onchangeSearchText = (e) => {
    setSearch(e.target.value);
  };
  function hamburgermenuopen() {
    sethamburgermenu(!hamburgermenu);
  }
  function toggleOption() {
    setOptions(!options);
  }
  const logout = () => {
    dispatch(userLogOut());
  };
  const smalllogout = () => {
    dispatch(userLogOut());
    sethamburgermenu(false);
  };
  function handleLinkClick() {
    sethamburgermenu(false);
  }

  function handlemouseEnter() {
    setBrand(!isbrand);
  }
  function handlemouseLeave() {
    // setBrand(false);
  }
  function brandddetails() {
    setbrandDetails(!branddetails);
  }
  function hanldeMyProfileButtons(){
    console.log("click ki maa ka bhosda")
    setIsOpen(!modalIsOpen);
    navigate('/myprofile')
  }
  const hanldeMyProfileButton=()=>{
    setIsOpen(!modalIsOpen);
    navigate('/myprofile')
  }
  // const hanldeMyProfileButtons=()=>{
    
  //   setIsOpen(!modalIsOpen);
  //   navigate('/myprofile')
  // }
  console.log("carts m total",cartTotal)

  useEffect(()=>{
    const data ={
      name : searchTerm
    }
    if (searchTerm.length > 0) {
      const data = {}
    postApiData(
      `user/getSearchedProduct?name=${searchTerm}`,
      data,
      (resp)=>{
        console.log("search ke products",resp)
        setSearchResults(resp)
      },
      (error)=>{
        console.log("search ki error",error)
      }
    )
    }else{
      setSearchResults([])
    }
  },[searchTerm])

  const handleSearch = (event) => {
    const value = event.target.value
    if (value === '') {
      setSearchTerm('');
      navigate('/');
    } else {
      setSearchTerm(value);
      navigate(`/search${value ? `?${value}` : ''}`);
    }
  };

  console.log("navbar ke search results",searchResults)
  
  return (
    <div style={{ overflow: "hidden", backgroundColor: "black"}} className="sans-serif  ">
      
      {isSmallScreen && (
        <SmallNavbar />

      )}
      {!isSmallScreen && (
        <>
          <div className="navbar-container">
            <div className="firstlogo">
              <Link
                to={"/"}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <img className="logo" src={logo} alt="logo" />
              </Link>
            </div>

            <div className="search-container">
          <div>
              <input
                className="search-input px-5 placeholder:text-black"
                type="search"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search the products..."                
              />
              <div className="search-icon" >
                <img src={searchicon} alt="search icon" />
              </div>
              </div>
            </div>
            <Link
              to={"/Skinco"}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img className="skinco-logo" src={skinco} alt="logo" />
            </Link>
            <Link
              to={"/Argatin"}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img className="argatin-logo" src={argatin} alt="logo" />
            </Link>

            <div className="user-cart-container cursor-pointer">
              <img
                className="user-icon hover:scale-125 hover:duration-100 hover:font-bold"
                src={user}
                alt="user icon"
                onClick={openUserModal}
              />
              {/* <FaUserLarge className='text-3xl font-bold bg-white text-white' onClick={openUserModal}/> */}

              {/* {loginModal && (
                <Login modalIsOpen={loginModal} setIsOpen={setLoginModal} />
              )} */}
              {modalIsOpen && (
                <div
                  className={
                    authToken ? "dropdown-menu" : "dropdown-menulogout"
                  }
                >
                  <div className={"dropdown-submenu"}>
                    {!authToken && (
                      <>
                        <IoIosLogIn />
                        <div className="submenu-content">
                          <div
                            style={{ color: "blue",cursor:"pointer" }}
                            onClick={loginmodelpress()}
                          >
                            Login
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="dropdown-submenu" >
                    <FaCircleUser />
                    <div className="submenu-content" onClick={hanldeMyProfileButton}>
                      <h1>My Profile</h1>
                    </div>
                  </div>

                  {/* <div>
                    <Link to="/ordersummary" className="dropdown-submenu">
                      <FaBorderAll />
                      <div className="submenu-content" onClick={loginpress}>
                        <h1>My Orders</h1>
                      </div>
                    </Link>
                  </div> */}

                  {authToken && (
                    <div className="dropdown-submenu">
                      <IoIosLogIn />
                      <div className="submenu-content">
                        <h1 onClick={logout}>Logout</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* <div className="cart-container"> */}
              <div className="relative ">
              <p className="text-xs text-gray-300 bg-red-500 rounded-full p-1 absolute -top-2 -right-5 ">{cartLengthData.length}</p>
              <FaCartShopping className='text-3xl font-bold text-white' onClick={onpress}/>

              </div>
              {/* {showCart && (
                <Cart setShowCart={setShowCart} setTest={setTest} test={test} />
              )} */}
              {isCartOpen&& (
                <Cart setShowCart={setIsCartOpen} isCartOpen={isCartOpen} setTest={setTest} test={test} />
              )}
              {/* </div> */}
              <PiCirclesThreePlusBold
                className="menu-icon"
                onClick={toggleOption}
              />
            </div>
          </div>

          <div className=" productsubcategory-container">
            <Link
              to={{
                pathname: "/best-sellers",
              }}
              onClick={() => {
                if (modalIsOpen) {
                  setIsOpen(!modalIsOpen);
                }
              }}
              style={{ textDecoration: "none" }}
              className="m-1 inline-block cursor-pointer  text-white px-4 py-2 whitespace-nowrap text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out  hover:border-b-2 pb-0 "
            >
              Best Sellers
            </Link>

            <div
              className="m-1 inline-block cursor-pointer   text-white px-4 py-2 whitespace-nowrap text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out  hover:border-b-2 pb-0 text-decoration-line: none"
              // onMouseOver={handlemouseEnter}
              // onMouseOut={handlemouseLeave}
              onClick={handlemouseEnter}
            >
              Brands
            </div>
            {isbrand && (
              <div className="brand-dropdown"
               >
                <Link
              to="/loreal"
              className="brand-option"
              onClick={() => setBrand(false)}
              style={{textDecoration:"none"}}
            >
              Loreal
            </Link>
                <Link
                  to="/skinco"
                  className="brand-option"
                  onClick={() => setBrand(false)}
                  style={{ textDecoration: "none" }}
                >
                  Skinco
                </Link>
                <Link
                  to="/argatin"
                  className="brand-option"
                  onClick={() => setBrand(false)}
                  style={{ textDecoration: "none" }}
                >
                  Argatin
                </Link>
            <Link
              to="/spring"
              className="brand-option"
              onClick={() => setBrand(false)}
              style={{textDecoration:"none"}}
            >
              Spring H2O
            </Link>
              </div>
            )}

            

            <Link
              to={{
                pathname: "/hair-care",
              }}
              className="text-decoration-line: none m-1 inline-block  font-bold text-white px-4 py-2 whitespace-nowrap text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out hover:border-b hover:border-b-2 "
              onClick={() => {
                if (modalIsOpen) {
                  setIsOpen(!modalIsOpen);
                }
              }}
              style={{ textDecoration:"none"}}
            >
              Hair care
            </Link>

            <Link
              to={{
                pathname: "/skin-care",
              }}
              className="text-decoration-line: none m-1 inline-block  font-bold text-white px-4 py-2 whitespace-nowrap text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out hover:border-b hover:border-b-2 pb-0 "
              onClick={() => {
                if (modalIsOpen) {
                  setIsOpen(!modalIsOpen);
                }
              }}
              style={{ textDecoration: "none" }}
            >
              Skin care
            </Link>
            <Link
              to={{
                pathname: "/distributer",
              }}
              className=" remove-underline m-1 inline-block  font-bold text-white px-4 py-2 whitespace-nowrap text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out hover:border-b hover:border-b-2 pb-0 "
              onClick={() => {
                if (modalIsOpen) {
                  setIsOpen(!modalIsOpen);
                }
              }}
              style={{ textDecoration: "none" }}
            >
              Distribution
            </Link>
            <Link
              to={{
                pathname: "/ContactUs",
              }}
              className="m-1 inline-block  font-bold text-white px-4 py-2 whitespace-nowrap text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out hover:border-b hover:border-b-2 pb-0 text-decoration-line: none"
              onClick={() => {
                if (modalIsOpen) {
                  setIsOpen(!modalIsOpen);
                }
              }}
              style={{ textDecoration: "none" }}
            >
              Contact Us
            </Link>

            <Link
              to={{
                pathname: "/product-combo",
              }}
              className="m-1 inline-block  font-bold text-white px-4 py-2 whitespace-nowrap text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out hover:border-b hover:border-b-2 pb-0"
              onClick={() => {
                if (modalIsOpen) {
                  setIsOpen(!modalIsOpen);
                }
              }}
              style={{ textDecoration: "none" }}
            >
              Product combo
            </Link>
          </div>
        </>
      )}

      {test && <AddressModal addressmodal={test} setAddressModal={setTest} />}
      
    </div>
    
  );
};

export default Navbar;





// <>
//           <div className="smaller-container top-0 sticky z-10">
//             <GiHamburgerMenu
//               className="hamburger"
//               onClick={hamburgermenuopen}
//             />
//             {hamburgermenu && (
//               <div
//                 className={`hamburger-container ${
//                   hamburgermenu ? "" : "hidden"
//                 }`}
//               >
//                 <button className="close-button" onClick={hamburgermenuopen}>
//                   <IoClose />
//                 </button>
//                 <div className="text-container">
//                   <Link
//                     to={{
//                       pathname: "/best-sellers",
//                     }}
//                     className="nav-text"
//                     onClick={handleLinkClick}
//                   >
//                     Best Seller
//                   </Link>
//                   <div className="brands-container" onClick={brandddetails}>
//                     <label className="brandtext">Brands</label>
//                     <MdKeyboardArrowDown className="brandaicon" />
//                   </div>
//                   {branddetails && (
//                     <div className="optionsbrand">
//                       <h2>
//                         <Link
//                           to="/skinco"
//                           className="brand-link"
//                           onClick={handleLinkClick}
//                         >
//                           Skinco
//                         </Link>
//                       </h2>
//                       <h2>
//                         <Link
//                           to="/argatin"
//                           className="brand-link"
//                           onClick={handleLinkClick}
//                         >
//                           Argatin
//                         </Link>
//                       </h2>
//                       {/* <h2>
//                         <Link
//                           to="/spring"
//                           className="brand-link"
//                           onClick={handleLinkClick}
//                         >
//                           Spring H2O
//                         </Link>
//                       </h2> */}
//                       <h2><Link to="/loreal" className="brand-link"  onClick={handleLinkClick}>Loreal</Link></h2>
//                     </div>
//                   )}

//                   <Link
//                     to={{
//                       pathname: "/haircareallproducts",
//                     }}
//                     className="nav-text"
//                     onClick={handleLinkClick}
//                   >
//                     Haircare
//                   </Link>

//                   <Link
//                     to={{
//                       pathname: "/skincareallproducts",
//                     }}
//                     className="nav-text"
//                     onClick={handleLinkClick}
//                   >
//                     Skincare
//                   </Link>

//                   <Link
//                     to={{
//                       pathname: "/distributer",
//                     }}
//                     className="nav-text"
//                     onClick={handleLinkClick}
//                   >
//                     Distribution
//                   </Link>

//                   <Link
//                     to={{
//                       pathname: "/ContactUs",
//                     }}
//                     className="nav-text"
//                     onClick={handleLinkClick}
//                   >
//                     Contact Us
//                   </Link>

//                   <Link
//                     to={{
//                       pathname: "/Listcombo",
//                     }}
//                     className="nav-text"
//                     onClick={handleLinkClick}
//                   >
//                     Product combo
//                   </Link>
//                   {
//                     authToken && (

//                   <button className="nav-text" onClick={smalllogout}>Logout</button>
//                     )
//                   }
//                 </div>
//               </div>
//             )} 
//             <div className="small-firstlogo">
//               <Link
//                 to={"/"}
//                 onClick={() => {
//                   setIsOpen(false);
//                 }}
//               >
//                 <img className="small-logo cursor-pointer" src={logo} alt="logo" />
//               </Link>
//             </div>
//             <div className="small-user-cart-container">
//               {/* <img
//                 className="small-user-icon cursor-pointer"
//                 src={user}
//                 alt="user icon"
//                 onClick={openSmallUserModal}
//               /> */}
//               <SlUser onClick={openSmallUserModal} className="text-white text-xl cursor-pointer"/>

//               {loginModal && (
//                 <Login modalIsOpen={loginModal} setIsOpen={setLoginModal} />
//               )}
//               {/* {modalIsOpen && (
//                 <div
//                   className={
//                     authToken ? "dropdown-menu " : "dropdown-menulogout "
//                   }
//                 >
//                   <div className={"dropdown-submenu"}>
//                     {!authToken && (
//                       <>
//                         <IoIosLogIn/>
//                         <div className="submenu-content">
//                           <div
//                             style={{ color: "blue" }}
//                             onClick={loginmodelpress}
//                           >
//                             Login
//                           </div>
//                         </div>
//                       </>
//                     )}
//                   </div>

//                   <div className="dropdown-submenu">
//                     <FaCircleUser />
//                     <div className="submenu-content">
//                       <h1>My Profile</h1>
//                     </div>
//                   </div>

//                   <div>
//                     <Link to="/ordersummary" className="dropdown-submenu">
//                       <FaBorderAll />
//                       <div className="submenu-content" onClick={loginpress}>
//                         <h1>My Orders</h1>
//                       </div>
//                     </Link>
//                   </div>

//                   {authToken && (
//                     <div className="dropdown-submenu">
//                       <IoIosLogIn />
//                       <div className="submenu-content">
//                         <h1 onClick={logout}>Logout</h1>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               )} */}

//               {
//                 modalIsOpen && (
//                   <div className="w-[200px] h-auto bg-white rounded-lg shadow-xl fixed z-10 top-40 right-20">
//                   <div className="">
//                     {!authToken && (
//                       <button className="text-lg w-full px-3 py-2" onClick={loginmodelpress()}>
//                       Login
//                       </button>
//                     )}
//                   </div>

//                 <div className="" >
//               {
//                 authToken && (
//                   <div className="dropdown-submenu">
//                     <FaCircleUser />
//                     <div className="submenu-content" onClick={hanldeMyProfileButtons()}>
//                       <h1>My Profile</h1>
//                     </div>
//                   </div>
//                 )
//               }
                 
//                   </div>

//                   <div className="">
//                     <div className="dropdown-submenu">
//                       <FaBorderAll />
//                       <div className="submenu-content"  onClick={loginpress} >
//                         <h1>My Orders</h1>
//                       </div>
//                     </div>
//                   </div>

//                   {authToken && (
//                     <div className="dropdown-submenu"  >
//                       <IoIosLogIn />
//                       <div className="submenu-content" onClick={smalllogout}>
//                         <h1>Logout</h1>
//                       </div>
//                     </div>
//                   )}
//                 </div>
             
//                 )
//               }
//               {/* <div className="cart-container"> */}
//               <img
//                 className="small-kart-icon"
//                 src={kart}
//                 alt="kart icon"
//                 onClick={onpress}
//               />
//               {showCart && (
//                 <Cart setShowCart={setShowCart} setTest={setTest} test={test} />
//               )}
//               {/* {
//                 isCartOpen && (
//                   <Cart setShowCart={setIsCartOpen} isCartOpen={isCartOpen} setTest={setTest} test={test} cartTotal={cartTotal} setCartTotal={setCartTotal}/>
//                 )
//               } */}
//               {/* </div> */}
//               <PiCirclesThreePlusBold
//                 className="menu-icon"
//                 onClick={toggleOption}
//               />
//             </div>
//           </div>
//           <div className="small-search-container">
//             <input
//               className="small-search-input px-5"
//               type="search"
//               placeholder="Enter the product name ..."
//               onChange={handleSearch}
//             />
//             <div className="small-search-icon">
//               <img src={searchicon} alt="search icon" />
//             </div>
//           </div>
//         </>