import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import CustomCart from "../customcard";
import { getApiCall } from "../../utils/services";
import ProductCart from "../customcard/productCart";
import { IoIosArrowDropleft } from "react-icons/io";
import { IoIosArrowDropright } from "react-icons/io";
import PrevArrow from "../prevArrow";
import NextArrow from "../nextArrow";
import TestimonialPrevArrow from "../prevArrow/TestimonalPrev";
import TestimonialNextArrow from "../nextArrow/TestimonialNext";
import Message from "../../components/message";

function TestimonalSlider() {
   
  var settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: <TestimonialPrevArrow />,
    nextArrow: <TestimonialNextArrow />,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 0,
              infinite: true,
              dots: true
            }
          },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
     
      {
        breakpoint: 941,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="slider-container relative my-10 md:my-10 lg:my-6 mx-8 md:px-5 lg:px-10 lg:py-10  h-full " >
      <Slider {...settings}>
      
      {/* {
        data?.map((item,index)=>(
            <ProductCart key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)} productType={"combos"}/>
        ))
      } */}
      <Message name="Saanvi Tiwari" desc={"I'm amazed by how quickly this anti-dandruff shampoo cleared up my scalp. This shampoo cleared up my dandruff flakes and left my hair silky. It is a must-try for anyone battling dandruff. Has been my dandruff's worst enemy, and I'm loving it!"}/>
      <Message name="Aditi Tripathi" desc={"I have very sensitive, acne prone skin. I am on medication due to skin condition. I ordered this facewash for the first time. Writing this review after using it twice in a day for continuous a week. This is working for me like a miracle. All acne gone. Excessive oil production stopped. I loved it. Highly recommended"}/>
      <Message name="Riya Manral" desc={"I have been a regular user of this product. It really helped me with my pigmented underarms and also with the odor. I started seeing results just after 3-4 weeks, works gradually and you need to be consistent with its use. It’s Amazing!!! Worth every penny!!"}/>
      <Message name="Priyanka Mehra" desc={"This exfoliating body wash is the best! It doesn't dry out the skin after use and it really works on bumps or pimples on the skin, it also has small exfoliating beads which help in gentle exfoliation. The smell is great. Definitely worth a try! I'm already one bottle down and ordered few more."}/>
      <Message name="Chestha Bisht" desc={"This is one of the best body sunscreen I have used till date!  It absorbs real good into the skin and also it's really hydrating & leaves absolutely no white cast."}/>
      </Slider>
    </div>
  );
}

export default TestimonalSlider;
