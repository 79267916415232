import React from 'react'
import { faqData } from '../../utils/dummydata'
import './faq.css'

export default function Faq() {
  return (
    <div className="mt-24">
     {
        faqData.map((item,index)=>{
            return(
        <div className='faqContainer'>
            <p className='faqQusetion'>{item.question}</p>
            <p className='faqAns'>{item.ans}</p>

        </div>
     

            )
     
        })

     }
    </div>
  )
}
