// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";

// import Allproducts from "../Allproductlist/Allproductlist";
// import BestSeller from "../bestseller";
// import { postApiData } from "../utils/services";
// import Pricefilter from "../commonComponent/priceFilter/Pricefilter";
// import CustomCart from "../commonComponent/customcard";

// const Skincareallproducts = () => {
//   const [productformdetail, setProductformdetail] = useState([]);
//   const apidata = {
//     brand: "Skinconyc",
//   };
//   useEffect(() => {
//     postApiData("user/getAllProducts", apidata, (callBack) => {
//       setProductformdetail(callBack);
//       console.log("navbar", callBack);
//     });
//   }, []);
//   const location = useLocation();
//   const { productdata } = location.state || {};

//   console.log("dataneeds", productdata);
//   return (
//     <div>
//       <Pricefilter />
//       <div>
//         {/*<Allproducts productdata={productdata}/>*/}
//         {/* <BestSeller productdata={productformdetail} /> */}
//         <CustomCart productdata={filteredProducts} />
//       </div>
//     </div>
//   );
// };

// export default Skincareallproducts;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BestSeller from "../bestseller";
import Pricefilter from "../commonComponent/priceFilter/Pricefilter";
import { IoMdClose } from "react-icons/io";
import "../Haircareallproducts/haircareallproducts.css";
import { postApiData } from "../utils/services";
import { IoFilter } from "react-icons/io5";
import CustomCart from "../commonComponent/customcard";
import ProductCart from "../commonComponent/customcard/productCart";
import CustomProductTag from "../commonComponent/gtm";
import TestCard from "../commonComponent/customcard/testCart";
import { Helmet } from "react-helmet";

const Skincareallproducts = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [productformdetail, setProductformdetail] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [newPriceRange, setNewPriceRange] = useState([0, 3000]);
  const [showFilter, setShowFilter] = useState(false);
  const apidata = {
    brands: {Skinconyc:true},
  };

  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setProductformdetail(callBack);
      setFilteredProducts(callBack); // Initialize filteredProducts with all products
      console.log("navbar", callBack);
    });
  }, []);

  const handlePriceRangeChange = (newPriceRange) => {
    console.log(newPriceRange);
    setNewPriceRange(newPriceRange);
    const filtered = productformdetail.filter(
      (product) =>
        product.price >= newPriceRange[0] && product.price <= newPriceRange[1]
    );
    console.log(filtered);
    setFilteredProducts(filtered?.length > 0 ? filtered : productformdetail);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const closeFilter = () => {
    setShowFilter(false);
  };

  const containerClasses = `newprice-container ${showFilter ? "show" : "hide"}`;

  return (
    <div className="mt-24 ">
      <div>
        {/* <h1 className="mt-[30px] ml-[20px] mb-[3rem] text-3xl">
          Chemist At Play
        </h1> */}

        <Helmet>
          <title>
            Our Best Sellers: Buy Our Top-Selling Products You Can't Buy The
            Best Skincare Products Online for Healthy Skin at Pro salon cart
          </title>
          <meta name="description" content="best skincare products for healthy, radiant skin at Pro Salon Cart. Our online store offers a curated selection of premium skincare essentials designed to nourish, protect, and enhance your skin.Our best sellers collection features a curated selection of hair care and skincare."    />
          <link rel="canonical" href="https://prosaloncart.com/best-sellers" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />

          <meta
            property="og:description"
            content="Buy Hair Care Products Online in India. Argatin brings to You Goodness of Nature with Our Toxin Free Hair Products Such as Hair Oil, Mask, Shampoo, Conditioner."
          />
          <meta
            property="og:url"
            content="https://prosaloncart.com/hair-care"
          />
          <meta property="og:site_name" content="Hair care" />
          <meta
            keyword="keyword"
            content="buy skincare products online, best skincare products, healthy skin products, pro salon cart skincare
          "
          />
        </Helmet>
      </div>
      <div className="btn-class-container">
        {/* <Pricefilter

            newPriceRange={newPriceRange}
            onPriceRangeChange={handlePriceRangeChange}
          />  */}
        {window.innerWidth >= 350 && window.innerWidth < 1000 && (
          <div>
            <div className="btn-filter">
              <button onClick={toggleFilter}>
                {showFilter ? (
                  <div style={{ display: "flex" }}>
                    <IoFilter />
                    <span> Filter </span>
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <IoFilter />
                    <span> Filter </span>
                  </div>
                )}
              </button>
            </div>
            <div className={containerClasses}>
              <IoMdClose onClick={closeFilter} />
              {showFilter && (
                <Pricefilter
                  newPriceRange={newPriceRange}
                  onPriceRangeChange={handlePriceRangeChange}
                  productformdetail={productformdetail}
                />
              )}
            </div>
          </div>
        )}
        {window.innerWidth > 1000 && (
          <Pricefilter
            newPriceRange={newPriceRange}
            onPriceRangeChange={handlePriceRangeChange}
            productformdetail={productformdetail}
          />
        )}

        <div>
          {/* <BestSeller productdata={filteredProducts} /> */}
          <div className="flex flex-row gap-5 justify-evenly md:justify-start items-center flex-wrap md:mx-10 mb-10">
      {
        filteredProducts?.map((item,index)=>(
          <>
            <TestCard key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)} stockQuantity={item.stockQuantity} productType={"product"}/>
            <CustomProductTag product={item}/>
          </>
        ))
      }
      </div>
          {/* <CustomCart productdata={filteredProducts} productType={"product"} /> */}
        </div>
      </div>
    </div>
  );
};

export default Skincareallproducts;
