import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Login from '../../Register/Register';
import { postProductData } from '../../Redux/actions';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { CartContext } from '../../context/CartContext';

const ProductCart = ({item,name,img,desc,price,mrp,discount,stockQuantity,productType}) => {
    const { isCartOpen,setIsCartOpen} = useContext(CartContext);
    
    
    console.log("discount",discount);
    const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const token = useSelector((store) => store.authReducer.userData);
    const addToCart = (product) => {
          dispatch(postProductData(product
          ));  
          // setIsCartOpen(true)  
          setIsCartOpen(true)   
          toast.success("Product Added Successfully!!")
        
      };
      // className='mt-5 mx-auto w-[150px]   sm:w-[200px] sm:h-[450px] md:w-[350px] md:h-[550px] lg:w-[300px] lg:h-[480px] xl:w-[300px] xl:h-[480px] flex flex-col hover:scale-105 ease-in-out duration-200 border-2 border-black'
  return (
    <div className='mt-5 mx-auto h-[300px] md:h-[480px] w-[150px] md:w-[300px] flex flex-col hover:scale-105 ease-in-out duration-200 border-2 border-black'>
    <Link to={`/products/${item.shortUrl}`} state={{ data: item ,productType:productType}} className=' h-[55%]'>
    <img src={img} alt={name} className='w-full h-full cursor-pointer' style={{ filter: "brightness(95%)", backgroundColor: "#F9F1E0" }} />
    </Link>
    <div className='border-t-2  flex flex-col  h-full'>
    <div></div>
      <div className='flex justify-start gap-x-1 md:gap-x-5 items-center '>
        <p className='bg-red-700 text-white font-semibold text-xs md:text-lg px-2 md:px-3 py-1 '>{discount}%</p>
        <p className=' text-red-900 font-semibold text-[10px] md:text-sm px-2 py-1'>Limited Time Offer</p>
      </div>
      <div className='flex  justify-start items-center gap-x-1 md:gap-x-4'>
        <p className='text-xs md:text-lg font-semibold text-black '>₹{price}</p>
        <p className='text-xs md:text-sm text-black'>List Price: </p>
        <p className='text-xs md:text-lg line-through text-black font-semibold'>₹{mrp}</p>
      </div>
      <div className='flex my-2 h-[25%] overflow-hidden'>
        {/* <p className='text-xs md:text-lg text-black font-semibold '>{name?.length > 3 ? `${name.slice(0, 60)}...` : name}</p> */}
        <p className='text-xs md:text-lg text-black font-semibold '>{name}</p>
      </div>
      {
        stockQuantity === 0 ? (
      <button className='flex justify-center items-center text-white font-semibold text-md px-2 md:px-5 py-2 cursor-not-allowed bg-gray-700 hover:text-slate-100 duration-150 ease-in-out' >Out Of Stock</button>
        ) :
      <button className='flex justify-center items-center bg-black text-white font-semibold text-md px-5 py-2 cursor-pointer hover:bg-gray-800 hover:text-slate-100 duration-150 ease-in-out'  onClick={() => addToCart(item)}>Add to cart</button>   
      }
    </div>

    {loginModal && (
                // Render the Login component if loginModal is true
                <Login modalIsOpen={loginModal} setIsOpen={setLoginModal} />
              )}

              {/* {isModalOpen && (
                <div className="notification">
                  {/* <p>Product added to cart successfully!</p> */}

                  {/* <button
                    className="close-cart-button"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              )} */} 
    </div>
  )
}

export default ProductCart