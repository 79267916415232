
const Initalstate = {
    cart: [],
    cartTotalAmount: 0,
    userData: '',
    paymentData: [],
    products: [],
    filterData: [],
   quantity:0,
   islogin:false,
   cartLength:[],
   cartpayableAmount:0
}
const addProduct = (state = Initalstate, action) => {
    const { type, payload } = action
    switch (type) {
        case 'addproductTocart':
            // const itemIndex = state.cart.findIndex(item => item._id === payload._id);
            const itemIndex = state.cart.findIndex(item => item._id === payload.productData._id);
            // console.log('itemINdex',itemIndex,payload.productData._id)
            
            if (itemIndex !== -1) {
              // If item already exists in the cart, increase its quantity
              return {
                ...state,
                cart: state.cart.map((item, index) => {
                  if (index === itemIndex) {
                    return { ...item, quantity: item.quantity + 1 };
                  }
                  return item;
                })
              };
            } else {
              // If item doesn't exist in the cart, add it
              return {
                ...state,
                cart: [...state.cart, { ...payload.productData, quantity: 1 }]
              };
            }
            case 'DecreaseQuantity' :
                console.log('itempayload',payload.productData._id)
                const itemIndexdata = state?.cart?.findIndex(item => item?._id === payload?.productData?._id);
               
             
                if (itemIndexdata !== -1) {
                  // If item already exists in the cart, increase its quantity
                  return {
                    ...state,
                    cart: state.cart.map((item, index) => {
                      if (index === itemIndexdata) {
                        return { ...item, quantity: item.quantity -1};
                      }
                      return item;
                    })
                  };
                } else {
                  // If item doesn't exist in the cart, add it
                  return {
                    ...state,
                    cart: [...state.cart, { ...payload.productData, quantity: 1 }]
                  };
                }
                
          
            
          
        case 'RemoveItem':
            console.log({...state, cart: state.cart.filter((item) => item._id !== payload)})
            return {
                ...state, cart: state.cart.filter((item) => item._id !== payload)
            }
        case 'successorder':
            console.log({...state, cart: state.cart.filter((item) => item._id !== payload)})
            return {
                ...state, cart: []
            }
        default: return state;
    }

}
const authReducer = (state = Initalstate, action) => {
    const { type, payload } = action
    console.log("athflow", payload)
    switch (type) {
        case 'SET_USERDETAILS':
            return {
                 userData: payload
            }
         
            case 'logoutDetails':
                return{
                 ...state,
                userData: '',
                cartLength: []
                }
        default: return state;
    }
}
const paymentTotalAmount = (state, action) => {
    const { type, payload } = action
    // console.log("payloaddata", payload)
    switch (type) {
        case 'payment_TotalAmount':
            return {
                ...state, paymentData: payload

            }
        default: return state;
    }

}
 const cartDetailsReducer = (state = Initalstate, action) => {
    const { type, payload } = action
    console.log("Cart_Details",action)
    switch (type) {
        case 'Cart_Details':
                return{
                  ...state,cartLength:payload
                }
                case 'RemoveItem':
                    return {
                        ...state, cartLength: state.cartLength.filter((item) => item._id !== payload)
                    }
                    // case 'logoutDetails':
                    //     return{
                    //      ...state,
                    //     userData: '',
                    //     cartLength: []
                    //     }
        default: return state;
    }
}







export { addProduct,  authReducer, paymentTotalAmount  ,cartDetailsReducer}