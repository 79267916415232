import React from 'react'
import ProductCart from '../customcard/productCart'
import CustomProductTag from '../gtm'
import TestCard from '../customcard/testCart'

const CartDisplayer = ({productdata,productType}) => {
return (
    <div className='flex justify-evenly md:justify-between items-center flex-wrap gap-5 mt-10 mx-5'>
    {
        productdata?.map((item,index)=>(
          <>
            <TestCard key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)} stockQuantity={item.stockQuantity} productType={productType}/>
            <CustomProductTag product={item}/>
          </>
        ))
      }
    </div>
  )
}

export default CartDisplayer