import React, { useEffect } from 'react'
import './addNewAddress.css'
import Modal from "react-modal";
import { useState } from 'react';
import { getApiCall, postApiData } from '../../utils/services';
import { LOCAL_IMAGES } from '../../utils/localimages';
import axios from 'axios';


export default function AddNewAddress({ setAddNewAddress, addNewAddress }) {
  const [allAddress,setAllAddress] = useState([]);
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [countrystate, setCountrystate] = useState("");
  const [pincode, setPincode] = useState(null);
  // const [name,setName]=useState('')
  // const { state } = useLocation();
  const [selectedOption, setSelectedOption] = useState('');
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [bool,setBool] = useState(false)
  const customStyles = {
    content: {
      top: "55%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "whitesmoke",
      position: "absolute",
      zIndex: 10000,
      borderRadius: "20px",
      paddingRight: "0px",
      width: "500px"

    },
  };
  const addNewAddressModalclick = () => {

    var combineAddress = {
      address: address,
      address2: address1,
      city: city,
      pincode: pincode,
      state: countrystate,
      isActive: true,

    }
    if (address && address1 && city && pincode && countrystate) {
      postApiData("user/addAddressToUser", combineAddress, (response) => {
        alert("address Added SucessFully")
        setAddNewAddress(false)

      },
      )

    }
    // setAddNewAddress(false)
    setCity("")
    setCountrystate("")
    setPincode(null)
    setAddress("")
    setAddress1("")
  }
  const backpress = () => {
    setAddNewAddress(false)
  }
  function closeModal() {
    setAddNewAddress(false);
  }
  // useEffect(async()=>{
  //   const pins = pincode.toString();
  //   console.log("pinsss",pins.length)
  //   if(pins.length ===6){
  //     const data = await axios.get(`https://api.postalpincode.in/pincode/263656`);
  //     console.log("axios ka data",data)
  //   }
  // },[pincode])
  useEffect(() => {
    const fetchData = async () => {
      const pins = pincode?.toString();
      console.log("pinsss", pins?.length);
      if (pins?.length === 6) {
        try {
          const response = await axios.get(`https://api.postalpincode.in/pincode/${pins}`);
          console.log("axios ka data", response.data);
          console.log('post office', response.data[0].PostOffice[0].Name)
          setCountrystate(response.data[0].PostOffice[0].State)
          setCity(response.data[0].PostOffice[0].Block)

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
  
    fetchData();
  
    // Return a cleanup function (if needed)
    return () => {
      // Cleanup code here (if needed)
    };
  }, [pincode]);

  const handlePinCode = (e)=>{
    setPincode(e.target.value)
  }


  return (
    <Modal
      isOpen={addNewAddress}
      onRequestClose={closeModal}
      // style={customStyles}
      className='w-full md:w-1/3 mx-auto bg-white mt-32 px-5 py-4 border border-black rounded-lg shadow-xl h-[600px] overflow-y-auto'
      contentLabel="Example Modal"
    >
      <div style={{ backgroundColor: '', paddingRight: '20px', }}>
        <div className="salonkartcontianer">
          <img onClick={backpress}
            src={LOCAL_IMAGES.leftArrow} style={{ height: '30px', width: "30px" }} className='cursor-pointer'/>
          <span className="salonkart text-center">{'SalonKart'}</span>
          {/* <span className="totalprice">{'448'}</span> */}
        </div>
        <p className="freeshipping">{'Free shipping on orders above 449'}</p>
        <form>


          <label
            htmlFor="labeled-input"
            style={{ display: "block", marginBottom: "8px",  }}
          >
            Enter Address:
          </label>
          <input
            className="textinputStyle"
            type="text"
            placeholder="Enter your address"
            style={
              {
                // paddingRight: '60px' // Adjust the padding to ensure the text doesn't go under the <p> tag
              }
            }
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <label
            htmlFor="labeled-input"
            style={{ display: "block", marginBottom: "8px" }}
          >
            Enter Address2:
          </label>
          <input
            className="textinputStyle"
            type="text"
            placeholder="address1"
            style={
              {
                // paddingRight: '60px' // Adjust the padding to ensure the text doesn't go under the <p> tag
              }
            }
            value={address1}
            onChange={(e) => setAddress1(e.target.value)}
          />
          <label
            htmlFor="labeled-input"
            style={{ display: "block", marginBottom: "8px" }}
          >
            Enter Pincode:
          </label>

          <input
            className="textinputStyle"
            type="text"
            placeholder="Enter Pincode"
            style={
              {
                // paddingRight: '60px' // Adjust the padding to ensure the text doesn't go under the <p> tag
              }
            }
            value={pincode}
            // onChange={(e) => setPincode(e.target.value)}
            onChange={handlePinCode}
          />
          <label
            htmlFor="labeled-input"
            style={{ display: "block", marginBottom: "8px" }}
          >
            Enter City:
          </label>
          <input
            className="textinputStyle"
            type="text"
            placeholder="Enter City"
            style={
              {
                // paddingRight: '60px' // Adjust the padding to ensure the text doesn't go under the <p> tag
              }
            }
            value={city}
            // onChange={(e) => setCity(e.target.value)}
            disabled={true}
          />
          <label
            htmlFor="labeled-input"
            style={{ display: "block", marginBottom: "8px" }}
          >
            Enter State:
          </label>

          <input
            className="textinputStyle"
            type="text"
            placeholder="Enter State"
            style={
              {
                // paddingRight: '60px' // Adjust the padding to ensure the text doesn't go under the <p> tag
              }
            }
            value={countrystate}
            // onChange={(e) => setCountrystate(e.target.value)}
            disabled={true}
          />
          

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
            {/* <button onClick={addnewAddress} className="buttonstyle">
       Add Address
      </button> */}
          </div>

        </form>


        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
          <button onClick={addNewAddressModalclick} className="buttonstyle">
            Add Address
          </button>
        </div>

      </div>
    </Modal>
  )
}
