import React, { useEffect } from "react";
import BrandSlider from "../Brandslider/Brandslider";
import Productlist from "../Productlist/Productlist";
import Footer from "../components/footer";
import MainHeading from "../commonComponent/dividerline";
import "./home.css";
import { LOCAL_IMAGES } from "../utils/localimages";
import LoveusSlider from "../components/loveusslider";
import SellingBrandSlider from "../commonComponent/sellingBrandSlider";
import ReactPixel from 'react-facebook-pixel';
import {
  brandImg,
  brandsImg,
  safeAndScienctific,
  sellingPlateformData,
} from "../utils/dummydata";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import setAuthorizationToken from "../utils/commonFunction";
import BrandContainer from "../Middlesection/Middlesection";
import Skincategory from "../commonComponent/skincategory";
import { photoData } from "../utils/dummydata";
import { hairData } from "../utils/dummydata";
import Listcombo from "../productcombo";
import { getApiCall, postApiData } from "../utils/services";
import { useState } from "react";
import BestSeller from "../bestseller";
import CustomCart from "../commonComponent/customcard";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ResponsiveSlider from "../commonComponent/slider";
import { totalProduct } from "../Redux/actions";
import Testimonial from "../components/testimonial";
import ProductCart from "../commonComponent/customcard/productCart";
import axios from "axios";
import CustomProductTag from "../commonComponent/gtm";
import CommonSlider from "../commonComponent/commonSlider";
import Progress from "../commonComponent/progress";

import BasicTabs from "../commonComponent/Tabs";
import TestCard from "../commonComponent/customcard/testCart";


const Home = () => {
  const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
  const [productlistData, setproductListData] = useState([]);
  const [favouriteProductlistData, setFavouriteProductListData] = useState([]);
  const [checkedBrands, setCheckedBrands] = useState({
    Loreal: false,
    // Skinconyc: false,
    Argatin: false,
    // SpringH2O: false,
  });
  const dispatch = useDispatch();

  const TRACKING_ID = "G-56Q0JTSZKG";
  ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const token = useSelector((store) => store.authReducer.userData);
  useEffect(() => {
    if (token) {
      setAuthorizationToken(token);
    }
  }, []);

  useEffect(() => {
    // Initialize Facebook Pixel
    ReactPixel.init('1166284498035863');

    // Track page view event
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    const data = {
      brands: checkedBrands,
    };
    postApiData(
      "user/getComboProducts",
      data,
      (resp) => {
        console.log(resp);
        let getcomboList = resp.splice(0, 8);
        setproductListData(getcomboList);
        // setFilteredProducts(resp);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }, [checkedBrands]);
  //const imageUrl = 'https://res.cloudinary.com/dkvmvyvnx/image/upload/v1703921377/app_banner_k9bd37.png';
  const apidata = {
    brand: "",
  };
  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      let selectedProducts = callBack.slice(8, 16);
      setFavouriteProductListData(selectedProducts);
      console.log("fgetAllProductsst", callBack);
      // dispatch(totalProduct(callBack));
    });
  }, []);

  useEffect(() => {
    const data = {};
    axios
      .post("https://prosaloncart.com/user/getCartDetails", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(
          "Responsedatadsfhsahhfsdsdg:",
          response.data.data[0].products
        );
        dispatch(totalProduct(response.data.data[0]?.products));
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error
      });
  }, []);
  console.log("favorate list", favouriteProductlistData);
  console.log("product list data ", productlistData);
  return (
    <div className=" mt-24">
      <Helmet>
        <title>
          Buy Hair Care & Skin Care Products Online in India - Argatin Keratin &
          SkinCo.
        </title>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16512616878"></script>
          <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16512616878');
          `}
          </script>

        <meta
          name="description"
          content="Buy Hair Care Products Online in India. Argatin brings to You Goodness of Nature with Our Toxin Free Hair Products Such as Hair Oil, Mask, Shampoo, Conditioner."
        />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href="https://prosaloncart.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Buy Hair Care Products Online in India - Argatin Keratin"
        />
        <meta
          property="og:description"
          content="Buy Hair Care Products Online in India. Argatin brings to You Goodness of Nature with Our Toxin Free Hair Products Such as Hair Oil, Mask, Shampoo, Conditioner."
        />
        <meta property="og:url" content="https://prosaloncart.com/" />
        <meta property="og:site_name" content="Argatin Keratin" />
        <meta property="og:updated_time" content="2023-05-02T06:55:45+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Buy Hair Care & Skin Care Products Online in India - Argatin Keratin & SkinCo."
        />
        <meta
          name="twitter:description"
          content="Buy Hair Care & Skin Care Products Online in India. Argatin,Skin.Co brings to You Goodness of Nature with Our Toxin Free Hair Products Such as Hair Oil, Mask, Shampoo, Conditioner."
        />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content="Argatin" />
        <meta name="twitter:label2" content="Time to read" />
        <meta name="twitter:data2" content="Less than a minute" />
      </Helmet>

      <BrandSlider />

      {/* <Productlist /> */}
      <div className="mx-auto w-full bg-white mt-20 md:mt-32 lg:mt-10">
        {/* <MainHeading headingText={"COMBOS"} /> */}
        <div className="flex justify-start items-center mt-10">
          {/* <h1 className="text-2xl font-bold text-black ml-6">
            SHOP FAVOURITES
          </h1> */}
          {/* <Link to={"/Listcombo"}><p className="mt-3 ml-4 text-gray-500 hover:border-b-2 hover:border-gray-500">View All</p></Link> */}
        </div>
        {/* <div className="hidden md:flex">
        <ResponsiveSlider data ={favouriteProductlistData}/>
        </div> */}
         {/* <div className="flex flex-row gap-5 justify-evenly md:justify-between items-center flex-wrap md:mx-10">
          {favouriteProductlistData?.map((item, index) => (
            <> 
                <TestCard
                key={index}
                item={item}
                img={item.images[0]}
                name={item.name}
                desc={item.description}
                price={item.price}
                mrp={item.mrp}
                discount={Math.ceil(((item.mrp - item.price) / item.mrp) * 100)}
                stockQuantity={item.stockQuantity}
                productType={"combos"}
              />
              <CustomProductTag product={item} />
            </>
          ))}
        </div>  */}
        <BasicTabs />
        {/* <CustomCart productdata={productlistData} productType={"combos"} /> */}
      </div>
      <div
        style={{
          height: "300px",
          backgroundColor: "#000000",
          marginTop: "20px",
        }}
      >
        <h2
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "30px",
            paddingBottom: "30px",
            fontSize: "25px",
            fontWeight: "500",
          }}
        >
          Safe and Scientific
        </h2>

        <div className="safeImgContainer">
          {safeAndScienctific?.map((item, index) => {
            return (
              <div className="textsafecontianer">
                <img src={item.image} alt="icon" className="iconimg " />
                <p className="description text-green-600">{item.description}</p>
              </div>
            );
          })}
        </div>
      </div>

      <MainHeading headingText={"SHOP BY CONCERN"} />
      <div className="w-full bg-white">
      <div className="bg-black w-1/4 text-white text-xl md:text-3xl font-bold px-14 md:px-10 py-1 md:py-3 my-2">
            Skin
        </div>
        <Skincategory skin={photoData} />
      </div>

      <div className="w-full bg-white mt-10">
        <div className="bg-black w-1/4 text-white text-xl md:text-3xl font-bold px-14 md:px-10 py-1 md:py-3 my-2">
            Hair
        </div>
        <Skincategory skin={hairData} />
      </div>
      <div className="mx-auto w-[95%] bg-white mt-20 md:mt-14 lg:mt-10">
        {/* <MainHeading headingText={"COMBOS"} /> */}
        <div className="flex justify-start items-center mt-10">
          <h1 className="text-2xl font-bold text-black ml-6">BEST COMBOS</h1>
          <Link to={"/product-combo"}>
            <p className="mt-3 ml-4 text-gray-500 hover:border-b-2 hover:border-gray-500">
              View All
            </p>
          </Link>
        </div>
        {/* <div className="flex flex-row gap-5 justify-between items-center flex-wrap md:mx-10">
          {productlistData?.map((item, index) => (
            <>
                <TestCard
                key={index}
                item={item}
                img={item.images[0]}
                name={item.name}
                desc={item.description}
                price={item.price}
                mrp={item.mrp}
                discount={Math.ceil(((item.mrp - item.price) / item.mrp) * 100)}
                stockQuantity={item.stockQuantity}
                productType={"combos"}
              />
              
              <CustomProductTag product={item} />
            </>
          ))}
        </div> */}
        <div className=" mx-auto my-5">
        <ResponsiveSlider data ={productlistData}/>
        </div>
      </div>

      <div className="reasonTolove" style={{ marginBottom: "20px",marginTop:"40px" }}>
        <MainHeading headingText={"OUR BRANDS"} />
      </div>
      <div className="my-5">
        <CommonSlider brand="brands" />
      </div>
        <Testimonial />

        <MainHeading headingText={"REASON TO LOVE US "} />
     
      <div>
        <LoveusSlider />
      </div>
      <a href="https://play.google.com/store/apps/details?id=com.smartsalon" target="_blank" rel="noopener noreferrer">
        <img
          src={LOCAL_IMAGES.app_banner}
          alt="phoneimg"
          className="w-full shadow-xl h-auto my-5 rounded-lg"
        />
      </a>
      <div className="NowAvailable">
        <MainHeading headingText={"NOW AVAILABLE ON "} />
      </div>
      <div>
        <SellingBrandSlider brandimg={sellingPlateformData} />
      </div>
    </div>
  );
};

export default Home;
