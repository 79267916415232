import React, { useEffect, useState } from "react";
import { brandimg } from "../../utils/dummydata";

import MainHeading from "../../commonComponent/dividerline";
import "./skinco.css";
import BestSeller from "../../bestseller";
import { postApiData } from "../../utils/services";
import SellingBrandSlider from "../../commonComponent/sellingBrandSlider";
import { sellingPlateformData } from "../../utils/dummydata";
import Footer from "../footer";
import { LOCAL_IMAGES } from "../../utils/localimages";
import { Link } from "react-router-dom";
import { argatinbannerdata } from "../../utils/dummydata";
import { useSelector } from "react-redux";
import setAuthorizationToken from "../../utils/commonFunction";
import CustomCart from "../../commonComponent/customcard";
import CommonSlider from "../../commonComponent/commonSlider";
import { Helmet } from "react-helmet";
import ProductCart from "../../commonComponent/customcard/productCart";
import CustomProductTag from "../../commonComponent/gtm";
import TestCard from "../../commonComponent/customcard/testCart";
import SliderCarts from "../../commonComponent/customcard/sliderCart";
//import BrandSlider from '../../Brandslider/Brandslider'

export default function Skinco() {
  const [facialkit, setFacialKit] = useState([]);
  console.log("facialkit", facialkit);
  const [menicure, setMenicure] = useState([]);

  const token = useSelector((store) => store.authReducer.userData);
  useEffect(() => {
    if (token) {
      setAuthorizationToken(token);
    }
  }, []);
  const apidata = {
    brands: {Skinconyc:true},
  };
  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      console.log("callbackgetproduct", callBack);

      const x = callBack.filter((item) => item.subCategory === "Facial Kit");
      console.log("xdata", x);
      setFacialKit(x);

      const y = callBack.filter(
        (item) => item.subCategory === "Menicure and Pedicure"
      );
      setMenicure(y);
    });
  }, []);

  return (
    <div className="mt-24 ">
      <Helmet>
        <title>Buy Skin Care Products Online in India -SkinCo.</title>
        <meta
          name="description"
          content="Experience the pure goodness of nature with Skinco's toxin-free skin care products. Our range includes a luxurious Facial Kit for radiant skin, Menicare for tailored male grooming, and Pedicare for pampered feet. Embrace the power of natural ingredients for a healthier, glowing complexion. Buy online in India and treat your skin to the care"
        />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href="https://prosaloncart.com/Skinco" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16512616878"></script>
          <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16512616878');
          `}
          </script>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Buy Skin Care Products Online in India -SkinCo"
        />
        <meta
          property="og:description"
          content="Experience the pure goodness of nature with Skinco's toxin-free skin care products. Our range includes a luxurious Facial Kit for radiant skin, Menicare for tailored male grooming, and Pedicare for pampered feet. Embrace the power of natural ingredients for a healthier, glowing complexion. Buy online in India and treat your skin to the care"
        />
        <meta property="og:url" content="https://prosaloncart.com/Skinco" />
        <meta property="og:site_name" content="Skinco" />
        <meta property="og:updated_time" content="2023-05-02T06:55:45+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="skinconContainer">
        <CommonSlider brand="skinco" />
      </div>
      <div className="brandbanner ">
        <Link
        // to='/FacialKit' state={{ facilakit: facialkit }}
        className="image-link"
        >
          <img
            src={LOCAL_IMAGES.facialkit}
            alt="argetinkaratine"
            className="argatin2img"
          />
        </Link>
        <Link
        // to='/Menicure' state={{ facilakit: facialkit }}
        className="image-link"
        >
          <img src={LOCAL_IMAGES.medicare} alt="skinco" />
        </Link>
      </div>

      <div className="facialkitContainer">
        <MainHeading headingText={"Facial Kits"} />
      </div>
      {/* <BestSeller productdata={facialkit} /> */}
      {/* <CustomCart productdata={facialkit} productType={"product"} /> */}
      <div className="flex flex-row gap-5 justify-evenly items-center flex-wrap md:mx-5">
      {
        facialkit?.map((item,index)=>(
          <>
            <SliderCarts key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)}  stockQuantity={item.stockQuantity} productType={"product"}/>
            <CustomProductTag product={item}/>
          </>
        ))
      }
      </div>
      <div className="facialkitContainer">
        <MainHeading headingText={"Manicure Pedicure Kits"} />
      </div>
      {/* <CustomCart productdata={menicure} productType={"product"} /> */}
      <div className="flex flex-row gap-5 justify-evenly items-center flex-wrap md:mx-5">
      {
        menicure?.map((item,index)=>(
          <>
            <SliderCarts key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)} stockQuantity={item.stockQuantity} productType={"product"}/>
            <CustomProductTag product={item}/>
          </>
        ))
      }
      </div>

      <div className="facialkitContainer">
        <MainHeading headingText={"Now Available on"} />
      </div>
      <div style={{ backgroundColor: "", padding: "30px" }}>
        <SellingBrandSlider brandimg={sellingPlateformData} />
      </div>
      <div style={{ backgroundColor: "green" }}>{/* <Footer/> */}</div>
    </div>
  );
}
