import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import CustomCart from "../customcard";
import { getApiCall } from "../../utils/services";
import ProductCart from "../customcard/productCart";
import { IoIosArrowDropleft } from "react-icons/io";
import { IoIosArrowDropright } from "react-icons/io";
import PrevArrow from "../prevArrow";
import NextArrow from "../nextArrow";
import TestimonialPrevArrow from "../prevArrow/TestimonalPrev";
import TestimonialNextArrow from "../nextArrow/TestimonialNext";
import Message from "../../components/message";
import ProductPrevArrow from "../prevArrow/ProductPrev";
import ProductNextArrow from "../nextArrow/ProductNext";

function ProductSlider({data}) {
   
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,     
    prevArrow: <ProductPrevArrow />,
    nextArrow: <ProductNextArrow />,
    
  };
  return (
    <div className="slider-container relative my-10 md:my-10 lg:my-6 mx-8 md:px-5 lg:px-10 lg:py-10  h-full " >
      <Slider {...settings}>

      {
        data?.map((media,index)=>{
            const isImage = media.includes(".jpg") || media.includes(".png") || media.includes(".jpeg") || media.includes(".webp") || media.includes(".JPG");
            return(
                isImage ? (
            <img src={media} className="w-full h-[280px] "/>
                )  : (
                    <video
          className={`w-full h-[280px]`}
          controls
          autoPlay
          muted
        >
          <source src={media} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
                )           
            )
      })
      }
      </Slider>
    </div>
  );
}

export default ProductSlider;
