
import { combineReducers } from 'redux';
import { addProduct } from '../reducers';
import { authReducer,cartDetailsReducer } from '../reducers';
import { paymentTotalAmount } from '../reducers';

const rootReducer=combineReducers({
   addProduct,
   authReducer,
   cartDetailsReducer

   // paymentTotalAmount   
})
export default rootReducer;