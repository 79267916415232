import React from "react";
import Slider from "react-slick";
import PrevArrow from "../prevArrow";
import NextArrow from "../nextArrow";
import TestCard from "../customcard/testCart";
import SliderCarts from '../customcard/sliderCart'
function ResponsiveSlider({data}) {
  
   
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
           
            }
          },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,

        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ]
  };
  return (
    <div className="mx-auto" >
      <Slider {...settings} className="mx-auto w-full">
      {
        data?.map((item,index)=>(
            <SliderCarts key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)} stockQuantity={item.stockQuantity}/>
        ))
      }
      </Slider>
    </div>
  );
}

export default ResponsiveSlider;
