import React, { useEffect, useState } from 'react'
import { getApiCall, postApiData, setAuthorizationToken } from '../../utils/services';
import Popup from '../addressPopup';
import { useSelector } from 'react-redux';
import { authReducer } from '../../Redux/reducers';
import axios from 'axios';
import AddNewAddress from '../../commonComponent/addnewAddress';

const Address = () => {
  console.log("khafjjkdhfkjahsdjkfhdasjhfsdahjhfsj")
  const [filledAddress, setFilledAddress] = useState([]);
  const [isVisible,setIsVisible] = useState(false)
  const [addNewAddressModal, setAddNewAddress] = useState(false);
  const [editId,setEditId] = useState("");
  const [deleteId,setDeleteId] = useState("")
  const [bool,setBool] = useState(false)
  const token=useSelector((store)=>store.authReducer.userData)

  // useEffect(()=>{
  //   setAuthorizationToken(token)
  // },[])
  // useEffect(() => {
  //   getApiCall("user/getUserAddress", (resp) => {
  //     console.log("user/getUserAddress", resp);
  //     setFilledAddress(resp.addresses);
  //   });
  // }, [deleteId]);

  useEffect(() => {
    axios.get('https://prosaloncart.com/user/getUserAddress', {
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
  .then(response => {
    console.log('Responsedatadsfhsahhfsd:', response.data.data);
    setFilledAddress(response.data.data.addresses);
    // Handle response
  })
  .catch(error => {
    console.error('Error:', error);
    // Handle error
  });
  }, [deleteId,bool,addNewAddressModal]);



  // console.log("addresss ye h",filledAddress);

  const handleDelete=(id)=>{
    console.log("delete id", id)
    const data = {
      id:id
    }

    axios.post(`https://prosaloncart.com/user/deleteAddressOfUser`, 
    data,
    {
    headers: {
    Authorization: `Bearer ${token}`,
  },
})
  .then(response => {
    console.log('Responsedatadsfhsahhfsd:', response.data.data);
    // Handle response
    setDeleteId(id);
  })
  .catch(error => {
    console.error('Error:', error);
    // Handle error
  });
  }
  const handleEdit=(id)=>{
    console.log("edit id",id)
    setIsVisible(true)
    setEditId(id)
  }
  const onClose=()=>{
    setIsVisible(false)
  }
  return (
    <div className='mt-24 w-[90%] mx-auto '>
        <h1 className='text-2xl font-bold text-center my-4'>YOUR ADDRESSES</h1>
        <div className='flex justify-center'>
      <button className='px-3 py-2 bg-black text-white font-semibold rounded-lg my-3 ' onClick={()=>setAddNewAddress(!addNewAddressModal)}>Add New Address</button>
        </div>
        {/* <h1 style={{marginTop:'50px'}}>afdhskfasdfkfsdafasdfd</h1> */}

        <div className='flex flex-col w-[90%] md:w-1/2 mx-auto  my-5 h-full'>
        {
            filledAddress?.map((item,index)=>(
              <div key={index} className='w-full border-2 border-gray-100 rounded-lg shadow-xl flex flex-col my-5 px-5 py-2'>
                <div className='flex flex-col items-center justify-center'>
                  <p className='text-start'>Address 1: <span className='text-green-600 '>{item?.address}</span></p>
                  <p className='text-start'>Address 2: <span className='text-green-600 '>{item?.address2}</span></p>
                  <p className='text-start'>City: <span className='text-green-600 '>{item?.city}</span></p>
                  <p className='text-start'>PinCode: <span className='text-green-600 '>{item?.pincode}</span></p>
                  <p className='text-start'>State: <span className='text-green-600 '>{item?.state}</span></p>
                </div>
                  <button className='bg-red-500 hover:bg-red-700 duration-150 ease-in-out rounded-lg my-2 text-lg text-white font-bold py-2 cursor-pointer' onClick={()=>handleDelete(item._id)}>Delete</button>
                  <button className='bg-blue-500 hover:bg-blue-700 duration-150 ease-in-out rounded-lg text-lg text-white font-bold py-2 cursor-pointer' onClick={()=>handleEdit(item._id)}>Edit</button>
              </div>
            ))
        }    
        </div> 
        <Popup isVisible={isVisible} onClose={onClose} id={editId} bool={bool} setBool={setBool}/>
      {
          <AddNewAddress
            addNewAddress={addNewAddressModal}
            setAddNewAddress={setAddNewAddress}
          />
      } 
    </div>
  )
}

export default Address