import "./App.css";
import Home from "./Auth/auth";
// import store from './Redux/store/store';
import { Routes, Route, useLocation } from "react-router-dom";
import Skinco from "./components/skinco";
import Navbar from "./Navbar/Navbar";
import Cartdescription from "./Cartdescription/Cartdescription";
import Haircareallproducts from "./Haircareallproducts/Haircareallproducts";
import Skincareallproducts from "./Skincareallproducts/Skincareallproducts";
import Argatin from "./components/argatin";
import SignUp from "./Login/Login";
import ResetPassword from "./components/resetpassword";
import Menicure from "./components/menicure";
import FacialKit from "./components/facialkit";
import Profile from "./components/profile";
import Faq from "./components/faq";
import PrivacyPolicyComponent from "./components/privacyPolicy";
import TermsAndCondition from "./components/termsAndcondition";
import ReturnRefund from "./components/refundAndReturn";
import AboutUs from "./components/aboutus";
import Distributer from "./components/distributerpage";

//import LoginForm from "./Loginform/Loginform";
import Productformdetails from "./Productformdetails/Productformdetails";
import Editform from "./Editform/Editform";
import Argatinshampoo from "./Argatinshampoo/Argatinshampoo";
import Hairconditioner from "./Hairconditioner/Hairconditioner";
import Hairoil from "./Hairoil/Hairoil";
import Argatinkeratintreatment from "./Argatinkeratintreatment/Argatinkeratintreatment";
import Listcombo from "./productcombo";
import Productupdateform from "./productupdateform/Productupdateform";
import Formupdate from "./formupdate/formUpdate";
import ContactUs from "./components/contactus";
import ReactGA from "react-ga";
import { useEffect } from "react";
import Bestsellerlist from "./Bestsellerlist/Bestsellerlist";
import Loreal from "./components/lorealbrand";
import Lorealallproducts from "./lorealAllproducts";
import Btxhaircare from "./Btxhaircare/Btxhaircare";
import Spring from "./components/springh2o";
import { Toaster } from "react-hot-toast";
import { CartProvider } from "./context/CartContext";
import YourOrders from "./Ordersummary";
import SearchResult from "./components/searchResult";
import Address from "./components/address";
import SuccessOrder from "./SuccessOrder";
import Unlisting from "./components/unlisiting";
import Shipping from "./commonComponent/addressModal/Shipping";
import Logins from "./Login";
import ProductDiscription from "./Cartdescription";
import NewFooter from "./components/footer/newFooter";
import Sitemap from "./sitemap";

const TRACKING_ID = "G-LHBQBP8CYF"; // Replace with your tracking ID

ReactGA.initialize(TRACKING_ID);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="relative roboto-regular">
      <ScrollToTop />
      <CartProvider>
        {/* <Header /> */}
        <Toaster />
        <div className="fixed top-0 z-10 w-full">
          {(window.location.pathname !== "/PrivacyPolicy" ||
            window.location.pathname !== "/successorder") && <Navbar />}
        </div>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/search" Component={SearchResult} />
          <Route path="/Skinco" Component={Skinco} />
          <Route path="/Skinco/*" Component={Skinco} />
          <Route path="/spring" Component={Spring} />
          <Route path="/spring/*" Component={Spring} />
          <Route path="/haircare" Component={Cartdescription} />
          <Route path="/haircare/*" Component={Cartdescription} />
          {/* <Route path="/best-sellers" Component={BestSeller} /> */}
          <Route path="/hair-care" Component={Haircareallproducts} />
          <Route path="/skin-care" Component={Skincareallproducts} />
          <Route path="/best-sellers" Component={Bestsellerlist} />
          <Route path="/best-sellers-combos" Component={Skincareallproducts} />
          <Route path="/newlaunches" Component={Skincareallproducts} />
          {/* <Route path="/cartdescription/:productname/:id" Component={Cartdescription} /> */}
          <Route
            path="/products/:shortUrl"
            Component={ProductDiscription}
          />
          <Route path="/Argatin" Component={Argatin} />
          <Route path="/Argatin/*" Component={Argatin} />
          <Route path="/Login" Component={Logins} />
          <Route path="/SignUp" Component={SignUp} />
          <Route path="/ResetPassword" Component={ResetPassword} />
          <Route path="/Menicure" Component={Menicure} />
          <Route path="/FacialKit" Component={FacialKit} />
          <Route path="/Profile" Component={Profile} />
          <Route path="/AddressModal" Component={Shipping} />
          <Route path="/Faq" Component={Faq} />
          <Route path="/PrivacyPolicy" Component={PrivacyPolicyComponent} />
          <Route path="/ReturnRefund" Component={ReturnRefund} />
          {/* <Route path="/ordersummary" Component={Ordersummary} /> */}
          <Route path="/ordersummary" Component={YourOrders} />
          <Route path="/AboutUs" Component={AboutUs} />
          <Route path="/distributer" Component={Distributer} />
          {/*<Route path="/loginform" Component={LoginForm} />*/}
          <Route path="/productdetailform" Component={Productformdetails} />
          <Route path="/form" Component={Editform} />
          <Route path="/productupdateform" Component={Productupdateform} />
          <Route path="/formupdate" Component={Formupdate} />
          <Route path="/btx-hair-care" Component={Btxhaircare} />
          <Route path="/argatin-shampoo" Component={Argatinshampoo} />
          <Route path="/hairconditioner" Component={Hairconditioner} />
          <Route path="/hairoil" Component={Hairoil} />
          <Route
            path="/argatin-keratin-treatment"
            Component={Argatinkeratintreatment}
          />
          <Route path="/product-combo" Component={Listcombo} />
          <Route path="/ContactUs" Component={ContactUs} />
          <Route path="/TermsAndCondition" Component={TermsAndCondition} />
          <Route path="/loreal" Component={Loreal} />
          <Route path="/loreal/*" Component={Loreal} />
          <Route path="/lorealallproducts" Component={Lorealallproducts} />
          <Route path="/myprofile" Component={Profile} />
          <Route path="/addresses" Component={Address} />
          <Route path="/unlisting" Component={Unlisting} />
          <Route path="/successorder" Component={SuccessOrder} />
          <Route path="/Sitemap" Component={Sitemap} />
          <Route path="*" Component={Home} />
        </Routes>
        {/* <Footer /> */}
        {/* {(window.location.pathname !== "/PrivacyPolicy" || window.location.pathname !== "/successorder")  && <Footer />} */}
        {(window.location.pathname !== "/PrivacyPolicy" ||
          window.location.pathname !== "/successorder") && <NewFooter />}
      </CartProvider>
    </div>
  );
}

export default App;

// import React, { useState } from "react";
// import "./App.css";
// // import Modal from "./Components/Modal";
// import Modal from "./commonComponent/rawmodal";

// function App() {
//   const [modalOpen, setModalOpen] = useState(false);

//   return (
//     <div className="App">
//       <h1>Hey, click on the button to open the modal.</h1>
//       <button
//         className="openModalBtn"
//         onClick={() => {
//           setModalOpen(true);
//         }}
//       >
//         Open
//       </button>

//       {modalOpen && <Modal setOpenModal={setModalOpen} />}
//     </div>
//   );
// }

// export default App;
