import React, { useEffect, useState } from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./pricefilter.css";
import { MdCurrencyRupee } from "react-icons/md";

const Pricefilter = ({
  onPriceRangeChange,
  newPriceRange,
  productformdetail,
}) => {
  const [sliderRange, setSliderRange] = useState([0, 3000]);
  console.log("narottam", productformdetail);

  useEffect(() => {
    // const averagePrice =
    //   productformdetail.reduce((sum, product) => sum + product.price, 0) /
    //   productformdetail.length;

    const maxPrice = Math.max(
      ...productformdetail.map((product) => product.price)
    );

    setSliderRange([0, maxPrice]);
    // setSliderRange([0, Math.ceil(averagePrice)]);
  }, [productformdetail]);

  const handleAfterChange = () => {
    console.log("Price range updated:", newPriceRange);
  };
  const formatTooltip = (value) => {
    return (
      <span>
        <MdCurrencyRupee />
        {value}
      </span>
    );
  };

  return (
    <div>
      <div className="sidebar">
        <div className="price-range-filter">
          <label>
            Price
            <Slider.Range
              min={0}
              max={sliderRange[1]}
              //style={{width:'210px'}}
              value={newPriceRange}
              onChange={onPriceRangeChange}
              onChangeComplete={handleAfterChange}
              //  pushable={true}
              draggableTrack={true}
              className="pricerange-slider"
              // tipFormatter={(value) => `$${value}`}
              // marks={{ 0: "0", 3000: "3000" }}
              tipFormatter={formatTooltip}
              marks={{ 0: "0", [sliderRange[1]]: `₹${sliderRange[1]}` }}
              trackStyle={[
                { backgroundColor: "#000" },
                { backgroundColor: "#000" },
              ]}
              handleStyle={[
                {
                  backgroundColor: "#000",
                  borderColor: "#000",
                  transition: "all 0.3s ease",
                },
                {
                  backgroundColor: "#000",
                  borderColor: "#000",
                  transition: "all 0.3s ease",
                },
              ]}
            />
            <p className="pricerangestyle">
              Selected Price Range: ₹{newPriceRange[0]} - ₹{newPriceRange[1]}
            </p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Pricefilter;
