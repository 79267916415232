import React from "react";
import { BsInstagram } from "react-icons/bs";
import { FaFacebook, FaYoutube, FaTwitter, FaPinterest } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoMdCall } from "react-icons/io";
import { IoMailSharp } from "react-icons/io5";

const NewFooter = () => {
  return (
    <div className="p-5 md:p-20 flex flex-col bg-black text-white w-full merriweather-bold-italic ">
      <div className="flex justify-evenly gap-x-3 md:justify-around items-start  text-lg font-bold flex-wrap">
        <div className="flex flex-col">
          <h1 className="text-xs md:text-md mb-5 font-semibold text-white">
            More
          </h1>
          <Link to={"/AboutUs"}>
            <h1 className="text-xs mt-2 font-semibold text-white cursor-pointer">
              About us
            </h1>
          </Link>
          <Link to={"/Faq"}>
            <h1 className="text-xs mt-2 font-semibold text-white cursor-pointer">
              Faq
            </h1>
          </Link>
          <Link to={"/Sitemap"}>
            <h1 className="text-xs mt-2 font-semibold text-white cursor-pointer">
              Site map
            </h1>
          </Link>
        </div>
        <div className="flex flex-col">
          <h1 className="text-xs md:text-md mb-5 font-semibold text-white">
            Our Brands
          </h1>
          <Link to={"/Argatin"}>
            <h1 className="text-xs mt-2 font-semibold text-white cursor-pointer">
              Argatin
            </h1>
          </Link>
          <Link to={"/Skinco"}>
            <h1 className="text-xs mt-2 font-semibold text-white cursor-pointer">
              Skin Co
            </h1>
          </Link>
          <Link to={"/spring"}>
            <h1 className="text-xs mt-2 font-semibold text-white cursor-pointer">
              Spring H2O
            </h1>
          </Link>
        </div>
        <div className="flex flex-col">
          <h1 className="text-xs md:text-md mb-5 font-semibold text-white">
            Policies
          </h1>
          <Link to={"/PrivacyPolicy"}>
            <h1 className="text-xs mt-2 font-semibold text-white cursor-pointer">
              Privacy Policy
            </h1>
          </Link>
          <Link to={"/TermsAndCondition"}>
            <h1 className="text-xs mt-2 font-semibold text-white cursor-pointer">
              Terms & Conditions
            </h1>
          </Link>
          <Link to={"/ReturnRefund"}>
            <h1 className="text-xs mt-2 font-semibold text-white cursor-pointer">
              Refund & Return Policy
            </h1>
          </Link>
        </div>
        <div className="flex flex-col justify-start items-start">
          <h1 className="text-xs md:text-md mb-5 font-semibold text-white mt-7 md:mt-0">
            Get in touch
          </h1>
          <h1 className="text-sm font-semibold text-white cursor-pointer flex justify-center items-center font-sans gap-x-3">
            <IoMdCall className="text-xl font-bold" />
            9315743367
          </h1>
          <h1 className="text-sm font-semibold text-white cursor-pointer flex justify-center items-center font-sans gap-x-3 mt-2">
            <IoMailSharp className="text-xl font-bold" />
            info@theprofessionalworld.com
          </h1>
        </div>
        <div className="flex flex-col">
          <h1 className="text-xs md:text-md mb-5 font-semibold text-white  mt-7 md:mt-0">
            Our Social Media
          </h1>
          <div className="flex flex-row gap-x-3 md:gap-x-5">
            <a href={"https://www.instagram.com/argatinkeratin/"}>
              <h1 className="text-md font-semibold text-white cursor-pointer hover:scale-110">
                <BsInstagram />
              </h1>
            </a>
            <a href={"https://www.facebook.com/people/Skinco/100085397768322/"}>
              <h1 className="text-md font-semibold text-white cursor-pointer hover:scale-110">
                <FaFacebook />
              </h1>
            </a>
            <a
              href={"https://www.youtube.com/channel/UCOoZ6zYRKHJ-8jFZjR-Ar1A"}
            >
              <h1 className="text-md font-semibold text-white cursor-pointer hover:scale-110">
                <FaYoutube />
              </h1>
            </a>
            <a href={"https://twitter.com/argatinkeratin"}>
              <h1 className="text-md font-semibold text-white cursor-pointer hover:scale-110">
                <FaTwitter />
              </h1>
            </a>
            <a href={"https://in.pinterest.com/skinconyc/"}>
              <h1 className="text-md font-semibold text-white cursor-pointer hover:scale-110">
                <FaPinterest />
              </h1>
            </a>
          </div>
        </div>
      </div>
      <div className="border-t-2 border-white mt-4">
        <h1 className="text-xs md:text-md mb-5 font-semibold text-white text-center mt-3">
          The Professional Hair Salon & Spa (India) Pvt. Ltd.
        </h1>
        <h1 className="text-xs md:text-md mb-5 font-semibold text-white text-center mt-3">
          Address: Plot No-31 Okhla Industrial Estate, Phase – 3, South Delhi,
          New Delhi – 110020
        </h1>
      </div>
    </div>
  );
};

export default NewFooter;
