import React from 'react'
import { useNavigate } from 'react-router-dom'

const AccountCard = ({title,desc,img,src}) => {
  const navigate = useNavigate();
  const handleNavigation =()=>{
    navigate(src)
  }
  return (


    <div className='flex  rounded-lg shadow-xl w-[350px] h-[150px] cursor-pointer p-4' onClick={handleNavigation}>
        <div className='mr-4'>
            <img src={img} alt={""} className='w-[50px] h-[50px]' />
        </div>
        <div className='flex flex-col'>
            <h1 className='text-lg font-semibold text-black'>{title}</h1>
            <p className='text-md font-semibold text-gray-500'>{desc}</p>
        </div>

    </div>
  )
}

export default AccountCard