import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BestSeller from "../bestseller";
import Pricefilter from "../commonComponent/priceFilter/Pricefilter";
import { IoMdClose } from "react-icons/io";
import "./lorealallproducts.css";
import { postApiData } from "../utils/services";
import { IoFilter } from "react-icons/io5";
import CustomCart from "../commonComponent/customcard";
import ProductCart from "../commonComponent/customcard/productCart";
import CustomProductTag from "../commonComponent/gtm";

const Lorealallproducts = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [productformdetail, setProductformdetail] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [newPriceRange, setNewPriceRange] = useState([0, 3000]);
  const [showFilter, setShowFilter] = useState(false);
  const [noProductFound, setNoProductFound] = useState(false);
  const apidata = {
    brands: {
      Loreal:true
    },
  };

  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setProductformdetail(callBack);
      setFilteredProducts(callBack); // Initialize filteredProducts with all products
      console.log("navbar", callBack);
    });
  }, []);

  const handlePriceRangeChange = (newPriceRange) => {
    console.log(newPriceRange);
    setNewPriceRange(newPriceRange);
    const filtered = productformdetail.filter(
      (product) =>
        product.price >= newPriceRange[0] && product.price <= newPriceRange[1]
    );
    console.log(filtered);
    setFilteredProducts(filtered?.length > 0 ? filtered : productformdetail);
    setNoProductFound(filtered.length === 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const closeFilter = () => {
    setShowFilter(false);
  };

  const containerClasses = `newprice-container ${showFilter ? "show" : "hide"}`;

  return (
    <>
      <div className="mt-24">
        <div className=" shadow text-white"></div>
      </div>

      <div className="btn-class-container">
        {window.innerWidth >= 350 && window.innerWidth < 1000 && (
          <div>
            <div className="btn-filter">
              <button onClick={toggleFilter}>
                {showFilter ? (
                  <div style={{ display: "flex" }}>
                    <IoFilter />
                    <span> Filter </span>
                  </div>
                ) : (
                  <div
                    style={{ display: "flex", gap: "13px", fontSize: "18px" }}
                  >
                    <IoFilter />
                    <span> Filter </span>
                  </div>
                )}
              </button>
            </div>
            <div className={containerClasses}>
              <IoMdClose onClick={closeFilter} />
              {showFilter && (
                <Pricefilter
                  newPriceRange={newPriceRange}
                  onPriceRangeChange={handlePriceRangeChange}
                  productformdetail={productformdetail}
                />
              )}
            </div>
          </div>
        )}
        {window.innerWidth > 1000 && (
          <Pricefilter
            newPriceRange={newPriceRange}
            onPriceRangeChange={handlePriceRangeChange}
            productformdetail={productformdetail}
          />
        )}
        <div className="flex flex-row gap-5 justify-between items-center flex-wrap md:mx-10">
        {
          filteredProducts?.map((item,index)=>(
            <>
            <ProductCart key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)}  stockQuantity={item.stockQuantity} productType={"product"}/>
            <CustomProductTag product={item}/>
            </>
        ))
      }
      </div>

        {/* <CustomCart productdata={filteredProducts} productType={"product"} /> */}
      </div>
    </>
  );
};

export default Lorealallproducts;
