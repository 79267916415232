import React, { useEffect } from 'react';

const CustomProductTag = ({ product }) => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'productView',
      'ecommerce': {
        'detail': {
          'products': [
            {
              'id': product.id,
              'name': product.name,
              'price': product.price,
              'brand': product.brand,
              'category': product.category,
              'image': product.images[0]
            }
          ]
        }
      }
    });
  }, [product]);

  return null;
};

export default CustomProductTag;
