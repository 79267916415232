import React, { useState } from "react";
import "./card.css";
import { useDispatch, useSelector } from "react-redux";
// import postProductData from "../Redux/actions";
import { postProductData } from "../Redux/actions";
import { Link } from "react-router-dom";
import Login from "../Register/Register";
import Cartdescription from "../Cartdescription/Cartdescription";

import Editform from "../Editform/Editform";

export default function BestSeller(props) {
  const [cartdescrip, setcartdescrip] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { form } = props;
  console.log("form details", form);
  function gotocart() {
    setcartdescrip(!cartdescrip);
  }
  const { productdata } = props;
  const dispatch = useDispatch();
  const [loginModal, setLoginModal] = useState(false);
  const token = useSelector((store) => store.authReducer.userData);

  const addToCart = (product) => {
    // console.log("addedproduct",product)

    if (token) {
      dispatch(postProductData(product, 1));
      //dispatch(postProductData(product, 2));
      // Open the modal
      setIsModalOpen(true);

      // Close the modal after 2 seconds (adjust as needed)
      // setTimeout(() => {
      //   setIsModalOpen(false);
      // }, 2000);
    } else {
      setLoginModal(true);
    }
  };
  return (
    <div className="container mx-auto px-4 mt-5 productlistcontainer1" onClick={gotocart} >
      <div className="bestseller1con grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-10 px-11 ">
        {productdata?.map((product, index) => (
          // <Link to="/Cartdescription" state={{ data: product }}>
          <div
            key={index}
            className="flex flex-col items-center productlist-container"
            style={{
              border: "1px solid #cccc",
              borderRadius: "2px",
              // backgroundColor: "grey",
            }}
          >
            <Link
              to={form ? "/form" : "/cartdescription"}
              state={{ data: product }}
            >
              {product.image_link ? (
                <img
                  className="h-[200px] w-[200px] object-cover mb-2 productlist-img"
                  src={product.image_link}
                  alt="product"
                  style={{ marginTop: "10px" }}
                />
              ) : (
                <Link
                  to={form ? "/form" : "/cartdescription"}
                  state={{ data: product }}
                  className="product-link"
                >
                  <img
                    className="h-[200px] w-[200px] object-cover mb-2 productlist-img"
                    src={product.images[0]}
                    alt="product"
                  />
                </Link>
              )}
            </Link>
            <div className="flex justify-center mb-2">
              <div class="flex items-center space-x-1">
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-gray-300 dark:text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
              </div>
            </div>
            <div className="text-center w-[270px]">
              <p
                className="mb-2 text-name"
                style={{
                  fontSize: "15px",
                  fontWeight: 500,
                  width: "300px",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2, // Limit the text to two lines
                  paddingRight: "15px",
                }}
              >
                {product.name}
              </p>
              <div className="flex flex-col items-center">
                <div className="flex items-center">
                  <p className="line-through text-gray-500 mr-2">
                    ₹{product.mrp}
                  </p>
                  {(1 - product.price / product.mrp) * 100 > 0 && (
                    <p className="text-green-500">
                      -{Math.round((1 - product.price / product.mrp) * 100)}%
                      OFF
                    </p>
                  )}
                </div>
                <p className="text-red-500">₹{product.price}</p>
              </div>
              <button
                className="bg-black text-white mb-5 mt-4 add_to_cart_btn"
                onClick={() => addToCart(product)}
              >
                Add To Cart
              </button>

              {loginModal && (
                // Render the Login component if loginModal is true
                <Login modalIsOpen={loginModal} setIsOpen={setLoginModal} />
              )}

              {isModalOpen && (
                <div className="notification">
                  <p>Product added to cart successfully!</p>

                  <button
                    className="close-cart-button"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
          // </Link>
        ))}
      </div>
      {/* {cartdescrip && <Cartdescription productdata={productdata} />} */}
    </div>
  );
}
