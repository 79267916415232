import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import BestSeller from '../bestseller';
import Allproducts from '../Allproductlist/Allproductlist';
import Cartdescription from '../Cartdescription/Cartdescription';
import { postApiData } from '../utils/services';

const Btxhaircare = () => {
    const [productformdetail, setProductformdetail] = useState([]);
  const apidata = {
    brand: "",
  };
  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setProductformdetail(callBack);
      console.log("navbar", callBack);
    });
  }, []);
  console.log("apidataa",productformdetail)
    const location=useLocation();
    const { productdata } = location.state || {};

    console.log("dataneeds",productdata)
    return (
        <div className="mt-24">
        {/* <div >
            <h1 className="mt-[30px] ml-[20px] mb-[3rem] text-3xl">Chemist At Play</h1>
            <div className="border-b-2 border-black shadow text-white"></div>
        </div> */}
        {/*<div className='mt-[3rem] ml-[3rem] '>
            <h1 className='text-2xl text-bold mb-2'>Availability</h1>
            <h1 className='text-2xl text-bold mb-2'>Price</h1>
            <h1 className='text-2xl text-bold mb-2'>Product type</h1>
            <h1 className='text-2xl text-bold mb-2'>Brand</h1>
        </div>
         */}
        <div>
      
            
           {/*<Allproducts productdata={productdata}/>*/}
           <BestSeller productdata={productformdetail} />
          
        </div>
        </div>
        




    )
}

export default Btxhaircare;