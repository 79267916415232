// import React, { useEffect, useState } from "react";

// import { useDispatch } from "react-redux";
// import { cardAdded, cardProductAdded, totalProduct } from "../Redux/actions";
// import BestSeller from "../bestseller";
// import { getApiCall, postApiData } from "../utils/services";
// import { Link } from "react-router-dom";
// import CustomCart from "../commonComponent/customcard";
// const Listcombo = () => {
//   const [data, setData] = useState([]);
//   const [productlistData, setproductListData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [categorypress, setCategorypress] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState("Best Sellers");
//   const handleButtonClick = (buttonName) => {
//     // setLoading(true);
//     setSelectedCategory(buttonName);
//     setCategorypress(!categorypress);
//   };

//   const dispatch = useDispatch();

//   useEffect(() => {
//     getApiCall("/user/getComboProducts", (callBack) => {
//       setproductListData(callBack);
//       console.log("fgetAllProductsst", callBack);
//       dispatch(totalProduct(callBack));
//     });
//   }, []);
//   console.log("productcombodata",productlistData)

//   const buttonStyle = {
//     backgroundColor: "black",
//     color: "white",
//     transition: "background-color 0.3s, color 0.3s",
//   };

//   return (
//     <div className="xs:overflow-hidden sm:overflow-hidden">
//        <div>
//        <Link
//         to={{
//           pathname: "/Listcombo",
//           state: { productdata: productlistData },
//         }}
//       >
//         <div className="underline font-bold row justify-end flex mr-11">

//         </div>
//       </Link>

//      <div>
//         {/* <BestSeller productType="productcombo" productdata={productlistData}   /> */}
//         <CustomCart productType="productcombo" productdata={productlistData}   />

//       </div>
//     </div>
//     </div>
//   );
// };

// export default Listcombo;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BestSeller from "../bestseller";
import Pricefilter from "../commonComponent/priceFilter/Pricefilter";
import { IoMdClose } from "react-icons/io";
import "./productcombo.css";
import { getApiCall, postApiData } from "../utils/services";
import { IoFilter } from "react-icons/io5";
import CustomCart from "../commonComponent/customcard";
import ReactGA from "react-ga";
import CartDisplayer from "../commonComponent/CartDisplayer";
import DropdownWithCheckboxes from "../commonComponent/CheckBox";
import AccordionExpandIcon from "../commonComponent/CheckBox/AccordianCheckbox";
const TRACKING_ID = "G-56Q0JTSZKG";
ReactGA.initialize(TRACKING_ID);
const Listcombo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [productformdetail, setProductformdetail] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [newPriceRange, setNewPriceRange] = useState([0, 3000]);
  const [showFilter, setShowFilter] = useState(false);
  const [checkedBrands, setCheckedBrands] = useState({
    Loreal: false,
    // Skinconyc: false,
    Argatin: false,
    // SpringH2O: false,
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  // useEffect(() => {
  //   getApiCall("user/getComboProducts", (callBack) => {
  //     setProductformdetail(callBack);
  //     setFilteredProducts(callBack);
  //     console.log("navba", callBack);
  //   });
  // }, []);
  useEffect(()=>{
    const data ={
      brands: checkedBrands
    }
    postApiData("user/getComboProducts",
    data,
    (resp)=>{
      console.log(resp)
      setProductformdetail(resp);
    setFilteredProducts(resp);
    },
    (error)=>{
      console.log("error",error)
    }
    )
  },[checkedBrands])

  const handlePriceRangeChange = (newPriceRange) => {
    console.log(newPriceRange);
    setNewPriceRange(newPriceRange);
    const filtered = productformdetail.filter(
      (product) =>
        product.price >= newPriceRange[0] && product.price <= newPriceRange[1]
    );
    console.log("combo", filtered);
    setFilteredProducts(filtered?.length > 0 ? filtered : productformdetail);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const closeFilter = () => {
    setShowFilter(false);
  };

  const containerClasses = `newprice-container ${showFilter ? "show" : "hide"}`;

  return (
   <div className="flex justify-start items-start mt-24 relative">
      <div className="md:w-1/6 hidden   h-full md:flex flex-col fixed left-0 z-1">
      {window.innerWidth >= 350 && window.innerWidth < 1000 && (
     <div>
       <div className="btn-filter">
         <button onClick={toggleFilter}>
           {showFilter ? (
             <div style={{ display: "flex" }}>
               <IoFilter />
               <span> Filter </span>
             </div>
           ) : (
             <div style={{ display: "flex" }}>
               <IoFilter />
               <span> Filter </span>
             </div>
           )}
         </button>
       </div>
       <div className={containerClasses}>
         <IoMdClose onClick={closeFilter} />
         {showFilter && (
           <Pricefilter
             newPriceRange={newPriceRange}
             onPriceRangeChange={handlePriceRangeChange}
             productformdetail={productformdetail}
           />
         )}
       </div>
     </div>
   )}
   {window.innerWidth > 1000 && (
     <Pricefilter
       newPriceRange={newPriceRange}
       onPriceRangeChange={handlePriceRangeChange}
       productformdetail={productformdetail}
     />
    
   )}
   <DropdownWithCheckboxes checkedBrands={checkedBrands} setCheckedBrands={setCheckedBrands}/>
   {/* <AccordionExpandIcon /> */}
      </div>
      <div className="flex w-full justify-center md:justify-between items-center flex-wrap  lg:ml-64">
        <CartDisplayer productdata={filteredProducts} productType={"combos"} /> 
      </div>
   </div>   
  );
};

export default Listcombo;


// <>
      
// <div className="flex flex-col w-1/4 h-full">
// <div className="btn-class-container">
//   {/* <Pricefilter

//       newPriceRange={newPriceRange}
//       onPriceRangeChange={handlePriceRangeChange}
//     /> */}
//   {window.innerWidth >= 350 && window.innerWidth < 1000 && (
//     <div>
//       <div className="btn-filter">
//         <button onClick={toggleFilter}>
//           {showFilter ? (
//             <div style={{ display: "flex" }}>
//               <IoFilter />
//               <span> Filter </span>
//             </div>
//           ) : (
//             <div style={{ display: "flex" }}>
//               <IoFilter />
//               <span> Filter </span>
//             </div>
//           )}
//         </button>
//       </div>
//       <div className={containerClasses}>
//         <IoMdClose onClick={closeFilter} />
//         {showFilter && (
//           <Pricefilter
//             newPriceRange={newPriceRange}
//             onPriceRangeChange={handlePriceRangeChange}
//             productformdetail={productformdetail}
//           />
//         )}
//       </div>
//     </div>
//   )}
//   {window.innerWidth > 1000 && (
//     <Pricefilter
//       newPriceRange={newPriceRange}
//       onPriceRangeChange={handlePriceRangeChange}
//       productformdetail={productformdetail}
//     />
    
//   )}
  
// </div>
//   <div className="flex">
//     {/* <BestSeller productdata={filteredProducts} /> */}
//     {/* <CustomCart productdata={filteredProducts} productType={"combos"} /> */}
//     <CartDisplayer productdata={filteredProducts} productType={"combos"} /> 
//   </div>
// </div>
// </>