import React, { useEffect, useState } from 'react'
import setAuthorizationToken from '../utils/commonFunction';
import { useSelector } from 'react-redux';
import { getApiCall, postApiData } from '../utils/services';
import { MdCancel } from "react-icons/md";
import toast from 'react-hot-toast';

const YourOrders = () => {
    const [data, setData] = useState([]);
    const [isCancel,setIsCancel] = useState(false)
  const token = useSelector((store) => store.authReducer.userData);
  useEffect(() => {
    if (token) {
      setAuthorizationToken(token);
    }
  }, []);
  useEffect(() => {
    getApiCall("user/userOrderHistory", (response) => {
      if (response) {
        console.log("API Error:", response);
        setData(response);
      }
    });
  }, [isCancel]);
  console.log("order ka data--------",data)
  function FormatDate(date) {
    const dates = new Date(date)
    
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = formatter.format(dates);
  
    return formattedDate;
  }
  const truncateProductName = (name, maxLength) => {
    return name.length > maxLength ? name.substring(0, maxLength) + "..." : name;
  };

  const handleCancel=(id)=>{

    const data = {
      orderId : id
    }
    postApiData('user/cancelOrder',data,
    (resp)=>{
      console.log("Succesfully order cancel!",resp)
      toast.success("Order Cancelled Succefully")
      setIsCancel(!isCancel)
    },
    (error)=>{
      console.log("Error in cancelling the Order",error)
      // toast.error("Something gone wrong! Please try again!")
    }
  )
  }
  const getStatusNumber = (status) => {
    console.log("status",status)
    switch (status) {
      case 1:
        return "Placed";
      case 2:
        return "Cancelled";
      case 3:
        return "Completed";
      default:
        // Handle other cases if needed
        return null; // or 'N/A'
    }
  
  };

  return (
    <div className='  mt-24 w-[90%] mx-auto flex flex-col md:flex-row  justify-between items-center flex-wrap gap-3 '>
    {
        data?.map((item,index)=>(
            <div className='flex flex-col w-full md:w-1/4 shadow-xl gap-4 my-5  mx-2 '>
              <div className='flex flex-col justify-center items-start bg-black px-3 py-2 '>
                <h4 className='text-white text-md font-semibold'>Order No: {item.numericOrderId}</h4>
                <h4 className='text-white text-xs'>{FormatDate(item.createdAt)}</h4>
              </div>
              <div className='flex flex-col justify-start h-[180px] overflow-y-auto'>
              {
                item.products.map((product)=>(
                  <div className='flex justify-between gap-x-5 items-center px-2 py-1 my-4 bg-white border-b-2 border-gray-200'>
                    <img src={product.images[0]} alt={product.name} className='w-14 h-full rounded-xl' />
                    <div className='flex flex-col'>
                      <h4 className='text-sm font-semibold text-right'>{truncateProductName(product.name, 30)}</h4>
                      <h4 className='text-sm font-semibold text-right'>{product.brand}</h4>
                      <div className='flex justify-between item-center  px-3 py-2 rounded-t-xl'>
                <h4 className='text-black text-md font-semibold'>Rs: {product.price}</h4>
                <h4 className='text-black text-md'>Quantity :{product.quantity}</h4>
              </div>
                    </div>
                  </div>
                ))
              }
              </div>
              <div className='flex justify-end items-center gap-x-3 border-t border-gray-200 bg-black px-3 py-2'>
              <h1 className={`font-semibold text-md bg-white px-3 rounded-lg ${item.status === 1 ? 'text-blue-500' : item.status === 2 ? 'text-red-500' : 'text-green-600'}`}>{getStatusNumber(item.status)}</h1>
              {
                item.status === 1 && <button className='bg-red-500 px-2 font-semibold text-white cursor-pointer hover:text-black rounded-lg' onClick={()=>handleCancel(item._id)}>Cancel</button>
              }
              </div>
            </div>
        ))
    }
    </div>
  )
}

export default YourOrders



// {
//   item.products?.map((product,index)=>(
//       <div key={index} className='flex justify-between items-center w-full h-auto rounded-xl shadow-lg border-2 border-gray-200'>
//           <img src={product?.images[0]} alt={product?.name}  className='h-[200px] w-[300px]' />
//           <div className=' flex flex-col justify-start items-start w-1/2'>
          
//         <div className='flex flex-col justify-start items-start mx-5 '>
//         {item?.status === 0 && (
//           <>
//             <h1 className="text-lg font-bold text-red-600">. In Cart</h1>
//           </>
//         )}

//         {item?.status === 1 && (
//           <>
//             <h1 className="text-lg font-bold text-blue-600">. Placed</h1>
//           </>
//         )}

//         {item?.status === 2 && (
//           <>
//             <h1 className="text-lg font-bold text-red-600">. Cancelled</h1>
//           </>
//         )}


//         {item?.status === 3 && (
//           <>
//             <h1 className="text-lg font-bold text-green-600">. Delivered</h1>
//           </>
//         )}


//         {item?.status === 4 && (
//           <>
//             <h1>. rto</h1>
//           </>
//         )}


//         {item?.status === 5 && (
//           <>
//             <h1 className="text-lg font-bold text-blue-500">. Pending</h1>
//           </>
//         )}


//         {item?.status === 6 && (
//           <>
//             <h1>. Undelivered/Attempted</h1>
//           </>
//         )}


//         {item?.status === 7 && (
//           <>
//             <h1 className="text-lg font-bold text-green-600">. shipped</h1>
//           </>
//         )}

//           <h1 className='text-sm md:text-lg'>{product?.name}</h1>
//           <h1 className='text-lg md:text-xl text-green-600'>₹ {product?.price}</h1>
//         </div>

//           </div>
//       </div>
//   ))
// }