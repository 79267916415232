import React from 'react'
import { refundData } from '../../utils/dummydata'
import './refundreturn.css'

export default function ReturnRefund() {
  return (
    <div className="mt-24">
     {
        refundData.map((item,index)=>{
            return(
        <div className='faqContainer'>
            <p className='faqQusetion'>{item.question}</p>
            <p className='faqAns'>{item.ans}</p>

        </div>
     

            )
     
        })

     }
    </div>
  )
}
