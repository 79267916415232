import React, { useEffect } from 'react'
import orderImg from '../../src/assets/images/sucessorder.png'
import { useNavigate } from 'react-router-dom'
import TagManager from 'react-gtm-module';
import ReactGA from "react-ga";
import { Helmet } from 'react-helmet';


const SuccessOrder = () => {
    const navigate = useNavigate();
    const TRACKING_ID = "G-LHBQBP8CYF"; // Replace with your tracking ID
    useEffect(()=>{
      ReactGA.initialize(TRACKING_ID)
    },[])
    useEffect(()=>{
      ReactGA.event({
        category:'Conversion',
        action:'Purchase',
        value:1
      })
    },[])
    const handleNavigate=()=>{
        navigate('/ordersummary')
        
    }
   
    useEffect(() => {
      // Initialize GTM
      const tagManagerArgs = {
        gtmId: 'GTM-MDBT748C', // Your GTM container ID
      };
      TagManager.initialize(tagManagerArgs);
    }, []);
  
    const trackPurchase = () => {
      // Push data to the data layer
      window.dataLayer.push({
        event: 'Purchase',
        // ecommerce: {
        //   Purchase: {
        //     actionField: {
        //       id: 'YOUR_ORDER_ID', // Unique ID for the purchase
        //       revenue: 'YOUR_REVENUE_VALUE', // Total purchase value
        //       currency: 'YOUR_CURRENCY_CODE', // Currency code (e.g., USD, EUR)
        //     },
        //   },
        // },
      });
    };
  
    useEffect(() => {
      trackPurchase();
    }, []);
  
  return (
    <div className='w-sreen h-screen flex justify-center items-center bg-[#2E0F15]'>
    <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QVHPDKWFC7"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-QVHPDKWFC7');
          `}
        </script>
        <noscript>
        {`
        <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=1166284498035863&ev=PageView&noscript=1"
          />
        `}
          
        </noscript>
        <script>
          {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1166284498035863');
fbq('track', 'PageView');
  `}
        </script>
      </Helmet>
    <div className='flex flex-col'>
        <img src={orderImg} alt='orderimg' className='w-full md:w-1/2 mx-auto'/>
        <button className='bg-red-500 text-white font-semibold p-4 mt-4' onClick={handleNavigate}>Check Your Orders.</button>
    </div>
    </div>
  )
}

export default SuccessOrder