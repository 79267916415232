import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './loveus.css'
// import { LOCAL_IMAGES } from '../utils/localimages';
import { LOCAL_IMAGES } from '../../utils/localimages';
import { loveusData } from '../../utils/dummydata';

const LoveusSlider = () => {
  const lovesettings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000, 
    cssEase: 'linear',
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        }
      },
      
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3
        }
      }
    ]
  };

  const images = [
    LOCAL_IMAGES.loveusicon1,
    LOCAL_IMAGES.loveusicon2,
    LOCAL_IMAGES.loveusicon3,
    LOCAL_IMAGES.loveusicon4,
    LOCAL_IMAGES.loveusicon5,

  ];

  return (
    <div className="w-full xs:w-full sm:w-full xs:overflow-hidden sm:overflow-hidden">
      <Slider {...lovesettings}>
        {loveusData.map((item, index) => (
          <div key={index} className="">
            <img src={item.image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LoveusSlider;
