// import React from "react";
// import { useLocation } from "react-router-dom";
// import BestSeller from "../../bestseller";
// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { filterProducts } from "../../Redux/actions";
// import { sliderPricedata } from "../../Redux/actions";
// import CustomCart from "../../commonComponent/customcard";

// export default function Menicure() {
//   const location = useLocation();
//   const dispatch = useDispatch();
//   const productList = useSelector((state) => state.addProduct.products);
//   const fileterdata = useSelector((state) => state.addProduct.filterData);
//   console.log("location", fileterdata);
//   const { state } = location;
//   let maxprice = productList?.map((item) => item?.price);
//   const calculatedMax_Price = Math.max.apply(null, maxprice);
//   const [minValue, setMinValue] = useState(0);
//   const [maxValue, setMaxValue] = useState(100);
//   const [categoryName, setCategoryName] = useState("All");
//   const [price, setPrice] = useState(0);
//   const handleInput = (e) => {
//     setPrice(e.target.value);
//     dispatch(sliderPricedata(price));
//   };
//   const arrx = productList.filter((sliderprice) => {
//     return sliderprice.price > parseInt(price, 10);
//   });

//   const handleMinChange = (event) => {
//     setMinValue(parseInt(event.target.value, 10));
//   };

//   const handleMaxChange = (event) => {
//     setMaxValue(parseInt(event.target.value, 10));
//   };
//   console.log("calculatedMax_Price", calculatedMax_Price);
//   const getUniqueData = (data, categoryType) => {
//     let newvalue = data?.map((item) => {
//       return item[categoryType];
//     });

//     newvalue = ["All", ...new Set(newvalue)];
//     return newvalue;
//   };
//   const categoryData = getUniqueData(productList, "category");
//   const updateFiltervalue = (name) => {
//     setCategoryName(name);
//     dispatch(filterProducts(name));
//   };
//   return (
//     <div style={{ backgroundColor: "" }}>
//       {/* <label htmlFor="min">Min Value: {minValue}</label>
//       <input
//         type="range"
//         id="min"
//         name="min"
//         min={0}
//         max={100}
//         value={minValue}
//         onChange={handleMinChange}
//       /> */}

//       {/* <label htmlFor="max">Max Value: {calculatedMax_Price}</label> */}
//       {/* <input type="range" onInput={ handleInput } /> */}
//       {/* <div>
//       <p>{"category"}</p>
//         {
// categoryData?.map((item,index)=>{
//   return(
//     <button key={index}
//     type='button'
//     name='category'
//     onClick={()=>updateFiltervalue(item)}>
//     <p>{item}</p>
//     </button>
//   )
// })
//         }
//       </div> */}

//       {/* <p style={{marginLeft:'10px'}}>{calculatedMax_Price}</p> */}
//       {categoryName == "All" ? (
//         <CustomCart productdata={productList} />
//       ) : (
//         <CustomCart productdata={fileterdata} />
//       )}
//       {/* <div>
//   {
//     arrx &&  <BestSeller productdata={arrx} />
//   }

//       </div> */}
//     </div>
//   );
// }
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//import BestSeller from "../bestseller";

import { IoMdClose } from "react-icons/io";
import "../../Haircareallproducts/haircareallproducts.css";

import { IoFilter } from "react-icons/io5";
import { postApiData } from "../../utils/services";
import CustomCart from "../../commonComponent/customcard";
import Pricefilter from "../../commonComponent/priceFilter/Pricefilter";

const Menicure = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [productformdetail, setProductformdetail] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [newPriceRange, setNewPriceRange] = useState([0, 3000]);
  const [showFilter, setShowFilter] = useState(false);
  const apidata = {
    brand: "",
  };

  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setProductformdetail(callBack);
      setFilteredProducts(callBack); // Initialize filteredProducts with all products
      console.log("navbar", callBack);
    });
  }, []);

  const handlePriceRangeChange = (newPriceRange) => {
    console.log(newPriceRange);
    setNewPriceRange(newPriceRange);
    const filtered = productformdetail.filter(
      (product) =>
        product.price >= newPriceRange[0] && product.price <= newPriceRange[1]
    );
    console.log(filtered);
    setFilteredProducts(filtered?.length > 0 ? filtered : productformdetail);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const closeFilter = () => {
    setShowFilter(false);
  };

  const containerClasses = `newprice-container ${showFilter ? "show" : "hide"}`;

  return (
    <>
      <div className="mt-24">
        {/* <h1 className="mt-[30px] ml-[20px] mb-[3rem] text-3xl">
          Chemist At Play
        </h1> */}
        <div className="border-b-2 border-black shadow text-white"></div>
      </div>
      <div className="btn-class-container">
        {/* <Pricefilter

            newPriceRange={newPriceRange}
            onPriceRangeChange={handlePriceRangeChange}
          />  */}
        {window.innerWidth >= 350 && window.innerWidth < 1000 && (
          <div>
            <div className="btn-filter">
              <button onClick={toggleFilter}>
                {showFilter ? (
                  <div style={{ display: "flex" }}>
                    <IoFilter />
                    <span> Filter </span>
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <IoFilter />
                    <span> Filter </span>
                  </div>
                )}
              </button>
            </div>
            <div className={containerClasses}>
              <IoMdClose onClick={closeFilter} />
              {showFilter && (
                <Pricefilter
                  newPriceRange={newPriceRange}
                  onPriceRangeChange={handlePriceRangeChange}
                />
              )}
            </div>
          </div>
        )}
        {window.innerWidth > 1000 && (
          <Pricefilter
            newPriceRange={newPriceRange}
            onPriceRangeChange={handlePriceRangeChange}
          />
        )}

        <div>
          {/* <BestSeller productdata={filteredProducts} /> */}
          <CustomCart productdata={filteredProducts} productType={"product"} />
        </div>
      </div>
    </>
  );
};

export default Menicure;
