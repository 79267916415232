import React, { useEffect, useState } from 'react';
import './editform.css';

import { useLocation } from 'react-router-dom';
import { postApiData } from '../utils/services';

const Editform = () => {

  const { state } = useLocation();
  const [showForm, setShowForm] = useState(false);
  console.log("jkdfhkfh", state)
  console.log(state?.data?.image_link)
  console.log(state?.data?._id)
  const [imageLink, setImageLink] = useState(state?.data?.image_link || '');
  const [name, setName] = useState(state?.data?.name || '');
  const [price, setPrice] = useState(state?.data?.price || '');
  const [mrp, setMrp] = useState(state?.data?.mrp || '');
  //const [dimention, setDimention] = useState(state?.data?.dimention || '');
  const [color, setColor] = useState(state?.data?.color || '');

  const [ingredients, setIngredients] = useState(state?.data?.ingredients || '');
  const [itemform, setitemForm] = useState(state?.data?.itemform || '');
  const [liquidvolume, setliquidVolume] = useState(state?.data?.liquidvolume || '');
  const [productbenefit, setproductBenefit] = useState(state?.data?.productbenefit || '');
  const [eancode, setEancode] = useState(state?.data?.eancode || '');
  const [description, setDescription] = useState(state?.data?.description || '');
  const [quantity, setQuantity] = useState(state?.data?.quantity || 0);
  const [brand, setBrand] = useState(state?.data?.brand || '');
  const [category, setCategory] = useState(state?.data?.category || '');
  const [subCategory, setSubCategory] = useState(state?.data?.subCategory || '');
  const [hsncode, setHsncode] = useState(state?.data?.hsncode || '');
  const [uniqueId, setUniqueId] = useState(state?.data?.uniqueId || '');
  const [skucode, setSkucode] = useState(state?.data?.skucode || '');

  const [length, setLength] = useState(state?.data?.length || 0);
  const [breadth, setBreadth] = useState(state?.data?.breadth || 0);
  const [height, setHeight] = useState(state?.data?.height || 0);
  const [weight, setWeight] = useState(state?.data?.weight || 0);
  const [images, setImages] = useState(state?.data?.images || []);

  const HandleUpdate = () => {
    postApiData("/form/updateProductInfo", {

      id: state?.data?._id,
      obj: {
        name: name,
        price: price,
        mrp: mrp,
        weight: weight,
        // dimention: dimention,
        color: color,
       
        ingredients: ingredients,
        itemform: itemform,
        liquidvolume: liquidvolume,
        productbenefit: productbenefit,
        eancode: eancode,
        description: description,
        quantity: quantity,
        brand: brand,
        category: category,
        subCategory: subCategory,
        hsncode: hsncode,
        skucode: skucode,
        uniqueId: uniqueId,
        length: length,
        breadth: breadth,
        height: height,
        weight: weight,
        images: images
      }

    }, (resp) => {
      console.log("reponsapi add", resp)

    });

  }

  // Navigate to the CartDescription component

  const handleAddButtonClick = () => {
    setShowForm(!showForm);
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', {
      imageLink,
      name,
      price,
      mrp,
      eancode,
      description,
      quantity,
      brand,
      category,
      subCategory,
      hsncode,
      skucode,
      uniqueId,
      length,
      breadth,
      height,
      weight,
      images,
    });

    // Reset the form state or perform any other actions
    setImageLink('');
    setName('');
    setPrice('');
    setMrp('')
    setWeight('');
    //setDimention('');
    setColor('');
    
    setIngredients('');
    setitemForm('');
    setliquidVolume('');
    setproductBenefit('');
    setEancode('');
    setDescription('');
    setQuantity(0);
    setBrand('');
    setCategory('');
    setSubCategory('');
    setHsncode('');
    setSkucode('');
    setUniqueId('');
    setLength(0);
    setBreadth(0);
    setHeight(0);
    setWeight(0);
    setImages([]);
    setShowForm(false);
  };
  return (

    <div className="app mt-24">
      <button onClick={handleAddButtonClick}
        style={{
          display: "flex",
          marginLeft: "700px",
          backgroundColor: "blue",
          color: "white",
          width: "100px",
          height: "50px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          justifyContent: "center",
          marginTop:'150px'

        }}>
        Add
      </button>
      {showForm && (
        <form className="add-form" onSubmit={handleSubmit}>
          <label htmlFor="imageLink">Image Link:</label>
          <input
            type="text"
            id="imageLink"
            name="imageLink"
            value={imageLink}
            onChange={(e) => setImageLink(e.target.value)}
          />

          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <label htmlFor="price">Price:</label>
          <input
            type="text"
            id="price"
            name="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />

          <label htmlFor="price">Mrp:</label>
          <input
            type="text"
            id="mrp"
            name="mrp"
            value={mrp}
            onChange={(e) => setMrp(e.target.value)}
          />

          {/* Add more fields */}

          <label htmlFor="price">Weight:</label>
          <input
            type="text"
            id="weight"
            name="weight"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          {/* <label htmlFor="price">Dimention:</label>
          <input
            type="text"
            id="dimention"
            name="dimention"
            value={dimention}
            onChange={(e) => setDimention(e.target.value)}
          /> */}

          <label htmlFor="color">Color:</label>
          <input
            type="text"
            id="color"
            name="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />

          
          <label htmlFor="ingredients">Ingredients:</label>
          <input
            type="text"
            id="ingredients"
            name="ingredients"
            value={ingredients}
            onChange={(e) => setIngredients(e.target.value)}
          />
          <label htmlFor="itemform">Item form:</label>
          <input
            type="text"
            id="itemform"
            name="itemform"
            value={itemform}
            onChange={(e) => setitemForm(e.target.value)}
          />
          <label htmlFor="itemform">Liquid Volume:</label>
          <input
            type="text"
            id="liquidvolume"
            name="liquidvolume"
            value={liquidvolume}
            onChange={(e) => setliquidVolume(e.target.value)}
          />
          <label htmlFor="itemform">Product Benefits:</label>
          <input
            type="text"
            id="productbenefit"
            name="productbenefit"
            value={productbenefit}
            onChange={(e) => setproductBenefit(e.target.value)}
          />





          {/* Add more fields */}
          <label htmlFor="eancode">EAN Code:</label>
          <input
            type="text"
            id="eancode"
            name="eancode"
            value={eancode}
            onChange={(e) => setEancode(e.target.value)}
          />

          <label htmlFor="description">Description:</label>
          <input
            type="text"
            id="description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <label htmlFor="quantity">Quantity:</label>
          <input
            type="number"
            id="quantity"
            name="quantity"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
          />

          <label htmlFor="brand">Brand:</label>
          <input
            type="text"
            id="brand"
            name="brand"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          />

          <label htmlFor="category">Category:</label>
          <input
            type="text"
            id="category"
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />

          <label htmlFor="subCategory">Sub Category:</label>
          <input
            type="text"
            id="subCategory"
            name="subCategory"
            value={subCategory}
            onChange={(e) => setSubCategory(e.target.value)}
          />

          <label htmlFor="hsncode">HSN Code:</label>
          <input
            type="text"
            id="hsncode"
            name="hsncode"
            value={hsncode}
            onChange={(e) => setHsncode(e.target.value)}
          />

          <label htmlFor="hsncode">Sku Code:</label>
          <input
            type="text"
            id="skucode"
            name="skucode"
            value={skucode}
            onChange={(e) => setSkucode(e.target.value)}
          />


          <label htmlFor="hsncode">Unique Id:</label>
          <input
            type="text"
            id="uniqueid"
            name="uniquecode"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
          />


          <label htmlFor="length">Length:</label>
          <input
            type="number"
            id="length"
            name="length"
            value={length}
            onChange={(e) => setLength(Number(e.target.value))}
          />

          <label htmlFor="breadth">Breadth:</label>
          <input
            type="number"
            id="breadth"
            name="breadth"
            value={breadth}
            onChange={(e) => setBreadth(Number(e.target.value))}
          />

          <label htmlFor="height">Height:</label>
          <input
            type="number"
            id="height"
            name="height"
            value={height}
            onChange={(e) => setHeight(Number(e.target.value))}
          />

          <label htmlFor="weight">Weight:</label>
          <input
            type="number"
            id="weight"
            name="weight"
            value={weight}
            onChange={(e) => setWeight(Number(e.target.value))}
          />

          <label htmlFor="images">Images:</label>
          <textarea
            type="text"
            id="images"
            className='imagesall'
            name="images"
            value={images.join(', ')}
            onChange={(e) => setImages(e.target.value.split(',').map(img => img.trim()))}
          />
          {/* <label htmlFor="images">Images:</label>
          <textarea
            type="text"
            id="images"
            className='imagesall'
            name="images"
            value={images.join(', ')}
            onChange={(e) => {
              const uniqueImages = new Set(e.target.value.split(',').map(img => img.trim()));
              setImages([...uniqueImages]);
            }}
          />
 */}



          <button type="submit" onClick={HandleUpdate}>
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default Editform;
