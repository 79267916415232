import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import "./distributer.css";
import { LOCAL_IMAGES } from "../../utils/localimages";
import MainHeading from "../../commonComponent/dividerline";

import { countryName } from "./country";

import { postApiData } from "../../utils/services";
import { redirect } from "react-router-dom";

export default function Distributer() {
  const collectiondata = [
    {
      image: LOCAL_IMAGES.image1,
    },
    {
      image: LOCAL_IMAGES.image2,
    },
    {
      image: LOCAL_IMAGES.image3,
    },
    {
      image: LOCAL_IMAGES.image4,
    },
    {
      image: LOCAL_IMAGES.image1,
    },
  ];

  const [formvalue, setformvalue] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    country: "",
    query: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setformvalue({ ...formvalue, [name]: value });
  };
  const handledistributionsubmit = (e) => {
    e.preventDefault();
    for (const key in formvalue) {
      if (!formvalue[key]) {
        alert(`Please fill in the ${key} field`);
        return; // Prevent form submission if any field is empty
      }   
    }
      postApiData(
        "/user/distributerForm",
        formvalue,
        (response) => {
          console.log('responsesubsdfklnfd',response)
         
          alert("Response submit SucessFully");
            setformvalue({
              name: "",
              phoneNumber: "",
              email: "",
              country: "",
              query: "",
            });
         
        },
        (error) => {
          console.error("Error submitting data to the database", error);
        }
      );

    

  
  };

  // const [selectedCountry, setSelectedCountry] = useState("");

  // const handleSelectChange = (event) => {
  //   setSelectedCountry(event.target.value);
  // };

  const [productfetchall, setproductfetchall] = useState([]);

  const sliderRef = useRef();
  const apidata = {
    brand: "",
  };
  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setproductfetchall(callBack);
    });
  }, []);
  console.log("hello", productfetchall.name);

  const certificationData = [
    {
      img: LOCAL_IMAGES.cer1,
    },
    {
      img: LOCAL_IMAGES.cer2,
    },
    {
      img: LOCAL_IMAGES.cer3,
    },
    {
      img: LOCAL_IMAGES.cer4,
    },
    {
      img: LOCAL_IMAGES.cer5,
    },
    {
      img: LOCAL_IMAGES.cer6,
    },
    {
      img: LOCAL_IMAGES.cer7,
    },
    {
      img: LOCAL_IMAGES.cer8,
    },
    {
      img: LOCAL_IMAGES.cer9,
    },
    {
      img: LOCAL_IMAGES.cer10,
    },
    {
      img: LOCAL_IMAGES.cer11,
    },
    {
      img: LOCAL_IMAGES.cer12,
    },
  ];

  const settingsreview = {
    //dots: true,
    // infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 500,
    cssEase: "linear",
    // arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsdistribution = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    // autoplay: true,
    speed: 800,
    autoplaySpeed: 2000,
    cssEase: "linear",

    // arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };
  function productClick() {
    window.location.href = "/";
  }

  return (
    <div style={{ overflowX: "hidden" }} className="mt-24">
      
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <img
          src={LOCAL_IMAGES.distributerImg}
          alt="distributerbanner"
          style={{ height: "auto", width: "100%" }}
        />
      </div>
      <h1 className="distribution-hedingfirst">
        The Professional Hair Salon & Spa
      </h1>
      <p className="distribution-parafirst">
        We want to pioneer the hair and beauty market with our goods. Our
        Keratin / BTX / Nanoplastia treatment series uses modern technology and
        natural bio-actives like Argan oil to help women worldwide feel
        attractive in their hair. Our products with natural Argan oil transform
        dull, lifeless hair into lustrous, gorgeous, and strong.
      </p>
      <h1 className="distribution-hedingfirst">Skinconyc: Skincare Essence</h1>
      <p className="distribution-parafirst">
        Premium skincare brand Skinconyc uses nature to deliver amazing results.
        Our products use the purest botanical components chosen for efficacy and
        skin-loving characteristics. Simple, effective, and pleasurable skincare
        is our goal.
      </p>

      <div className="distribution-middlecontainer">
        <div className="distribution-middlecontainer1">
          <h1>Quality is our commitment</h1>
          <p>
            We offer the best products and services at Skinconyc and Argatin.
            Our products are extensively tested for safety and efficacy.
            Sustainability and ethical sourcing are equally important to us.
          </p>
        </div>
        <div className="distribution-middlecontainer1">
          <h1>Argatin: Haircare Science</h1>
          <p>
            Argatin creates revolutionary hair products using cutting-edge
            science and natural ingredients. Our products treat dryness,
            breakage, thinning, and hair loss. We think everyone deserves
            healthy, attractive hair.
          </p>
        </div>
        <div className="distribution-middlecontainer1">
          <h1>Our Global Reach</h1>
          <p>
            We are happy to market Skinconyc and Argatin products to a growing
            global merchant and consumer network. Our goal is to provide
            excellent service and support.
          </p>
        </div>
      </div>

      <MainHeading
        headingText={"RELIABILITY IN QUALITY, PUNCTUALITY EVERY TIME"}
      />

      <div className="reliability-imageContainer">
        {collectiondata.map((image, index) => (
          <img key={index} src={image.image} alt={`Image ${index}`} />
        ))}
      </div>

      <form
        onSubmit={handledistributionsubmit}
        className="distribution-formgroupContainer"
      >
        <img src={LOCAL_IMAGES.qualityimg} alt="qualityimg" />
        <div className="distribution-formgroup">

          <p className="form-headingname" style={{color:'black'}}>
            {
              "Please Share Your requirements and we will get in touch with you "
            }
          </p>
          <div className="name-container">
            <div className="name-field">
              <h1>Your Name</h1>
              <input
                type="text"
                name="name"
                value={formvalue.name}
                onChange={handleInput}
                placeholder="Enter your name"
                style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
              />
            </div>
            <div className="phone-field">
              <h1>Phone</h1>
              <input
                type="number"
                name="phoneNumber"
                value={formvalue.phoneNumber}
                onChange={handleInput}
                placeholder="Enter your number"
                style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
              />
            </div>
          </div>

          <div className="name-container">
            <div className="name-field">
              <h1 style={{marginTop:'8px'}}>Email</h1>
              <input
                type="text"
                name="email"
                value={formvalue.email}
                onChange={handleInput}
                placeholder="Enter your email"
                style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
              />
            </div>
            <div className="phone-field">
              <h1 style={{marginTop:'8px'}}>Select Country</h1>
              <select
                name="country"
                value={formvalue.country}
                onChange={handleInput}
                style={{
                  backgroundColor: "white",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <option value="" disabled>
                  Please select
                </option>
                {countryName.map((country) => (
                  <option key={country.country_id} value={country.country_name}>
                    {country.country_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="query-container">
            <h1>Query</h1>
            <textarea
              className="query-inputbox"
              name="query"
              value={formvalue.query}
              onChange={handleInput}
            ></textarea>
          </div>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

          <button type="submit" className="submit-button">
            SUBMIT
          </button>
          </div>

        </div>
      </form>

      <MainHeading
        headingText={" PACKAGING TO FORMULATION"}
        className="heading-reliabiloity"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "23px",
        }}
      >
        <img src={LOCAL_IMAGES.packageImg} />
      </div>

      <MainHeading headingText={"OUR PRODUCTS"} />
      <div
        className="distribution-container"
        style={{ cursor: "pointer", marginTop: "23px" }}
      >
        <Slider ref={sliderRef} {...settingsdistribution}>
          {productfetchall?.map((product) => (
            <div key={product.id} onClick={productClick}>
              <img
                src={product.images[0]}
                className="distribution-image"
                alt="image link"
              />
              <div className="rating-icon flex justify-center mb-2 ">
                <div class="flex items-center space-x-1 ">
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                  <svg
                    class="w-4 h-4 text-gray-300 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                </div>
              </div>
              <h1 className="distribution-price">{"₹ "}{product.price}</h1>
              <h1 className="distribution-name">{product.name}</h1>
            </div>
          ))}
        </Slider>
        <div className="arrow-container">
          <button className="prev-button" onClick={goToPrev}>
            <IoIosArrowDropleftCircle />
          </button>
          <button className="next-button" onClick={goToNext}>
            <IoIosArrowDroprightCircle />
          </button>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="additiontext">
          Introduce Products Featuring Distinctive Researched Formulas To the
          Market
        </p>
      </div>
      <img
        src={LOCAL_IMAGES.productdist}
        style={{ marginTop: "20px" }}
        alt="product"
      />

      <MainHeading headingText={"OUR CERTIFICATIONS"} />

      <Slider {...settingsreview}>
        {certificationData?.map((item, index) => {
          return (
            <>
              <div style={{ backgroundColor: "", marginTop: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={item.img}
                    alt="testilogo"
                    className="service_para"
                  />
                </div>
              </div>
            </>
          );
        })}
      </Slider>
    </div>
  );
}
