import React, { useEffect, useState } from "react";
import { brandimg, rotateData } from "../../utils/dummydata";
import CommonSlider from "../../commonComponent/commonSlider";
import MainHeading from "../../commonComponent/dividerline";
import "./loreal.css";
import BestSeller from "../../bestseller";
import { postApiData } from "../../utils/services";
import SellingBrandSlider from "../../commonComponent/sellingBrandSlider";
import { argatinbannerdata } from "../../utils/dummydata";
import { LorealData } from "../../utils/dummydata";
import BrandContainer from "../../Middlesection/Middlesection";
import { sellingPlateformData } from "../../utils/dummydata";
import { useSelector } from "react-redux";
import setAuthorizationToken from "../../utils/commonFunction";
import CustomCart from "../../commonComponent/customcard";
import { Helmet } from "react-helmet";
import ProductCart from "../../commonComponent/customcard/productCart";
import CustomProductTag from "../../commonComponent/gtm";
import TestCard from "../../commonComponent/customcard/testCart";
import SliderCarts from "../../commonComponent/customcard/sliderCart";
export default function Loreal() {
  const [facialkit, setFacialKit] = useState([]);
  const token = useSelector((store) => store.authReducer.userData);
  useEffect(() => {
    if (token) {
      setAuthorizationToken(token);
    }
  }, []);
  const apidata = {
    brands: {
      Loreal:true
    },
  };
  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setFacialKit(callBack);
      console.log("LorealData", callBack);
    });
  }, []);
  return (
    <div style={{ overflowX: "hidden" }} className="mt-24 ">
      <>
        <Helmet>
          <title>
            Buy Hair Care & Skin Care Products Online in India - Argatin Keratin
            & SkinCo & Loreal.
          </title>
          <meta
            name="robots"
            content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Loreal Professional Products" />
          <meta
            property="og:updated_time"
            content="2023-05-02T06:55:45+00:00"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Written by" />
          <meta name="twitter:data1" content="Loreal Professional Products" />
          <meta name="twitter:label2" content="Time to read" />
          <meta name="twitter:data2" content="Less than a minute" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16512616878"></script>
          <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16512616878');
          `}
          </script>
        </Helmet>
      </>
      <div className="mainsliderargatin">
        <CommonSlider brandimg={LorealData} brand="loreal"/>
      </div>
      <BrandContainer photoData={rotateData} brandType="Loreal" />
      <div className="facialkitContainer">
        <MainHeading headingText={"Loreal  Based   Product"} />
      </div>
      {/* <BestSeller productdata={facialkit}/> */}
      {/* <CustomCart productdata={facialkit} productType={"product"} /> */}
      <div className="flex flex-row gap-5 my-5 justify-evenly md:justify-between items-center flex-wrap md:mx-5">
      {
        facialkit?.map((item,index)=>(
          <>
            <SliderCarts key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)}  stockQuantity={item?.stockQuantity} productType={"product"}/>
            <CustomProductTag product={item}/>
          </>
        ))
      }
      </div>
      {/* <div className="facialkitContainer">
        <MainHeading headingText={"Argatin Keratin Featured Items"} />
      </div>
      <CustomCart productdata={facialkit} productType={"product"} /> */}
      {/* <div className="NowAvailable">
        <MainHeading headingText={"Now Available on "} />
      </div>
      <div
        style={{ backgroundColor: "", padding: "25px" }}
        // className="sellingbrandslider"
      >
        <SellingBrandSlider brandimg={sellingPlateformData} />
      </div> */}
    </div>
  );
}
