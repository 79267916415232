import React, { useState } from 'react'
import login_icon from "./login_icon.png";
import loginImg from "./signup.png";
import { userDetails } from '../Redux/actions';
import setAuthorizationToken from '../utils/commonFunction';
import toast from 'react-hot-toast';
import { postApiData } from '../utils/services';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const Logins = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const loginPress = (e) => {
    e.preventDefault();
    const data = {
      phoneNumber: phoneNumber,
      otp: otp,
    };
    console.log("data", data);
    postApiData(
      "/user/login",
      data,
      (response) => {
        console.log("response login", response);
        dispatch(userDetails(response));
        // localStorage.set("token",response)
        setAuthorizationToken(response);
        // alert("logged In sucessfully")
        toast.success("logged in sucessfully!")
        navigate("/AddressModal")
      },
      (error) => {
        console.log("error", error);
        // alert("details are incorrect")
        toast.error("Details are incorrect!")
      }
    );
  };
  const sendotp = (e) => {
    e.preventDefault();
    let data = {
      phoneNumber: phoneNumber,
    };
    postApiData(
      "/user/sendOtp",
      data,
      (response) => {
        console.log("sendresponse", response);
        // alert("Otp send Sucessfully");
        toast.success("Otp send Sucessfully!")
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

      return (
    <div className='flex-col flex md:flex-row  w-screen h-auto md:h-[70vh] p-4  mt-28'>
        <div className='w-full md:w-[60%]   p-4'>
            <img src={loginImg} className='w-full h-full object-cover'/>
        </div>
        <div className='w-full md:w-[40%] p-5 flex flex-col'>
                <h1 className='text-3xl font-bold text-center text-black my-5'>Enter your Details</h1>
                <input type='text' placeholder='Enter Your Phone Number' value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} className='w-full p-4 h-[50px] border-2 border-black outline-none rounded-lg'/>
                <button className='px-2 py-3 text-lg text-white font-semibold bg-black my-4 rounded-lg hover:opacity-80 ' onClick={sendotp}>Send Otp</button>
                <input type='text' placeholder='Enter OTP' value={otp} onChange={(e)=>setOtp(e.target.value)} className='w-full p-4 h-[50px] border-2 border-black outline-none rounded-lg'/>
                <button className='w-full text-lg font-semibold text-center text-white bg-black my-4 px-2 py-3 rounded-lg hover:opacity-80' onClick={loginPress}>Submit</button>
        </div>
    </div>
  )
}

export default Logins