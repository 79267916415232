import React from 'react';
import './index.css'; // Make sure to import your CSS file
import { LOCAL_IMAGES } from '../utils/localimages';
import { Link } from 'react-router-dom';
const BrandContainer = ({photoData,brandType}) => {
  return (
    <div className="flex justify-between items-center flex-wrap px-3 my-10 gap-4 mx-2 md:mx-10">
      {photoData?.map((item, index) => (

        <div className="flex justify-center items-center " key={index}>
          <Link  to={brandType === "Loreal" ?"/loreal":"/hair-care"}>
            <img className="md:full md:h-full w-[100px] h-[100px] flex justify-center items-center mx-auto object-contain" src={item?.image} alt={item.description} />
            <h4 className="text-md md:text-xl text-center font-semibold font-serif">{item?.description}</h4>

          </Link>
        </div>

      ))}
    </div>
  );
};

export default BrandContainer;
