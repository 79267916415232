import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { cardAdded, cardProductAdded, totalProduct } from "../Redux/actions";
import BestSeller from "../bestseller";
import { getApiCall, postApiData } from "../utils/services";
import { Link } from "react-router-dom";
const Productupdateform = () => {
  const [data, setData] = useState([]);
  const [productlistData, setproductListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categorypress, setCategorypress] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Best Sellers");
  const handleButtonClick = (buttonName) => {
    // setLoading(true);
    setSelectedCategory(buttonName);
    setCategorypress(!categorypress);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getApiCall("/user/comboProductsUpdate", (callBack) => {
      setproductListData(callBack);
      console.log("fgetAllProductsst", callBack);
      dispatch(totalProduct(callBack));
    });
  }, []);
  console.log("productcombodata", productlistData);

  const buttonStyle = {
    backgroundColor: "black",
    color: "white",
    transition: "background-color 0.3s, color 0.3s",
  };

  return (
    <div className="xs:overflow-hidden sm:overflow-hidden mt-24">
      <div>
        {/* <Link
          to={{
            pathname: "/productupdateform",
            state: { productdata: productlistData },
          }}
        >
          <div className="underline font-bold row justify-end flex mr-11"></div>
        </Link> */}

        <div>
          <BestSeller
            productType="productcombo"
            form={"form"}
            productdata={productlistData}
          />
        </div>
      </div>
    </div>
  );
};

export default Productupdateform;
