import React, { useState } from 'react'
import './cart.css'
import { postProductData } from '../../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Login from '../../Register/Register';
import { useEffect } from 'react';

export default function CustomCart({ productdata ,productType}) {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const token = useSelector((store) => store.authReducer.userData);


  const addToCart = (product) => {
    // console.log("addedproduct",product)

    if (token) {

      dispatch(postProductData(product, 1,productType));
      //dispatch(postProductData(product, 2));
      // Open the modal
      setIsModalOpen(true);

      // Close the modal after 2 seconds (adjust as needed)
      // setTimeout(() => {
      //   setIsModalOpen(false);
      // }, 2000);
    } else {
      setLoginModal(true);
    }
  };
  return (
    <div className='service-section' >
      {
        productdata.map((item, index) => {
          {
            console.log("itemcomming", item)
          }
          return (
            <div className="Service_main hover:scale-y-110 duration-200 ease-in mx-10 my-10">
              <Link to={`/cartdescription/${item?.name?.slice(0, 20)}/${item._id}`} state={{ data: item ,productType:productType}}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className=''>
                  <img src={item.images[0]} alt='' className='image' />
                </div>

              </Link>

              <div style={{ overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: "center", marginLeft: "14px" }}>
                <span className='productName'>
                  {item?.name?.length > 3 ? `${item.name.slice(0, 30)}...` : item.name}
                </span>
              </div>

              <div
                //  class="flex items-center space-x-1"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg
                  class="w-4 h-4 text-gray-300 dark:text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 20"
                >
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex items-center">
                  <p className="line-through text-gray-500 mr-2">
                    ₹{item.mrp}
                  </p>
                  {(1 - item.price / item.mrp) * 100 > 0 && (
                    <p className="text-green-500">
                      -{Math.round((1 - item.price / item.mrp) * 100)}%
                      OFF
                    </p>
                  )}
                </div>
                <p className="text-red-500">₹{item.price}</p>
              </div>
              <button
                className="bg-black text-white mb-5 mt-4 add_to_cart_btn"
                onClick={() => addToCart(item)}
              >
                Add To Cart
              </button>
              {loginModal && (
                // Render the Login component if loginModal is true
                <Login modalIsOpen={loginModal} setIsOpen={setLoginModal} />
              )}

              {isModalOpen && (
                <div className="notification">
                  <p>Product added to cart successfully!</p>

                  <button
                    className="close-cart-button"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          )

        })
      }
    </div>
  )
}


// {
//   OurServicesData.map((item, index) => {
//     return (
//       <div className="Service_main" >
//         <img src={item.serviceIcon} alt='serviceIcon'
//           className='serviceIcon' />
//         {/* <div className='discriptionContainer'> */}
//           <p className='service_heading'>{item.serviceName}</p>
//           <p className='service_para'>{item.serviceDiscipton}</p>
//         {/* </div> */}


//       </div>
//     )

//   })
// }

