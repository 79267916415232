import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './sellingslider.css'

export default function SellingBrandSlider(props) {
  const { brandimg } = props
  const sellingsettings = {
    // dots:true,
    // infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (

    <div>
      <Slider {...sellingsettings}>
        {
          brandimg?.map((item, index) => {
            return (
              // <div style={{display:'flex',width: 'calc(100% /2- 10px)',}}
              //  >
              <div key={index} >
                <img
                  src={item?.img}
                  alt='galleryimages'
                  className='selling-icon'
                  //style={{ width: '250px', height: '150px' }}
                />
              </div>
            )

          })
        }
      </Slider>
    </div>
  )
}
