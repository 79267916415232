import React from 'react'
// import { faqData } from '../../utils/dummydata'
import { privacyPolicyData } from '../../utils/dummydata'
import './privacypolicy.css'

// import './faq.css'

export default function PrivacyPolicyComponent() {
  return (
    <div className="mt-24">
     {
        privacyPolicyData.map((item,index)=>{
            return(
        <div className='faqContainer'>
            <p className=' font-bold text-black text-lg'>{item.pquestion}</p>
            <p className='faqAns mb-4'>{item.pans}</p>
        </div>
     

            )
     
        })

     }
    </div>
  )
}
