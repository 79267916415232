import React, { useContext, useEffect, useRef, useState } from "react";
import "./Carddescrption.css";
import { AiFillGift } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { postProductData } from "../Redux/actions";
import Login from "../Register/Register";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { getApiCall, postApiData } from "../utils/services";
import { safeAndScienctific } from "../utils/dummydata";
import "./additionaldata";
import { additionalData } from "./additionaldata";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import toast from "react-hot-toast";
import { CartContext } from "../context/CartContext";

const Cartdescription = (props) => {
  const [loginModal, setLoginModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageall, setimageall] = useState([]);
  const { state } = useLocation();
  const [urldata, setUrlData] = useState('')
  const [product, setProduct] = useState({})
  const { isCartOpen,setIsCartOpen} = useContext(CartContext);

  const [showFullDescription, setShowFullDescription] = useState(false);

  const [activeTab, setActiveTab] = useState("additionalInformation");

  const [mainImageIndex, setMainImageIndex] = useState(0);
  const sliderRef = useRef();

  const slidesettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  };

  // const goToPrev = () => {
  //   console.log("slideefjikjfhdjfh")
  //   sliderRef.current.slickPrev();
  // };

  // const goToNext = () => {
  //   sliderRef.current.slickNext();
  // };
  const goToPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const { shortUrl } = useParams();
  console.log("shortUrl",shortUrl)
  useEffect(() => {
    //     user/getSingleProductData;   
    // get request 
    // query = id
    axios.get(`https://prosaloncart.com/user/getSingleProductData?id=${shortUrl}`)
      .then(response => {
        // Handle successful response
        // setData(response.data);
        setProduct(response.data.data)
        console.log("singleproduct", response)
      })
      .catch(error => {
        // Handle errors
        console.log('error', error)
      })
  }, []);

  // Now, 'id' contains the value of the parameter in the URL
  console.log("productssss-----",product)
  // console.log("urlidcoming", id);


  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  // let product = state?.data;
  // console.log("inbest", state?.productType);
  var typeProduct = state?.productType;
  const dispatch = useDispatch();
  //console.log(typeof (product))

  // console.log("locationcomminng", state.data);

  const token = useSelector((store) => store.authReducer.userData);

  const apidata = {
    brand: "",
  };
  useEffect(() => {
    // Get the current URL
    const currentURL = window.location.href;
    setUrlData(currentURL)
    console.log("currentURL", currentURL);

    // Rest of your component logic...

    return () => {
      // Cleanup or handle component unmounting if needed
    };
  }, []);
  const addToCart = (product) => {
    // console.log("addedproduct",product)

    if (token) {
      dispatch(postProductData(product, 1, typeProduct));
      // Open the modal
      // setIsModalOpen(true);

      // // Close the modal after 3 seconds (adjust as needed)
      // setTimeout(() => {
      //   setIsModalOpen(false);
      // }, 2000);
      setIsCartOpen(true)
      toast.success("product added successfully!")
    } else {
      setLoginModal(true);
      toast.error("Please Login First!")
    }
  };
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    //setMainImageIndex(index);
  };

  const settings = {
    //dots: true,
    infinite: true,
    //speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (Object.keys(product).length > 0) return (
    <>
      {/* {
            console.log("discrption",product.description)
          } */}
      <Helmet>
        <title>
          {product?.name}
        </title>
        <meta
          name="description"
          content={product?.description}
        />
        <link rel="canonical" href={urldata} />
        <meta
          property={product?.name}
          content={product?.description}
        />
        <meta
          property={product?.description}
          content={product?.description}
        />
        <meta property="og:url" content={urldata} />
        <meta
          name="robots"
          content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Skin.co" />
        <meta property="og:updated_time" content="2023-05-02T06:55:45+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content=" Skin.co  " />
        <meta name="twitter:label2" content="Time to read" />
        <meta name="twitter:data2" content="Less than a minute" />
      </Helmet>
      <div className="flex  cartmainconatiner1 mt-24">
        <div className="side-main">
          <div className="side-img">
            {window.innerWidth < 1100 && (
              <div className="relative">
                
                <Slider ref={sliderRef} {...settings}>
                  {product &&
                    product?.images?.map((image, index) => (
                      <div key={index}>
                        <img
                          className="cart-main"
                          src={image}
                          alt={`image${index}`}
                          style={{ width: '100%', height: 'auto' }}
                        // onClick={() => handleImageClick(index)}
                        />
                      </div>
                    ))}
                </Slider>
                <div className="cart-arrow-container ">
                  <button className="cart-prev-button absolute top-1/3 left-0" onClick={goToPrev}>
                    <IoIosArrowDropleftCircle />
                  </button>
                  <button className="cart-next-button absolute top-1/3 right-0" onClick={goToNext}>
                    <IoIosArrowDroprightCircle />
                  </button>
                </div>
              </div>
            )}

            {window.innerWidth > 1101 && (
              <>
               
                <div className="side-img-inner">
                  {product?.images?.map((image, index) => (
                    <img
                      key={index}
                      className={`w-[96px] h-[103px] mb-4 cursor-pointer`}
                      src={image}
                      alt={`image${index}`}
                      onClick={() => handleImageClick(index)}
                    />
                  ))}
                </div>

              </>

            )}

          </div>
          <div className="right-img">
            <img
              className="w-[500px] h-[465px] ml-[25px]  main-img"
              src={product?.images[selectedImageIndex]}
              alt={`mainImage`}
            />
          </div>



        </div>

        <div className="pl-[50px] cart-button-container border-2 border-black">
          <div className="cart-text">
            <h2 className="text-5xl font-bold" >{product?.name}</h2>

            <div className="productdescription-container">
              
              <div>
                {showFullDescription ? (
                  <div >
                    {product?.description}
                    <button
                      onClick={toggleDescription}
                      style={{ fontWeight: "bold" }}
                    >
                      {" "}Read Less
                    </button>
                  </div>
                ) : (
                  <React.Fragment>
                    {product?.description.slice(0, 380)}
                    {product?.description?.length > 100 && (
                      <button
                        onClick={toggleDescription}
                        style={{ fontWeight: "bold" }}
                      >
                        Read More
                      </button>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>

            <div className="rupee-price">
              <FaRupeeSign size={20} />
              <p className="price">{product?.price}</p>
            </div>
          </div>
          {
            product.stockQuantity === 0 ? (
              <button
            className="w-full bg-gray-700 text-white mt-[20px] p-[17px] addtocart-button cursor-not-allowed"
          >
            Out Of Stock
          </button>
            ): (
              <button
            className="w-full bg-black text-white mt-[20px] p-[17px] addtocart-button"
            onClick={() => addToCart(product)}
          >
            Add To Cart
          </button>
            )
          }

          

          {loginModal && (
            <Login modalIsOpen={loginModal} setIsOpen={setLoginModal} />
          )}

          {isModalOpen && (
            <div className="notification">
              <p style={{ backgroundColor: "#RRGGBB" }}>
                Product added to cart successfully!
              </p>
              <button
                className="close-cart-button"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
      {/* <div style={{ height: "250px", backgroundColor: "#000000", marginTop: '30px' }}>
        <h2 style={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '30px', paddingBottom: '30px', fontSize: '25px', fontWeight: '500' }}>Safe and Scientific</h2>

        <div className="safeImg">
          {
            safeAndScienctific.map((item, index) => {
              return (
                <div style={{ flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}>
                  <img src={item.image} alt="icon" style={{ height: '100px', width: '100px' }} />
                  <p style={{ color: 'white', fontSize: '19px', fontWeight: '500', lineHeight: '25px', textAlign: 'center', }}>{item.description}</p>
                </div>



              )

            })
          }
        </div>

      </div> */}
      <div
        style={{
          height: "300px",
          backgroundColor: "#000000",
          marginTop: "20px",
        }}
      >
        <h2
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "30px",
            paddingBottom: "30px",
            fontSize: "25px",
            fontWeight: "500",
          }}
        >
          Safe and Scientific
        </h2>

        <div className="safeImgContainer">
          {safeAndScienctific.map((item, index) => {
            return (
              <div className="textsafecontianer">
                <img src={item?.image} alt="icon" className="iconimg" />
                <p className="description">{item?.description}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="tabs">
          <button
            className={activeTab === "additionalInformation" ? "active" : ""}
            onClick={() => handleTabChange("additionalInformation")}
          >
            Additional Information
          </button>
          <button
            className={activeTab === "manufacturerDetails" ? "active" : ""}
            onClick={() => handleTabChange("manufacturerDetails")}
          >
            Manufacturer Details
          </button>
        </div>

        {activeTab === "additionalInformation" && (
          <div className="tab-content">
            <table>
              <tbody>
                {/* <tr>
                  <td>Weight</td>
                  <td>0.2 kg</td>
                </tr> */}
                <tr>
                  <td>EAN Code</td>
                  <td>{product?.eancode}</td>
                </tr>
                <tr>
                  <td>Dimensions</td>
                  <td>{product?.length} × {product?.breadth} × {product?.height}</td>
                </tr>
                <tr>
                  <td>Brand</td>
                  <td>{product?.brand}</td>
                </tr>
                {/* <tr>
                  <td>Color</td>
                  <td>Transparent</td>
                </tr> */}
                {
                  product?.hairType && (
                <tr>
                  <td>Hair Type</td>
                  <td>All Hair Type</td>
                </tr>
                  )
                }
                {
                  product?.ingredients && (
                <tr>
                  <td>Ingredients</td>
                  <td>Argan Oil, Burdock, Rose Oil, Vitamin E</td>
                </tr>
                  )
                }
                {
                  product?.itemDimesion &&  (
                <tr>
                  <td>Item Dimension LxWxH</td>
                  <td>4x11x15</td>
                </tr>
                  )
                }
                {
                  product?.hairType && (
                <tr>
                  <td>Item Form</td>
                  <td>Spray</td>
                </tr>
                  )
                }
                {
                  product?.hairType && (
                <tr>
                  <td>Liquid Volume</td>
                  <td>100ml</td>
                </tr>
                  )
                }
                {
                  product?.hairType && (
                <tr>
                  <td>Product Benefits</td>
                  <td>
                    Hair Strengthening, Anti-dandruff, Hair Growth, Nourishment,
                    Healthy Scalp, Conditioning, Prevents Greying
                  </td>
                </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        )}

        {activeTab === "manufacturerDetails" && (
          <div className="tab-contentabc">
            <div className="manufacture-detail">
              <p>
                <strong>Manufacturer Name:</strong> The Professional Hair & Spa
                (India) Pvt. Ltd. # The Address: Plot No 31, Okhla Phase III,
                New Delhi - 110020,{" "}
              </p>
              <p>
                <strong>Country of Origin:</strong> India
              </p>
              <p>
                <strong>Marketed By & Contact details:</strong> The Professional
                Hair & Spa (India) Pvt. Ltd. # The Address: Plot No 31, Okhla
                Phase III, New Delhi - 110020
              </p>
              <p>
                <strong>Tel:</strong> +91 9315743367
              </p>
            </div>

            <div className="customer-care">
              <h2>Customer Care Details:</h2>
              <p>
                <strong>Email:</strong>{" "}
                <a href="mailto:enquiry@theprofessionalworld.com">
                  enquiry@theprofessionalworld.com
                </a>
              </p>
              <p>
                <strong>Phone Number:</strong> +91-9315 743 367
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
  return <div />
};

export default Cartdescription;
