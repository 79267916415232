import React, { useEffect, useState } from 'react'
import { MdOutlineClose } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { getApiCall } from '../../utils/services';
import axios from 'axios';
import { useSelector } from 'react-redux';

const Popup = ({isVisible,onClose,id,setBool,bool}) => {
    console.log("editted id",id)
    const [address,setAddress] = useState([]);
    const [address1,setAddress1] = useState("");
    const [address2,setAddress2] = useState("");
    const [pincode,setPincode] = useState("");
    const [city,setCity] = useState("");
    const [state,setState] = useState("");
    console.log("edit ke liye address",address)
    const token=useSelector((store)=>store.authReducer.userData)

    // useEffect(() => {
    //     getApiCall("user/getUserAddress", (resp) => {
    //         console.log("user/getUserAddress", resp.addresses);
    //         setAddress(resp.addresses);
    //     });
    // }, []);
    useEffect(() => {
        axios.get('https://prosaloncart.com/user/getUserAddress', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        console.log('Responsedatadsfhsahhfsd:', response.data);
        setAddress(response.data.data.addresses);
        // Handle response
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error
      });
      }, []);
    console.log("selected address",address)
    const filterAddress = address.filter((item)=>item._id===id)
    
    console.log("address jo filter h", filterAddress)
    const handleEditAddress = () =>{
      const data = {
        _id:id,
        address:address1,
        address2:address2,
      }
  
      axios.post('https://prosaloncart.com/user/editAddress', 
      data,
      {
      headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      console.log('Responsedatadsfhsahhfsd:', response.data.data);
      onClose()
      setBool(!bool)
      // Handle response
    })
    .catch(error => {
      console.error('Error:', error);
      // Handle error
    });
    }
    
    if(!isVisible) return null;
  return (
    <div className='fixed z-30 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center'>
    <div className='absolute z-40 mx-3 w-full md:w-1/3 my-10'>

        <div className='bg-white p-4 rounded-xl '>
            <div className='flex justify-between font-bold items-center'>
            <h1 className={`text-blue-500 text-lg font-bold mb-4 `}>Edit your address</h1>
            <button className='text-3xl font-bold mt-4 text-red-600 hover:text-red-900 bg-transparent' onClick={()=>onClose()}><MdOutlineClose /></button>

            </div>

            {filterAddress?.map((item,index)=>(
                 <div className="grid w-full items-center" key={index}>
                 <label htmlFor="name"><span className='font-bold text-md'>Address :</span></label>
                 <input type="text" placeholder='Address 1' className='rounded-lg border-none bg-gray-300 placeholder:font-semibold px-2 py-2 text-blue-600  my-2' defaultValue={item?.address} onChange={(e)=>setAddress1(e.target.value)}/>
                 <label htmlFor="name"><span className='font-bold text-md'>Address 2 :</span></label>
                 <input type="text" placeholder='Address 2' className='rounded-lg border-none bg-gray-300 placeholder:font-semibold px-2 py-2 text-blue-600 my-2' defaultValue={item?.address2} onChange={(e)=>setAddress2(e.target.value)}/>
                 <label htmlFor="email"><span className='font-bold text-md'>PinCode :</span></label>
                 <input type="email" placeholder='email' id="email" className='rounded-lg border-none bg-gray-300 placeholder:font-semibold px-2 py-2 text-blue-600 my-2' defaultValue={item?.pincode} disabled/>
                 <label htmlFor="number"><span className='font-bold text-md'>City :</span></label>  
                 <input placeholder='MRP' className='rounded-lg border-none bg-gray-300 placeholder:font-semibold px-2 py-2 text-blue-600 my-2' defaultValue={item?.city}  disabled/>
                 <label htmlFor="number"><span className='font-bold text-md'>state :</span></label>
                 <input placeholder='Price' className='rounded-lg border-none bg-gray-300 placeholder:font-semibold px-2 py-2 text-blue-600 my-2' defaultValue={item?.state} disabled/>
             </div>
            ))}
            <button className={`bg-blue-400 text-white font-normal p-3 hover:text-gray-500 rounded-xl mt-2 `} onClick={handleEditAddress}>Submit</button>
            
            

        </div>

        

    </div>

</div>
  )
}

export default Popup