import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BestSeller from "../bestseller";
import Pricefilter from "../commonComponent/priceFilter/Pricefilter";
import { IoMdClose } from "react-icons/io";
import "./bestsellerlist.css";
import { getApiCall, postApiData } from "../utils/services";
import { IoFilter } from "react-icons/io5";
import CustomCart from "../commonComponent/customcard";
import ProductCart from "../commonComponent/customcard/productCart";
import DropdownWithCheckboxes from "../commonComponent/CheckBox";
import CartDisplayer from "../commonComponent/CartDisplayer";
import DropdownAllCheckboxes from "../commonComponent/CheckBox/DropDownBox";
import { Helmet } from "react-helmet";

const  Bestsellerlist = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [productformdetail, setProductformdetail] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [newPriceRange, setNewPriceRange] = useState([0, 3000]);
  const [showFilter, setShowFilter] = useState(false);
  const [checkedBrands, setCheckedBrands] = useState({
    Loreal: false,
    Skinconyc: false,
    Argatin: false,
    "Spring H2O": false,
  });
  
  const apidata = {
    brand: "",
  };

  // useEffect(() => {
  //   postApiData("user/getAllProducts", apidata, (callBack) => {
  //     setProductformdetail(callBack);
  //     setFilteredProducts(callBack); // Initialize filteredProducts with all products
  //     console.log("navbar", callBack);
  //   });
  // }, []);
  // useEffect(()=>{
  //   const data = {
  //     brands: checkedBrands
  //   }
  //   postApiData("user/getAllProducts", data, (callBack) => {
  //         setProductformdetail(callBack);
  //         setFilteredProducts(callBack); // Initialize filteredProducts with all products
  //         console.log("navbar", callBack);
  //       });
  // },[checkedBrands])
  useEffect(()=>{
    getApiCall("user/getAllProductsForBestseller",
        (resp)=>{
          setFilteredProducts(resp)
        },
        (error)=>{
            console.log('error')
        }
    )
  },[])

  const handlePriceRangeChange = (newPriceRange) => {
    console.log(newPriceRange);
    setNewPriceRange(newPriceRange);
    const filtered = productformdetail.filter(
      (product) =>
        product.price >= newPriceRange[0] && product.price <= newPriceRange[1]
    );
    console.log(filtered);
    setFilteredProducts(filtered?.length > 0 ? filtered : productformdetail);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const closeFilter = () => {
    setShowFilter(false);
  };

  const containerClasses = `newprice-container ${showFilter ? "show" : "hide"}`;

  return (
    <div className="flex justify-start items-start mt-24 relative">
        <Helmet>
        <title>
        Our Best Sellers: Buy Our Top-Selling Products You Can't Miss
        </title>
        <meta
          name="description"
          content=" Best Sellers at Pro Salon Cart and discover the top-rated products that our customers love. Our best sellers collection features a curated selection of hair care and skincare."
        />
        <link rel="canonical" href="https://prosaloncart.com/best-sellers" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
     
        <meta
          property="og:description"
          content="Buy Hair Care Products Online in India. Argatin brings to You Goodness of Nature with Our Toxin Free Hair Products Such as Hair Oil, Mask, Shampoo, Conditioner."
        />
        <meta property="og:url" content="https://prosaloncart.com/hair-care" />
        <meta property="og:site_name" content="Hair care" />
        <meta
          keyword="keyword"
          content="top-rated products, customer favorites, best selling product, popular picks, highly recommended
          "
        />
      </Helmet>
      <div className="md:w-1/6 hidden   h-full md:flex flex-col fixed left-0 z-1">
      {window.innerWidth >= 350 && window.innerWidth < 1000 && (
           <div>
             <div className="btn-filter">
               <button onClick={toggleFilter}>
                 {showFilter ? (
                   <div style={{ display: "flex" }}>
                     <IoFilter />
                     <span> Filter </span>
                   </div>
                 ) : (
                   <div
                     style={{ display: "flex", gap: "13px", fontSize: "18px" }}
                   >
                     <IoFilter />
                     <span> Filter </span>
                   </div>
                 )}
               </button>
             </div>
             <div className={containerClasses}>
               <IoMdClose onClick={closeFilter} />
               {showFilter && (
                <>
                 <Pricefilter
                   newPriceRange={newPriceRange}
                   onPriceRangeChange={handlePriceRangeChange}
                   productformdetail={productformdetail}
                 />
                 <DropdownAllCheckboxes checkedBrands={checkedBrands} setCheckedBrands={setCheckedBrands}/>
                 </>
               )}
             </div>
           </div>
         )}
         {window.innerWidth > 1000 && (
           <Pricefilter
             newPriceRange={newPriceRange}
             onPriceRangeChange={handlePriceRangeChange}
             productformdetail={productformdetail}
           />
         )}
   <DropdownAllCheckboxes checkedBrands={checkedBrands} setCheckedBrands={setCheckedBrands}/>
   {/* <AccordionExpandIcon /> */}
      </div>
      <div className="flex w-full justify-between items-center flex-wrap  lg:ml-64 mb-10">
        <CartDisplayer productdata={filteredProducts} productType={"combos"} /> 
      </div>
   </div>   
  );
};

export default Bestsellerlist;


{/* <div className="mt-24">
      <div>
        {/* <h1 className="mt-[30px] ml-[20px] mb-[3rem] text-3xl">
          Chemist At Play
        </h1> */}
        
    //   </div>
    //   <div className="btn-class-container">
    //     {/* <Pricefilter

    //         newPriceRange={newPriceRange}
    //         onPriceRangeChange={handlePriceRangeChange}
    //       />  */}
    //     {window.innerWidth >= 350 && window.innerWidth < 1000 && (
    //       <div>
    //         <div className="btn-filter">
    //           <button onClick={toggleFilter}>
    //             {showFilter ? (
    //               <div style={{ display: "flex" }}>
    //                 <IoFilter />
    //                 <span> Filter </span>
    //               </div>
    //             ) : (
    //               <div
    //                 style={{ display: "flex", gap: "13px", fontSize: "18px" }}
    //               >
    //                 <IoFilter />
    //                 <span> Filter </span>
    //               </div>
    //             )}
    //           </button>
    //         </div>
    //         <div className={containerClasses}>
    //           <IoMdClose onClick={closeFilter} />
    //           {showFilter && (
    //             <Pricefilter
    //               newPriceRange={newPriceRange}
    //               onPriceRangeChange={handlePriceRangeChange}
    //               productformdetail={productformdetail}
    //             />
    //           )}
    //         </div>
    //       </div>
    //     )}
    //     {window.innerWidth > 1000 && (
    //       <Pricefilter
    //         newPriceRange={newPriceRange}
    //         onPriceRangeChange={handlePriceRangeChange}
    //         productformdetail={productformdetail}
    //       />
    //     )}

    //     <div>
    //       {/* <BestSeller productdata={filteredProducts} /> */}
    //       {/* <CustomCart productdata={filteredProducts} productType={"product"} /> */}
    //       <div className="flex justify-center items-center gap-5 flex-wrap ">
    //   {
    //     filteredProducts?.map((item,index)=>(
    //         <ProductCart key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)} productType={"product"}/>
    //     ))
    //   }
    //   </div>
    //     </div>
    //   </div>
    // </div> */}