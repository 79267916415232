import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BestSeller from "../bestseller";
import Pricefilter from "../commonComponent/priceFilter/Pricefilter";
import { IoMdClose } from "react-icons/io";
import "./haircareallproducts.css";
import { postApiData } from "../utils/services";
import { IoFilter } from "react-icons/io5";
import CustomCart from "../commonComponent/customcard";
import ProductCart from "../commonComponent/customcard/productCart";
import CustomProductTag from "../commonComponent/gtm";
import TestCard from "../commonComponent/customcard/testCart";
import { Helmet } from "react-helmet";
const Haircareallproducts = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [productformdetail, setProductformdetail] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [newPriceRange, setNewPriceRange] = useState([0, 3000]);
  const [showFilter, setShowFilter] = useState(false);
  const [noProductFound, setNoProductFound] = useState(false);
  
  const apidata = {
    brands: {Argatin:true},
  };

  useEffect(() => {
    postApiData("user/getAllProducts", apidata, (callBack) => {
      setProductformdetail(callBack);
      setFilteredProducts(callBack); // Initialize filteredProducts with all products
      console.log("navbar", callBack);
    });
  }, []);

  const handlePriceRangeChange = (newPriceRange) => {
    console.log(newPriceRange);
    setNewPriceRange(newPriceRange);
    const filtered = productformdetail.filter(
      (product) =>
        product.price >= newPriceRange[0] && product.price <= newPriceRange[1]
    );
    console.log(filtered);
    setFilteredProducts(filtered?.length > 0 ? filtered : productformdetail);
    setNoProductFound(filtered.length === 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const closeFilter = () => {
    setShowFilter(false);
  };

  const containerClasses = `newprice-container ${showFilter ? "show" : "hide"}`;

  return (
    <div className="mt-24 ">
      <Helmet>
        <title>
          Buy the Best Hair Care Products at pro Salon Cart, Healthy Hair
        </title>
        <meta
          name="description"
          content=" At pro salon cart, we offer the best hair care product designed to nourish, protect, and revitalize your locks. Our curated selection includes top-quality shampoos, conditioners, treatments, and styling products."
        />
        <link rel="canonical" href="https://prosaloncart.com/hair-care" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Buy Hair Care Products Online in India - Argatin Keratin"
        />
        <meta
          property="og:description"
          content="Buy Hair Care Products Online in India. Argatin brings to You Goodness of Nature with Our Toxin Free Hair Products Such as Hair Oil, Mask, Shampoo, Conditioner."
        />
        <meta property="og:url" content="https://prosaloncart.com/hair-care" />
        <meta property="og:site_name" content="Hair care" />
        <meta
          keyword="keyword"
          content="buy hair care products online india, argatin keratin hair products online, best hair care products india, buy argatin keratin online"
        />
      </Helmet>

      <div className="btn-class-container">
        {window.innerWidth >= 350 && window.innerWidth < 1000 && (
          <div>
            <div className="btn-filter">
              <button onClick={toggleFilter}>
                {showFilter ? (
                  <div style={{ display: "flex" }}>
                    <IoFilter />
                    <span> Filter </span>
                  </div>
                ) : (
                  <div
                    style={{ display: "flex", gap: "13px", fontSize: "18px" }}
                  >
                    <IoFilter />
                    <span> Filter </span>
                  </div>
                )}
              </button>
            </div>
            <div className={containerClasses}>
              <IoMdClose onClick={closeFilter} />
              {showFilter && (
                <Pricefilter
                  newPriceRange={newPriceRange}
                  onPriceRangeChange={handlePriceRangeChange}
                  productformdetail={productformdetail}
                />
              )}
            </div>
          </div>
        )}
        {window.innerWidth > 1000 && (
          <Pricefilter
            newPriceRange={newPriceRange}
            onPriceRangeChange={handlePriceRangeChange}
            productformdetail={productformdetail}
          />
        )}
        <div className="flex flex-row gap-5 justify-evenly md:justify-between items-center flex-wrap md:mx-10 mb-10">
      {
        filteredProducts?.map((item,index)=>(
          <>
            <TestCard key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)} stockQuantity={item.stockQuantity} productType={"product"}/>
            <CustomProductTag product={item}/>
          </>
        ))
      }
      </div>
        {/* <CustomCart productdata={filteredProducts} productType={"product"} /> */}
      </div>
    </div>
  );
};

export default Haircareallproducts;
